<template>
  <div class="container">
    <!-- Button to trigger modal -->
<button class="action-btn" @click="showAddUserModal = true">Add User</button>
    

    <!-- Add User Modal -->
    <div v-if="showAddUserModal" class="modal" style="display: block">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Add New User</h5>
            <button
              type="button"
              class="close"
              @click="showAddUserModal = false"
            >
              <span>&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="addUser">
              <div class="form-group">
                <label for="name">Name:</label>
                <input
                  type="text"
                  id="name"
                  v-model="newUser.name"
                  required
                  class="form-control"
                />
              </div>
              <div class="form-group">
                <label for="email">Email:</label>
                <input
                  type="email"
                  id="email"
                  v-model="newUser.email"
                  required
                  class="form-control"
                />
              </div>
              <button type="submit" class="btn btn-primary">Submit</button>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="loading"
      style="
        height: 90vh;
        display: flex;
        justify-content: center;
        align-items: center;
      "
    >
      <div class="clearfix">
        <div
          class="spinner-grow"
          style="width: 3rem; height: 3rem"
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
    <div v-else>
      <!-- Show table if displayedUsers has data -->
      <div
        v-if="displayedUsers && displayedUsers.length > 0"
        class="table-responsive"
      >
        <table class="table table-bordered company-table-container">
          <!-- Table Header [start] -->
          <thead>
            <tr>
              <!-- select all checkbox [start] -->
              <th scope="col" class="text-center col-1">
                <input
                  type="checkbox"
                  v-model="selectAll"
                  @change="handleSelectAll"
                />
              </th>
              <!-- select all checkbox [end] -->
              <th scope="col" class="text-center col-2">Name</th>
              <th scope="col" class="text-center col-2">Email</th>
              <th scope="col" class="text-center col-2">Role</th>
            </tr>
          </thead>
          <!-- Table Header [end] -->

          <!-- Table Body [start] -->
          <tbody>
            <!-- show white-list users -->

            <!-- mapping white list users data -->
            <tr v-for="user in displayedUsers" :key="user.id">
              <td class="align-middle text-center col-1">
                <input type="checkbox" v-model="user.selected" />
              </td>
              <td class="align-middle text-center col-2">
                {{ user?.name }}
              </td>
              <td class="align-middle text-center col-2">
                {{ user?.email }}
              </td>
              <!-- Make Admin button with loader -->
              <template v-if="user?.role !== 'admin'">
                <td class="align-middle text-center col-2">
                  <button
                    class="admin-btn"
                    @click="makeUserAdmin(user.email)"
                    :disabled="user.loading"
                  >
                    <span v-if="user.loading">
                      <div
                        class="spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </span>
                    <span v-else> Make Admin </span>
                  </button>
                  <button
                    style="margin-left: 10px"
                    class="admin-btn"
                    @click="toggleUserStatus(user)"
                    :disabled="user.loading"
                  >
                    {{ user.confirmStatus ? "Disable" : "Enable" }}
                  </button>
                </td>
              </template>
              <!-- Remove Admin button with loader -->
              <template v-else>
                <td class="align-middle text-center col-2">
                  <button
                    class="admin-btn"
                    @click="removeUserAdmin(user.email)"
                    :disabled="user.loading"
                  >
                    <span v-if="user.loading">
                      <div
                        class="spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </span>
                    <span v-else> Remove Admin </span>
                  </button>
                </td>
              </template>
            </tr>
          </tbody>
          <!-- Table Body [end] -->
        </table>
      </div>
      <!-- Show message if displayedUsers is empty -->
      <div v-else class="no-users-message">Users Not Found</div>
    </div>
    <div :style="paginationContainerStyles" class="pagination-container">
      <!-- Show the button if at least one checkbox is checked -->
      <button
        v-if="showMoveAllButton"
        class="action-btn"
        :disabled="movingAll"
        @click="handleMoveAllClick"
      >
        <span v-if="!movingAll">Move to {{ buttonLabel }}</span>
        <span v-else>
          <!-- Show the loader while movingAll is true -->
          <div class="spinner-border spinner-border-sm" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";
import {
  fetchWhiteListUsers,
  fetchBlackListUsers,
  changeUserStatus,
  changeUserRole,
  addUser,
} from "@/apis/apis.js";
import { parseJSONFromLocalStorage } from "@/utils/utils";

export default {
  name: "DashboardUsers",
  props: {
    activeTab: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      localActiveTab: this.activeTab,
      selectAll: false,
      allWhiteListUsers: [],
      allBlackListUsers: [],
      loading: true,
      movingAll: false,
      displayedUsersData: [],
      displayedWhiteListUsersData: [],
      displayedBlackListUsersData: [],
      showAddUserModal: false, 
       newUser: {  
      name: '',
      email: ''
    },
      userData: parseJSONFromLocalStorage("loggedInUser"),
      loadingUsers: [],
    };
  },
  computed: {
    buttonLabel() {
      return this.activeTab === "whitelist" ? "Blacklist" : "Whitelist";
    },
    paginationContainerStyles() {
      return {
        "justify-content": this.showMoveAllButton
          ? "space-between"
          : "flex-end",
        "align-items": "center",
        "margin-bottom": "20px",
      };
    },
    showMoveAllButton() {
      return (
        this.displayedUsers && this.displayedUsers.some((user) => user.selected)
      );
    },
    displayedUsers() {
      return this.activeTab === "whitelist"
        ? this.displayedWhiteListUsersData
        : this.displayedBlackListUsersData;
    },
  },
  methods: {
    // for success toast
    showSuccessToast(message) {
      const toast = useToast();
      toast.success(message, {
        position: "bottom-right",
      });
    },
    // for error toast
    showErrorToast(message) {
      const toast = useToast();
      toast.error(message, {
        position: "bottom-right",
      });
    },
    // to select all users
    handleSelectAll() {
      const isSelectedAll = this.displayedUsers.every((user) => user.selected);
      this.displayedUsers.forEach((user) => {
        user.selected = !isSelectedAll;
      });
    },
    // change user status [white/black to black/white list]
    async handleMoveAllClick() {
      this.movingAll = true; // Set movingAll to true to show the loader
      const actionText =
        this.activeTab === "whitelist"
          ? "to the blacklist"
          : "to the whitelist";
      const confirmationMessage = `Are you sure to move users ${actionText}?`;

      const isConfirmed = window.confirm(confirmationMessage);
      if (isConfirmed) {
        const selectedUsers = this.displayedUsers.filter(
          (user) => user.selected
        );

        // selected users data in an array
        const usersToUpdate = selectedUsers.map((user) => ({
          email: user.email,
          isWhiteList: this.activeTab === "whitelist" ? false : true,
        }));

        // data has to pass change user status api
        const data = {
          users: usersToUpdate,
        };

        // Call the API to change the status of all selected users
        const response = await changeUserStatus(
          data,
          this.userData?.accessToken
        );

        // success case
        if (response?.data?.status) {
          this.showSuccessToast(`Users have been removed from the list`);

          // Filter out the selected users from the displayedUsers array
          const updatedDisplayedUsers = this.displayedUsers.filter(
            (user) => !user.selected
          );
          if (this.activeTab === "whitelist") {
            this.displayedWhiteListUsersData = updatedDisplayedUsers;
          } else {
            this.displayedBlackListUsersData = updatedDisplayedUsers;
          }

          // Reset the selectAll checkbox
          this.selectAll = false;
        } else {
          /** network error */
          if (response.code === "ERR_NETWORK") {
            this.toast.error(`Network Error`, {
              position: "bottom-right",
            });
          }
          /** internal server error */
          if (response?.response?.status === 500) {
            this.toast.error(`Internal Server Error`, {
              position: "bottom-right",
            });
            this.router.push("/server-error");
          }
          // Handle error if API request fails
          this.toast.error(`Failed to update user`, {
            position: "bottom-right",
          });
        }

        this.movingAll = false; // Set movingAll back to false after the API request
      }
    },

  async addUser() {
  this.newUserLoading = true; 

  try {
    const response = await addUser(this.newUser, this.userData.accessToken);
    if (response && response.data && response.data.success) {
      this.displayedUsers.push(response.data.user); 
      this.showAddUserModal = false;
      this.showSuccessToast('User added successfully');
      this.newUser = { name: '', email: '' }; // Reset form
    } else {
      this.showErrorToast('Failed to add user');
    }
  } catch (error) {
    console.error('Error adding user:', error);
    this.showErrorToast('Error occurred while adding user');
  } finally {
    this.newUserLoading = false;
  }
},

    // show users
    async fetchData() {
      try {
        this.loading = true;

        let data;
        // white-listed users
        if (this.activeTab === "whitelist") {
          data = await fetchWhiteListUsers(this.userData?.accessToken);

          this.allWhiteListUsers = data?.data || [];
        } else {
          // black-listed users
          data = await fetchBlackListUsers(this.userData?.accessToken);
          this.allBlackListUsers = data?.data || [];
        }

        this.displayedWhiteListUsersData = this.allWhiteListUsers.map(
          (user) => ({
            ...user,
            selected: false,
          })
        );
        this.displayedBlackListUsersData = this.allBlackListUsers.map(
          (user) => ({
            ...user,
            selected: false,
          })
        );

        this.displayedUsersData = data;

        this.loading = false;
      } catch (error) {
        console.error("Error fetching data:", error);
        this.loading = false;
      }
    },
    // make user admin
    async makeUserAdmin(email) {
      let userToUpdate;

      userToUpdate = this.displayedUsers.find((user) => user.email === email);
      if (userToUpdate) {
        userToUpdate.loading = true;
      }

      const data = {
        email: email,
        role: "admin",
      };

      const response = await changeUserRole(data, this.userData?.accessToken);

      // Assuming the API call was successful, update the role in the displayedUsers array
      if (response?.data?.status) {
        userToUpdate.role = "admin";
      }
      /** network error */
      if (response.code === "ERR_NETWORK") {
        this.toast.error(`Network Error`, {
          position: "bottom-right",
        });
      }
      /** internal server error */
      if (response?.response?.status === 500) {
        this.toast.error(`Internal Server Error`, {
          position: "bottom-right",
        });
        this.router.push("/server-error");
      }

      // Set loading back to false after the API call is finished, regardless of success or error
      if (userToUpdate) {
        userToUpdate.loading = false;
        this.showSuccessToast(
          "User has been granted admin privileges successfully"
        );
      }
    },
    // remove user admin
    async removeUserAdmin(email) {
      let userToUpdate;

      userToUpdate = this.displayedUsers.find((user) => user.email === email);
      if (userToUpdate) {
        userToUpdate.loading = true;
      }

      const data = {
        email: email,
        role: "user",
      };

      const response = await changeUserRole(data, this.userData?.accessToken);

      // Assuming the API call was successful, update the role in the displayedUsers array
      if (response?.data?.status) {
        userToUpdate.role = "user";
      }

      /** network error */
      if (response.code === "ERR_NETWORK") {
        this.toast.error(`Network Error`, {
          position: "bottom-right",
        });
      }
      /** internal server error */
      if (response?.response?.status === 500) {
        this.toast.error(`Internal Server Error`, {
          position: "bottom-right",
        });
        this.router.push("/server-error");
      }

      // Set loading back to false after the API call is finished, regardless of success or error
      if (userToUpdate) {
        userToUpdate.loading = false;
        this.showSuccessToast(
          "User's admin privileges have been revoked successfully"
        );
      }
    },

    async toggleUserStatus(user) {
      user.loading = true;

      const newData = {
        email: user.email,
        confirmStatus: !user.confirmStatus, // Toggle the status
      };

      try {
        const response = await changeUserStatus(
          newData,
          this.userData?.accessToken
        );
        if (response?.data?.status) {
          user.confirmStatus = newData.confirmStatus;
          this.showSuccessToast(
            `User status updated to ${user.confirmStatus ? "enabled" : "disabled"}.`
          );
        } else {
          this.showErrorToast("Failed to update user status.");
        }
      } catch (error) {
        console.error("Error toggling user status:", error);
        this.showErrorToast("Error occurred while updating status.");
      } finally {
        user.loading = false;
      }
    },
  },
  async mounted() {
    await this.fetchData();
  },
  watch: {
    // data fetch for the active tab
    activeTab() {
      this.fetchData();
    },
    displayedUsersData: {
      handler() {
        // This empty handler will trigger a re-render of the component
        // whenever displayedUsersData is updated.
      },
      deep: true, // Enable deep watching for changes in nested properties
    },
  },
};
</script>

<style scoped>
.company-table-container {
  margin: 10px 5px 10px 0px;
  /* width: 90%;
  align-self: center; */
}
table tbody tr td {
  background-color: #222 !important;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2) !important;
  color: #fff !important;
}
.pagination-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
input[type="checkbox"] {
  cursor: pointer;
}
input[type="checkbox"]:checked {
  accent-color: #222;
}
.no-users-message {
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: #fff;
  font-weight: bold;
}
.action-btn {
  border: none;
  font-size: 14px;
  padding: 10px 10px;
  border-radius: 5px;
  background-color: #222;
  color: #fff;
  margin-top: 20px;
  border: 1px solid #fff;
}
.admin-btn {
  border: none;
  font-size: 14px;
  border-radius: 5px;
  background-color: #222;
  color: #fff;
  border: 1px solid #fff;
  height: 30px;
}
/* for mobile screen */
@media only screen and (max-width: 768px) {
  .company-table-container {
    margin-top: 20px;
  }
}
</style>
