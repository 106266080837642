<template>
  <div class="main-container">
    <div class="inner-container">
      <h3 class="heading my-3">FORGET PASSWORD</h3>
      <form @submit="onSubmit" class="add-form">
        <!-- email input -->
        <InputField
          label="Email"
          v-model:modelValue="email"
          type="text"
          name="email"
          placeholder="Enter email"
        />
        <!-- loader and submit button -->
        <div class="btn-container mt-4">
          <button class="submit-btn" type="submit" :disabled="isLoading">
            <span v-if="isLoading">
              <div class="spinner-border spinner-border-sm" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </span>
            <span v-else>Submit</span>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import { verifyEmail } from "@/apis/auth.js";
import InputField from "@/components/common/InputField.vue";

export default {
  name: "ForgetPasswordView",
  components: {
    InputField,
  },

  setup() {
    // Get the toast instance using useToast
    const toast = useToast();
    // Get the router instance using useRouter
    const router = useRouter();

    // Define reactive properties using ref()
    const email = ref("");
    const isLoading = ref(false);

    // handle sign-in
    const onSubmit = async (e) => {
      e.preventDefault();
      let apiResponse;

      /** Set isLoading to true before making the sign-up request */
      isLoading.value = true;
      apiResponse = await verifyEmail({ email: email.value });

      /** success case */
      if (apiResponse?.status) {
        /** success message show */
        toast.success(`Code has been sent to your email`, {
          position: "bottom-right",
        });
        /** navigate to reset-password and pass username in route */
        router.push({
          path: "/reset-password",
          query: { email: email.value },
        });
      } else {
        /** failure error */
        if (
          apiResponse?.response?.data.statusCode === "LimitExceededException"
        ) {
          toast.error("Attempt limit exceeded, please try after some time.", {
            position: "bottom-right",
          });
        }
        /** network error */
        if (apiResponse.code === "ERR_NETWORK") {
          toast.error(`Network Error`, {
            position: "bottom-right",
          });
        }
        /** internal server error */
        if (apiResponse?.response?.status === 500) {
          toast.error(`Internal Server Error`, {
            position: "bottom-right",
          });
          router.push("/server-error");
        }
      }

      /** Set isLoading to false after the sign-up request is completed */
      isLoading.value = false;
    };

    // Return the data and methods that need to be used in the template
    return {
      email,
      isLoading,
      onSubmit,
    };
  },
};
</script>

<style scoped>
.main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  background-color: #1c1c1c;
}
.inner-container {
  /* background-color: #222;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px; */
  padding: 10px;
  border-radius: 10px;
  height: 70vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
}
.heading {
  color: #fff;
  text-align: center;
}
.add-form {
  margin-bottom: 20px;
}
.btn-container {
  display: flex;
  justify-content: center;
}
.submit-btn {
  background-color: #1c1c1c;
  color: white;
  width: 220px;
  height: 40px;
  font-size: 18px;
  border: 1px solid #fff;
  border-radius: 5px;
  cursor: pointer;
}
.submit-btn:hover {
  background-color: #333;
}
</style>
