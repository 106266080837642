import axios from "axios";
import { handleUnauthorized } from "./middleware";

const base_url = process.env.VUE_APP_BASE_URL;

/** GET all white-list users */
export async function fetchWhiteListUsers(token) {
  try {
    const response = await axios.get(`${base_url}/users?isWhiteList=${true}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response?.data;
  } catch (error) {
    await handleUnauthorized(error);
    return error;
  }
}

/** Add a new user */
export async function addUser(userData, token) {
  try {
    const response = await axios.post(`${base_url}/users/added`, userData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    await handleUnauthorized(error);
    return error;
  }
}


/** GET all black-list users */
export async function fetchBlackListUsers(token) {
  try {
    const response = await axios.get(`${base_url}/users?isWhiteList=${false}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response?.data;
  } catch (error) {
    await handleUnauthorized(error);
    return error;
  }
}
/** White-List Black-List User */
export async function changeUserStatus(data, token) {
  try {
    const response = await axios.put(`${base_url}/users/white-list/`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    await handleUnauthorized(error);
    return error;
  }
}
/** Change User Role */
export async function changeUserRole(data, token) {
  try {
    const response = await axios.put(`${base_url}/users/change-role`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    await handleUnauthorized(error);
    return error;
  }
}
/** Fetch Messages */
export async function fetchMessages(data, token) {
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios.get(
      `${base_url}/query/fetch/${data?.session}`,
      { headers }
    );
    let messages = [];
    response?.data?.data.map((message) => {
      if (message.query_text) {
        messages.push({ message: message.query_text, isUser: true });
      }
      if (message.answer_text) {
        messages.push({ message: message.answer_text, isUser: false });
      }
    });
    return messages;
  } catch (error) {
    await handleUnauthorized(error);
    return [];
  }
}
/** Check OpenAI key */
export async function checkOpenAIKey(token) {
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios.get(`${base_url}/query/check-open-ai-key`, {
      headers,
    });
    return response?.data?.data;
  } catch (error) {
    return error;
  }
}
/** Send Query */
export async function sendQuery(text, session, token) {
  try {
    let bodyData = {
      text,
      session,
    };

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const response = await axios.post(`${base_url}/query`, bodyData, {
      headers,
    });

    let message = {
      message: response?.data?.data?.answer_text,
      isUser: false,
    };

    return message;
  } catch (error) {
    return error;
  }
}
/** chat sessions, if isNew = true then new session will create else all sessions will fetch */
export async function chatSessions(token, isNew = false) {
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const params = {
      isNew: isNew ? true : false,
    };

    const response = await axios.get(`${base_url}/sessions`, {
      headers,
      params,
    });
    return response?.data;
  } catch (error) {
    await handleUnauthorized(error);
    return error;
  }
}
/** Fetch Company Detail */
export async function fetchCompanyDetail(data, token) {
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios.get(
      `${base_url}/companies/${data?.companyId}`,
      {
        headers,
      }
    );

    return response?.data?.data;
  } catch (error) {
    await handleUnauthorized(error);
    return [];
  }
}
/** Update Company Detail */
export async function UpdateCompanyDetail(data, token) {
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios.put(
      `${base_url}/companies/${data?.companyId}`,
      data,
      {
        headers,
      }
    );

    return response?.data;
  } catch (error) {
    return error;
  }
}

/** GET User token */
export async function fetchUserToken() {
  try {
    const response = await axios.get(`${base_url}/user-token`);
    return response?.data?.data;
  } catch (error) {
    return [];
  }
}
