<template>
  <div class="w-full h-full">
    <Chart
      type="bar"
      :data="chartData"
      :options="chartOptions"
      class="w-full h-full"
    />
  </div>
</template>

<script setup>
import { defineProps, ref, onMounted } from "vue";
const labels = ref([]);
const data = ref([]);
const props = defineProps({
  data: Array,
  selectedLlmType: String,
  selectedDate: Date,
  hostName: String,
  startHour: String,
  endHour: String,
  selectedCategory: String,
});

import Chart from "primevue/chart";
const chartData = ref();
const chartOptions = ref();

const setChartData = () => {
  const documentStyle = getComputedStyle(document.documentElement);

  return {
    labels: labels.value,
    datasets: [
      {
        label: "My Second dataset",
        backgroundColor: [
          "#C83F51",
          "#C83F51",
          "#C83F51",
          "#C83F51",
          "#C83F51",
          "#C83F51",
          "#C83F51",
        ],
        borderColor: documentStyle.getPropertyValue("--pink-500"),
        data: data.value,
      },
    ],
  };
};
const setChartOptions = () => {
  const textColorSecondary = "#F8F6FF";
  const surfaceBorder = "#33279A";

  return {
    indexAxis: "x",

    maintainAspectRatio: false,
    aspectRatio: 0.8,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        ticks: {
          color: textColorSecondary,
          font: {
            weight: 500,
          },
        },
        grid: {
          display: true,
          color: surfaceBorder,
          drawBorder: true,
        },
      },
      y: {
        ticks: {
          color: textColorSecondary,
        },
        grid: {
          color: surfaceBorder,
          drawBorder: true,
        },
      },
    },
    elements: {
      bar: {
        borderRadius: 0, // Adjust the value based on your preference
      },
    },
  };
};

const handleData = async () => {
  const result = props.data;

  labels.value = result.map((item) => item._id);
  data.value = result.map((item) => item.count);

  chartData.value = setChartData();
  chartOptions.value = setChartOptions();
};

onMounted(handleData);
</script>
