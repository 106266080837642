<template>
  <div>
    <Companies />
  </div>
</template>

<script>
import Companies from "@/components/dashboard/company/Companies.vue";

export default {
  name: "CompanyList",
  components: {
    Companies,
  },
};
</script>
