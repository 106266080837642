<template>
  <div class="w-full h-full">
    <Chart
      type="bar"
      :data="chartData"
      :options="chartOptions"
      class="w-full h-full"
    />
  </div>
</template>

<script setup>
import { defineProps, ref, onMounted, watch } from "vue";

import Chart from "primevue/chart";
const labels = ref([]);
const data = ref([]);
const chartData = ref();
const chartOptions = ref(null);

// const mainData = [
//   {
//     text: "HR",
//     value: 789,
//     color: "hr",
//   },
//   {
//     text: "Legal",
//     value: 789,
//     color: "legal",
//   },
//   {
//     text: "Finance",
//     value: 1208,
//     color: "finance",
//   },
//   {
//     text: "Marketing",
//     value: 2653,
//     color: "marketing",
//   },
//   {
//     text: "Sales",
//     value: 904,
//     color: "sales",
//   },
//   {
//     text: "IT",
//     value: 4596,
//     color: "it",
//   },
//   {
//     text: "Dev",
//     value: 3294,
//     color: "dev",
//   },
// ];

const props = defineProps({
  data: Array,
  selectedLlmType: String,
  selectedDate: Date,
  hostName: String,
  startHour: String,
  endHour: String,
  selectedCategory: String,
});

const setChartData = () => {
  return {
    labels: labels.value,
    datasets: [
      {
        data: data.value,
        backgroundColor: ["#C35202", "#1A69E0", "#DC132C"],
        hoverBackgroundColor: ["#C35202", "#1A69E0", "#DC132C"],
      },
    ],
  };
};

const setChartOptions = () => {
  const textColorSecondary = "#333";
  const surfaceBorder = "#33279A";

  return {
    indexAxis: "y",

    maintainAspectRatio: false,
    aspectRatio: 0.8,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        ticks: {
          color: textColorSecondary,
          font: {
            weight: 500,
          },
        },
        grid: {
          display: true,
          color: surfaceBorder,
          drawBorder: true,
        },
      },
      y: {
        ticks: {
          color: textColorSecondary,
        },
        grid: {
          color: surfaceBorder,
          drawBorder: true,
        },
      },
    },
    elements: {
      bar: {
        borderRadius: 10, // Adjust the value based on your preference
      },
    },
  };
};
const handleData = async () => {
  const llmsTypes = props.data;
  const datalabels = llmsTypes.map((item) => item._id);
  const datacounts = llmsTypes.map((item) => item.count);
  const response = {
    data: [
      {
        labels: datalabels,
        data: datacounts,
      },
    ],
  };

  labels.value = response.data[0].labels;
  data.value = response.data[0].data;
  chartData.value = setChartData();
  chartOptions.value = setChartOptions();
};

watch(
  () => [
    props.selectedLlmType,
    props.data,
    props.hostName,
    props.selectedCategory,
    props.selectedDate,
    props.startHour,
    props.endHour,
  ],
  handleData,
  { immediate: true }
);

onMounted(handleData);
</script>
