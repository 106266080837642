<template>
  <div
    class="flex w-full items-center justify-center md:flex-row flex-col gap-6"
  >
    <div class="flex justify-content-center h-full w-full">
      <Chart type="pie" :data="chartData" :options="chartOptions" />
    </div>
  </div>
</template>

<script setup>
import { defineProps, ref, onMounted, watch } from "vue";

import Chart from "primevue/chart";
const labels = ref([]);
const data = ref([]);
const chartData = ref();
const chartOptions = ref(null);

// const mainData = [
//   {
//     text: "Azure",
//     value: 789,
//     color: "hr",
//   },
//   {
//     text: "ECP",
//     value: 789,
//     color: "legal",
//   },
//   {
//     text: "AWS",
//     value: 1208,
//     color: "finance",
//   },
// ];

const props = defineProps({
  data: Array,
  selectedLlmType: String,
  selectedDate: Date,
  hostName: String,
  startHour: String,
  endHour: String,
});

const setChartData = () => {
  return {
    labels: labels.value,
    datasets: [
      {
        data: data.value,
        backgroundColor: ["#C35202", "#1A69E0", "#DC132C"],
        hoverBackgroundColor: ["#C35202", "#1A69E0", "#DC132C"],
      },
    ],
  };
};

const setChartOptions = () => {
  const textColor = "#333"; // Updated for contrast against a white background

  return {
    plugins: {
      legend: {
        display: true,
        labels: {
          color: textColor,
        },
      },
    },
    scales: {
      x: {
        ticks: {
          color: textColor,
        },
        grid: {
          color: "#e0e0e0",
        },
      },
      y: {
        ticks: {
          color: textColor,
        },
        grid: {
          color: "#e0e0e0",
        },
      },
    },
  };
};

const handleData = async () => {
  const cloudService = props.data;
  const datalabels = cloudService.map((item) => item._id);
  const datacounts = cloudService.map((item) => item.count);
  const response = {
    data: [
      {
        labels: datalabels,
        data: datacounts,
      },
    ],
  };

  labels.value = response.data[0].labels;
  data.value = response.data[0].data;
  chartData.value = setChartData();
  chartOptions.value = setChartOptions();
};

watch(
  () => [
    props.selectedLlmType,
    props.hostName,
    props.selectedDate,
    props.startHour,
    props.endHour,
    props.data,
  ],
  handleData,
  { immediate: true }
);

onMounted(handleData);
</script>
