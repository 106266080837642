<template>
  <div class="w-full h-full">
    <div class="flex items-center w-full overflow-scroll gap-4">
      <div v-for="pin in pins" :key="pin.x" :class="pinClass(pin.data.color)">
        <span class="text-md font-medium block text-black">{{
          pin.data.name
        }}</span>
        <span class="text-white text-sm">{{ pin.data.value }}</span>
      </div>
    </div>
    <span class="h-full w-full relative">
      <img
        class="h-full w-full"
        :src="`data:image/svg+xml;utf8,${encodeURIComponent(svgMap)}`"
        alt=""
      />
    </span>
  </div>
</template>

<script setup>
import DottedMap from "dotted-map";

const map = new DottedMap({ height: 60, grid: "vertical" });

map.addPin({
  lat: 31.5204,
  lng: 74.3587,
  data: { name: "Lahore", value: 300, color: "orange_" },
  svgOptions: { color: "#E74C3C", radius: 3 },
  bg: "orange",
});
map.addPin({
  lat: 19.076,
  lng: 72.8777,
  data: { name: "Mumbai", value: 250, color: "purple_" },
  svgOptions: { color: "#8E44AD", radius: 3 },
});

const points = map.getPoints();
const pins = points.filter((point) => point.data);

console.log(pins);

const svgMap = map.getSVG({
  radius: 0.35,
  color: "#D1D5DA",
  shape: "circle",
  backgroundColor: "transparent",
});

const pinClass = (pin) => {
  console.log("====================================");
  console.log(pin);
  console.log("====================================");
  return [
    ` px-2 py-2 whitespace-nowrap gap-4 rounded-md cursor-pointer bg-${pin}`,
  ];
};
</script>

<style scoped></style>
