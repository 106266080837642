<template>
  <div class="w-full h-full">
    <Chart
      type="line"
      :data="chartData"
      :options="chartOptions"
      class="w-full h-full"
    />
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import Chart from "primevue/chart";

onMounted(() => {
  chartData.value = setChartData();
  chartOptions.value = setChartOptions();
});

const chartData = ref();
const chartOptions = ref();

const setChartData = () => {
  return {
    labels: ["January", "February", "March", "April", "May", "June", "July"],
    datasets: [
      {
        label: "APAC",

        data: [0, 0, 400, 1000, 3000, 8000, 17000],
        fill: true,
        tension: 0.4,
        borderColor: (context) => {
          const gradient = context.chart.ctx.createLinearGradient(0, 0, 0, 300);
          gradient.addColorStop(0, "rgba(174, 251, 77, 1)");
          gradient.addColorStop(1, "rgba(174, 251, 77, 0.4)");
          return gradient;
        },
        backgroundColor: (context) => {
          const gradient = context.chart.ctx.createLinearGradient(0, 0, 0, 300);
          gradient.addColorStop(0, "rgba(174, 251, 77, 1)");
          gradient.addColorStop(1, "rgba(174, 251, 77, 0.4)");
          return gradient;
        },
        borderWidth: 8,
      },
      {
        label: "Europe",
        data: [0, 0, 200, 500, 4000, 11500, 20000],
        fill: true,
        tension: 0.4,
        borderColor: (context) => {
          const gradient = context.chart.ctx.createLinearGradient(0, 0, 0, 300);
          gradient.addColorStop(0, "rgba(47, 70, 189, 1)");
          gradient.addColorStop(1, "rgba(47, 70, 189, 0.4)");
          return gradient;
        },
        backgroundColor: (context) => {
          const gradient = context.chart.ctx.createLinearGradient(0, 0, 0, 300);
          gradient.addColorStop(0, "rgba(47, 70, 189, 1)");
          gradient.addColorStop(1, "rgba(47, 70, 189, 0.4)");
          return gradient;
        },
        borderWidth: 8,
      },
      {
        label: "US",
        data: [0, 0, 200, 700, 5000, 12500, 25000],
        fill: true,
        tension: 0.3,
        borderColor: (context) => {
          const gradient = context.chart.ctx.createLinearGradient(0, 0, 0, 300);
          gradient.addColorStop(0, "rgba(79, 191, 255, 1)");
          gradient.addColorStop(1, "rgba(79, 191, 255, 0.4)");
          return gradient;
        },
        backgroundColor: (context) => {
          const gradient = context.chart.ctx.createLinearGradient(0, 0, 0, 300);
          gradient.addColorStop(0, "rgba(79, 191, 255, 1)");
          gradient.addColorStop(1, "rgba(79, 191, 255, 0.4)");
          return gradient;
        },
        borderWidth: 8,
      },
    ],
  };
};

const setChartOptions = () => {
  return {
    maintainAspectRatio: false,
    aspectRatio: 4,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        ticks: {
          color: "white", // Set x-axis label color to white
        },
        grid: {
          color: "#33279A", // Set x-axis grid color
          border: {
            dash: [2, 4],
          },
        },
      },
      y: {
        ticks: {
          color: "white", // Set y-axis label color to white
        },
        grid: {
          color: "#33279A", // Set y-axis grid color
          border: {
            dash: [2, 4],
          },
        },
      },
    },
  };
};
</script>
