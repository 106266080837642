import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import Toast from "vue-toastification";
import store from "./store";

import "bootstrap/dist/css/bootstrap.css";

import "bootstrap/dist/js/bootstrap.js";
import "@fortawesome/fontawesome-free/css/all.css";
import "vue-toastification/dist/index.css";
import PrimeVue from "primevue/config";
import Dropdown from "primevue/dropdown";
import Calendar from "primevue/calendar";
import Button from "primevue/button";

const app = createApp(App);

app.use(router);

app.use(Toast);
app.use(store);
app.use(PrimeVue, { ripple: true });
// eslint-disable-next-line vue/multi-word-component-names
app.component("Dropdown", Dropdown);
// eslint-disable-next-line vue/multi-word-component-names
app.component("Calendar", Calendar);
// eslint-disable-next-line vue/multi-word-component-names
app.component("Button", Button);

app.mount("#app");
