<template>
  <!-- <h3 class="text-center">Change Email</h3> -->
  <div style="display: flex; justify-content: center">
    <form @submit="onSubmit" class="add-form">
      <!-- new email input -->
      <InputField
        label="New Email"
        v-model:modelValue="newEmail"
        type="email"
        name="newEmail"
        :placeholder="`Enter new email @${userData?.domain}`"
        @input="validateNewEmail"
      />
      <p class="info-message">domain should be {{ userData?.domain }}</p>
      <!--email error messages if current and new email same -->
      <p class="error-message" v-if="newEmailErrorMessage">
        {{ newEmailErrorMessage }}
      </p>
      <!-- loader and change email button -->
      <div class="btn-container mt-3">
        <button
          class="change-btn"
          type="submit"
          :disabled="isEmailsSame || isLoading"
        >
          <span v-if="isLoading">
            <div class="spinner-border spinner-border-sm" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </span>
          <span v-else>Change Email</span>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import { changeUserEmail } from "@/apis/auth.js";
import InputField from "../common/InputField.vue";
import { parseJSONFromLocalStorage } from "@/utils/utils";

export default {
  name: "ChangeEmail",
  components: {
    InputField,
  },
  data() {
    return {
      newEmail: "",
      newEmailErrorMessage: "",
      userData: parseJSONFromLocalStorage("loggedInUser"),
      isLoading: false,
      toast: useToast(),
      router: useRouter(),
    };
  },
  methods: {
    /** if new and current email same */
    validateNewEmail() {
      this.newEmailErrorMessage = "";
      if (this.newEmail === this.userData.email) {
        this.newEmailErrorMessage = `${this.newEmail} is your current email`;
      }
    },
    /** change email on submit */
    async onSubmit(e) {
      e.preventDefault();
      this.isLoading = true;

      const data = {
        oldEmail: this.userData.email,
        newEmail: this.newEmail,
      };

      const response = await changeUserEmail(data, this.userData.accessToken);

      /** success case */
      if (response?.status) {
        this.toast.success("Email Changed Successfully", {
          position: "bottom-right",
        });
        this.toast.success("Please Login Again", {
          position: "bottom-right",
        });
        localStorage.removeItem("loggedInUser");
        /**  After successful sign-out, you can redirect to the login page */
        this.router.push("/login");
      } else {
        /** network error */
        if (response.code === "ERR_NETWORK") {
          this.toast.error(`Network Error`, {
            position: "bottom-right",
          });
        }
        /** unauthorized */
        if (response?.status === 401) {
          localStorage.removeItem("loggedInUser");
          alert("Session Expired! Please Login Again");
          window.location.href = "/login";
        }
        /** internal server error */
        if (response?.response?.status === 500) {
          this.toast.error(`Internal Server Error`, {
            position: "bottom-right",
          });
          this.router.push("/server-error");
        }
        this.newEmail = "";
      }

      this.isLoading = false;
    },
  },
  computed: {
    isEmailsSame() {
      return this.newEmail === this.userData.email;
    },
  },
};
</script>

<style scoped>
.add-form {
  margin-bottom: 20px;
}
.btn-container {
  display: flex;
  justify-content: center;
}
.change-btn {
  background-color: #333;
  color: white;
  width: 150px;
  height: 40px;
  font-size: 18px;
  border: 1px solid #fff;
  border-radius: 5px;
  cursor: pointer;
}
.change-btn:hover {
  background-color: #555;
}
.info-message {
  color: #555;
  font-size: 12px;
  max-width: 300px;
  margin-left: 15px;
}
.error-message {
  color: red;
  font-size: 12px;
  max-width: 300px;
  margin-left: 15px;
}
</style>
