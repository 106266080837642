<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="179"
    height="71"
    viewBox="0 0 179 71"
    fill="none"
  >
    <path
      d="M72.2404 42L71.045 38.0154H65.7815L64.5765 42H61L66.4756 25H70.2979L76 42H72.2452H72.2404ZM66.6057 35.2701H70.24L68.4422 29.2265L66.6105 35.2701H66.6057Z"
      fill="#F8F7FF"
    />
    <path
      d="M78.8306 40.408C77.8923 39.3482 77.4231 37.8858 77.4231 36.0255C77.4231 34.1651 77.91 32.6934 78.8793 31.6151C79.8531 30.5368 81.1367 30 82.739 30C84.0713 30 85.1868 30.4026 86.0764 31.2125C86.9661 32.0224 87.4663 33.0775 87.5769 34.3779H84.5272C84.4741 33.818 84.2617 33.3783 83.8943 33.059C83.5269 32.7351 83.0931 32.5777 82.5974 32.5777C81.9556 32.5777 81.4377 32.86 81.0526 33.4246C80.6676 33.9892 80.4728 34.85 80.4728 36.0023C80.4728 37.1546 80.6631 38.0432 81.0482 38.5985C81.4333 39.1585 81.9467 39.4362 82.5974 39.4362C83.1153 39.4362 83.5535 39.2696 83.8987 38.941C84.2484 38.6124 84.4564 38.182 84.5228 37.6545H87.5725C87.4619 38.9688 86.9528 40.0239 86.0366 40.8153C85.1248 41.6066 83.9651 42 82.562 42C81.0084 42 79.7646 41.4724 78.8262 40.4127L78.8306 40.408Z"
      fill="#F8F7FF"
    />
    <path
      d="M98.7083 30.0047H102V41.783H99.1009L98.844 40.0991C98.5531 40.6698 98.078 41.1274 97.4187 41.4764C96.7594 41.8255 96.0079 42 95.1692 42C93.8942 42 92.881 41.5991 92.1296 40.8019C91.3781 40.0047 91 38.9387 91 37.6132V30H94.2918V37.033C94.2918 37.75 94.4614 38.316 94.796 38.7453C95.1353 39.1698 95.6637 39.3868 96.3812 39.3868C97.1472 39.3868 97.7241 39.1462 98.1168 38.6604C98.5095 38.1745 98.7083 37.5283 98.7083 36.717V30V30.0047Z"
      fill="#F8F7FF"
    />
    <path
      d="M118.83 30C120.133 30 121.15 30.3863 121.892 31.1637C122.629 31.9411 123 32.9918 123 34.3251V41.9953H119.596V34.881C119.596 33.3734 118.99 32.6196 117.782 32.6196C117.115 32.6196 116.599 32.841 116.238 33.2933C115.877 33.7456 115.692 34.3345 115.692 35.0789V41.9953H112.308V34.9282C112.308 33.3922 111.697 32.6243 110.474 32.6243C109.822 32.6243 109.316 32.8457 108.95 33.298C108.584 33.7503 108.404 34.3392 108.404 35.0836V42H105V30.2356H107.977L108.223 31.5783C108.95 30.5371 110.063 30.0141 111.556 30.0047C112.348 30.0047 113.05 30.1932 113.662 30.5654C114.273 30.9376 114.704 31.4417 114.95 32.0683C115.316 31.4276 115.842 30.9234 116.529 30.5559C117.221 30.1885 117.987 30.0047 118.83 30.0047V30Z"
      fill="#F8F7FF"
    />
    <path
      d="M130.585 39.5648C131.652 39.5648 132.328 39.125 132.611 38.2454H135.478C135.29 39.3889 134.748 40.3009 133.852 40.9815C132.956 41.662 131.853 42 130.545 42C128.999 42 127.758 41.4907 126.826 40.463C125.889 39.4398 125.423 37.9769 125.423 36.0741C125.423 34.1713 125.889 32.6991 126.817 31.6204C127.744 30.5417 129.008 30 130.608 30C132.207 30 133.368 30.5 134.251 31.4954C135.133 32.4954 135.577 33.8287 135.577 35.4954C135.577 35.9676 135.537 36.4028 135.46 36.7963H128.394V36.9676C128.394 37.8056 128.591 38.4491 128.981 38.8935C129.371 39.338 129.909 39.5648 130.585 39.5648ZM130.522 32.4028C129.873 32.4028 129.362 32.6111 128.994 33.0232C128.627 33.4352 128.425 34 128.394 34.7083H132.57V34.6759C132.57 33.9722 132.382 33.4213 132.006 33.0139C131.629 32.6065 131.136 32.4028 130.527 32.4028H130.522Z"
      fill="#F8F7FF"
    />
    <path
      d="M139 41.9953V30.2262H142.019L142.264 31.7251C142.596 31.216 143.091 30.8013 143.745 30.4808C144.399 30.1602 145.096 30 145.832 30C147.154 30 148.178 30.3771 148.909 31.1312C149.635 31.8853 150 33.0542 150 34.6332V42H146.769V34.9065C146.769 34.209 146.596 33.6528 146.25 33.238C145.904 32.8233 145.404 32.6206 144.75 32.6206C143.851 32.6206 143.212 32.8987 142.832 33.4548C142.452 34.011 142.264 34.7086 142.264 35.5475V41.9953H139Z"
      fill="#F8F7FF"
    />
    <path
      d="M168.24 42L167.045 38.0154H161.781L160.576 42H157L162.476 25H166.298L172 42H168.245H168.24ZM162.606 35.2701H166.24L164.442 29.2265L162.611 35.2701H162.606Z"
      fill="#F8F7FF"
    />
    <path d="M178 42H174V25H178V42Z" fill="#F8F7FF" />
    <path
      d="M43.7612 63C46.0434 51.3382 31.6034 29.5677 16.0223 24C14.3183 25.677 12.9521 27.6224 11.7036 29.7432C7.92217 36.183 1.48506 48.5053 5.0311 56.4312C6.06472 58.7429 9.48284 59.1506 10.3578 56.0648C13.0238 46.6683 18.9134 42.4731 26.1538 48.3969C26.5632 48.7014 26.9725 49.0368 27.3819 49.398C32.6574 54.0782 37.4264 59.4292 43.7663 63H43.7612Z"
      fill="url(#paint0_linear_9_232)"
    />
    <path
      d="M30.8875 9.59296C30.8875 9.59296 32.3054 17.6284 30 29.6737C33.9862 33.2831 39.8662 37.9252 43.1385 40.133C45.4687 41.7032 47.085 41.3081 47.3923 37.5564C48.017 29.9794 49.3011 12.7176 44.0805 7.66444C41.9684 5.62528 37.7641 4.65575 30.8875 9.59296Z"
      fill="url(#paint1_linear_9_232)"
    />
    <path
      d="M27.5934 27.6335C32.9956 32.93 38.8425 39.2188 43.1302 43.8739C46.0387 47.0308 48.9971 50.5134 53 52.8134C48.6673 36.598 56.5981 9.65424 49.5868 2.92957C44.2896 -1.5953 35.8491 2.44852 28.7178 8.74727C15.5998 20.3275 3.17132 38.7427 1.21735 54.8679C0.31283 62.3192 2.2668 68.9035 6.41961 70.6473C6.6295 70.7225 6.83439 70.7977 7.04428 70.8678C9.81781 71.6696 12.2215 68.7432 13.1311 64.2784C13.6208 61.8732 13.5308 58.5459 13.8107 55.369C14.6652 45.6528 20.2173 44.0142 25.9793 48.2986C18.4632 42.1852 12.6113 46.3042 10.0077 55.4291C9.15316 58.4257 6.6245 58.3405 5.61503 56.1006C2.15686 48.4088 8.53848 36.6081 12.2315 30.3544C14.3654 26.7365 16.8841 23.3842 19.5577 20.3576C24.8449 14.3846 29.3275 11.1625 30.5219 10.3558C37.4482 5.66053 41.691 6.58254 43.8199 8.52177C49.0871 13.3273 47.7878 29.7431 47.1581 36.9488C46.8482 40.5166 45.2191 40.8924 42.8703 39.3992C39.2772 37.1142 31.5763 31.3817 27.5834 27.6385L27.5934 27.6335Z"
      fill="url(#paint2_linear_9_232)"
    />
    <path
      d="M43.0434 43.7561C38.7502 39.0494 32.9077 32.703 27.5051 27.3536C24.5963 24.4739 21.8175 21.8877 19.4785 20C18.329 21.3108 17.1545 22.743 16 24.2968C31.6133 31.5137 44.7677 53.6047 43.0934 62.5475C50.6351 66.9555 56.5126 66.3786 52.9142 52.7849C48.9059 50.4619 45.9522 46.9445 43.0434 43.7561Z"
      fill="url(#paint3_linear_9_232)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_9_232"
        x1="6.97042"
        y1="35.2593"
        x2="50.072"
        y2="74.5797"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#03045E" />
        <stop offset="0.23" stop-color="#1A94B6" />
        <stop offset="0.47" stop-color="#18DAB6" />
        <stop offset="0.73" stop-color="#18D7B6" />
        <stop offset="1" stop-color="#CAF0F8" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_9_232"
        x1="46.4503"
        y1="11.4214"
        x2="1.40062"
        y2="81.6226"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#03045E" />
        <stop offset="0.23" stop-color="#1A94B6" />
        <stop offset="0.47" stop-color="#18DAB6" />
        <stop offset="0.73" stop-color="#18D7B6" />
        <stop offset="1" stop-color="#CAF0F8" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_9_232"
        x1="11.9417"
        y1="72.311"
        x2="53.132"
        y2="8.92442"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.17" stop-color="#18DAB6" />
        <stop offset="0.24" stop-color="#18D6B6" />
        <stop offset="0.3" stop-color="#18CAB6" />
        <stop offset="0.37" stop-color="#19B6B6" />
        <stop offset="0.44" stop-color="#1999B6" />
        <stop offset="0.51" stop-color="#1B75B6" />
        <stop offset="0.61" stop-color="#1A78B6" />
        <stop offset="0.71" stop-color="#1A84B6" />
        <stop offset="0.8" stop-color="#1998B6" />
        <stop offset="0.9" stop-color="#19B5B6" />
        <stop offset="1" stop-color="#18DAB6" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_9_232"
        x1="-20.4645"
        y1="8.78498"
        x2="90.277"
        y2="118.146"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#03045E" />
        <stop offset="0.5" stop-color="#00B4D8" />
        <stop offset="1" stop-color="#CAF0F8" />
      </linearGradient>
    </defs>
  </svg>
</template>
