<template>
  <div>
    <!-- Show loading message when data is being fetched -->
    <div
      v-if="loadChat"
      style="
        height: 70vh;
        display: flex;
        justify-content: center;
        align-items: center;
      "
    >
      <div class="spinner-border spinner-border-sm" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <!-- Loop through the chats to display chat history -->
    <div v-else>
      <div
        class="chat-instance"
        v-for="(chat, index) in chatHistory"
        :key="index"
        :class="{ 'selected-chat': chat._id === selectedChatId }"
        @click="selectChat(chat._id)"
      >
        <router-link :to="`/chat/${chat._id}`">
          <!-- <div>{{ chat?.user }}</div> -->
          <div style="width: 180px">Chat {{ index + 1 }}</div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { chatSessions } from "@/apis/apis";
import { ref, onMounted, computed, watch } from "vue";
import { parseJSONFromLocalStorage } from "@/utils/utils";

export default {
  name: "ChatHistory",
  setup() {
    const store = useStore();

    let apiResponse;
    let currentSessionId = null;
    const chatHistory = ref([]);
    const loadChat = ref(false);

    const userData = parseJSONFromLocalStorage("loggedInUser");

    const selectedChatId = computed(() => store.state.selectedChatId);
    const shouldRerender = computed(() => store.state.isNewSessionCreated);

    const getChatHistory = async () => {
      loadChat.value = true;
      if (userData?.accessToken) {
        apiResponse = await chatSessions(userData?.accessToken, false);
      }
      // console.log({ apiResponse });
      if (apiResponse?.status) {
        chatHistory.value = apiResponse?.data;
        loadChat.value = false;
      } else {
        loadChat.value = false;
      }
    };

    const selectChat = async (chatId) => {
      store.state.selectedChatId = chatId;
    };

    onMounted(async () => {
      await getChatHistory();
    });

    watch(shouldRerender, (newValue) => {
      if (newValue) {
        getChatHistory();
      }
    });

    return {
      loadChat,
      chatHistory,
      getChatHistory,
      selectChat,
      selectedChatId,
      currentSessionId,
      shouldRerender,
    };
  },
};
</script>

<style scoped>
.chat-instance {
  height: 40px;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  list-style: none;
  width: 200px;
  display: flex;
  align-items: center;
  margin: 10px 0;
  padding: 0px 10px;
  overflow: hidden;
}
.chat-instance a {
  color: #fff;
  text-decoration: none;
}
.chat-instance.selected-chat {
  background-color: #333;
  border: 1px solid #fff;
}
</style>
