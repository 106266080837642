<template>
  <DashboardLayout>
    <LoaderView v-show="dashboardDataIsLoading" />

    <div
      v-show="!dashboardDataIsLoading"
      class="grid grid-cols-1 md:grid-cols-2 gap-8 py-12 px-8"
    >
      <div class="col-span-1 md:col-span-2 rounded-md bg-[#2D237E] p-4">
        <table class="min-w-full">
          <thead>
            <tr class="text-left text-white">
              <th class="px-4 py-2">Domain Name</th>
              <th class="px-4 py-2">Admin Email</th>
              <th class="px-4 py-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="domain in domains"
              :key="domain.id"
              class="border-t border-gray-300"
            >
              <td class="px-4 py-2 text-white">{{ domain.domain }}</td>
              <td class="px-4 py-2 text-white">
                {{ domain?.adminEmail || "N/A" }}
              </td>
              <td class="px-4 py-2">
                <button
                  class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                  @click="confirmAndRemoveDomain(domain.id)"
                >
                  Remove
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <button
      class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded m-4"
      @click="showModal = true"
    >
      Allow Domain
    </button>

    <!-- Modal -->
    <div
      v-if="showModal"
      class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"
      @click="showModal = false"
    >
      <div
        class="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white"
        @click.stop
      >
        <div class="mt-3 text-center">
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Add Domain
          </h3>
          <div class="mt-2 px-7 py-3">
            <input
              type="text"
              v-model="newDomain"
              placeholder="Enter domain"
              class="mb-4 px-4 py-2 border rounded w-full"
            />
            <input
              type="email"
              v-model="adminEmail"
              placeholder="Enter admin email"
              class="px-4 py-2 border rounded w-full"
            />
          </div>
          <div class="items-center px-4 py-3">
            <button
              id="ok-btn"
              class="px-4 py-2 bg-blue-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
              @click="addDomain"
            >
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
  </DashboardLayout>
</template>

<script setup>
import LoaderView from "@/components/common/LoaderView.vue";
import DashboardLayout from "@/Layouts/DashboardLayout.vue";
import { ref, onMounted } from "vue";

const dashboardDataIsLoading = ref(false);
const showModal = ref(false);
// const showRemoveModal = ref(false);
// const domainToRemove = ref(null);
const domains = ref([]);
const newDomain = ref("");
const adminEmail = ref("");
const base_url = process.env.VUE_APP_BASE_URL;

// Fetch domains
const fetchDomains = async () => {
  dashboardDataIsLoading.value = true;
  try {
    const response = await fetch(`${base_url}/users/authorized-domain`, {
      method: "GET",
    });
    const domainsData = await response.json();
    domains.value = domainsData.data;
  } catch (error) {
    console.error("Failed to fetch domains:", error);
  } finally {
    dashboardDataIsLoading.value = false;
  }
};

// Add domain
const addDomain = async () => {
  try {
    const response = await fetch(`${base_url}/users/authorized-domain`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        domain: newDomain.value,
        adminEmail: adminEmail.value,
      }),
    });
    if (response.ok) {
      fetchDomains(); // Refresh the list
      newDomain.value = ""; // Reset the input
      adminEmail.value = ""; // Reset the email input
      showModal.value = false; // Close the modal
    }
  } catch (error) {
    console.error("Failed to add domain:", error);
  }
};

const confirmAndRemoveDomain = async (id) => {
  if (confirm("You are about to delete a customer account, are you sure?")) {
    removeDomain(id);
  }
};

const removeDomain = async (id) => {
  try {
    const response = await fetch(`${base_url}/users/authorized-domain/${id}`, {
      method: "DELETE",
    });
    if (response.ok) {
      fetchDomains(); // Refresh the list after deletion
    }
  } catch (error) {
    console.error("Failed to remove domain:", error);
  }
};

onMounted(() => {
  fetchDomains();
});
</script>

<style scoped>
.filter-container {
  background-color: #2d237e;
  padding: 16px 32px;
}

.filters-heading {
  color: #fff;
  text-align: center;
  font-size: 1rem; /* 16px */
  @media (min-width: 768px) {
    font-size: 1.5rem; /* 24px */
  }
}

.filter-label {
  color: #fff;
  font-size: 1rem; /* 16px */
  text-align: center;
  margin-right: 10px;
}

.dropdown {
  font-size: 1rem;
  color: #ffffff !important;
  border: 1px solid #ffffff;
  padding: 7px 10px;
  border-radius: 6px;
}

.dropdown .p-dropdown-trigger {
  font-size: 1rem;
  color: rgb(190, 181, 181) !important;
  margin-right: 5px;
}

.calendar .p-inputtext {
  font-size: 1.25rem;
  color: #fff;
}

.calendar .p-datepicker {
  color: #fff;
}

.calendar-input input {
  padding: 0 7px;
}

.p-datepicker.p-component {
  background-color: #ffffff;
  padding: 10px;
}

.clear-filters-button {
  font-size: 15px;
  color: #333 !important;
  border-color: #fff;
  background-color: #fff;
  padding: 2px 10px;
  border-radius: 12px;
}

/* Additional styles to adjust calendar input field size */
.calendar .p-calendar {
  min-width: 200px; /* Adjust this value as needed */
}
</style>
