<template>
  <div>
    <ul class="nav nav-tabs nav-underline">
      <!-- change email tab [start] -->
      <li class="nav-item user-tab">
        <a
          class="nav-link"
          :class="{ active: activeTab === 'Change Email' }"
          @click="setActiveTab('Change Email')"
          >Change Email</a
        >
      </li>
      <!-- change email tab [end] -->

      <!-- change password tab [start] -->

      <li class="nav-item user-tab">
        <a
          class="nav-link"
          :class="{ active: activeTab === 'Change Password' }"
          @click="setActiveTab('Change Password')"
          >Change Password</a
        >
      </li>
      <!-- change password tab [start] -->
    </ul>
    <div class="tab-content mt-3">
      <!-- change email tab content [start] -->
      <div
        class="tab-pane"
        :class="{ active: activeTab === 'Change Email' }"
        id="Change Email"
      >
        <ChangeEmail />
      </div>

      <!-- change email tab content [end] -->

      <!-- change password tab content [start] -->

      <div
        class="tab-pane"
        :class="{ active: activeTab === 'Change Password' }"
        id="Change Password"
      >
        <ChangePassword />
      </div>
      <!-- change password tab content [end] -->
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import ChangeEmail from "./ChangeEmail.vue";
import ChangePassword from "./changePassword/ChangePassword.vue";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Security",
  components: {
    ChangeEmail,
    ChangePassword,
  },
  setup() {
    // Define reactive properties using ref()
    const activeTab = ref("Change Email");

    /** set Active tab */
    const setActiveTab = (tab) => {
      activeTab.value = tab;
    };

    // Return the data and methods that need to be used in the template
    return {
      activeTab,
      setActiveTab,
    };
  },
};
</script>

<style scoped>
.user-tab {
  cursor: pointer;
}
.user-tab .nav-link {
  font-size: 16px !important;
  font-weight: 700 !important;
  padding: 10px 20px;
  color: #555 !important;
  border: 1px solid #555;
}
.user-tab .nav-link:hover {
  border: 1px solid #555;
}
.nav-tabs .nav-link.active {
  background-color: #222 !important;
  color: white !important;
  border-color: #fff !important;
}
.nav-tabs {
  display: flex !important;
  justify-content: center !important;
  border-bottom: none !important;
}
</style>
