<template>
  <template v-if="isOldPasswordResponse">
    <NewPassword />
  </template>
  <template v-else>
    <!-- <h3 class="text-center">Old Password</h3> -->
    <div style="display: flex; justify-content: center">
      <form @submit="onSubmit" class="add-form">
        <!-- old password input -->
        <div class="d-flex position-relative">
          <InputField
            label="Old Password"
            v-model:modelValue="oldPassword"
            :type="showOldPassword ? 'text' : 'password'"
            name="oldPassword"
            placeholder="Enter old password"
          />
          <div class="password-icon" @click="toggleOldPasswordVisibility">
            <i v-if="showOldPassword" class="fa-solid fa-eye"></i>
            <i v-else class="fa-solid fa-eye-slash"></i>
          </div>
        </div>
        <!-- loader and change password button -->
        <div class="btn-container mt-3">
          <button class="change-btn" type="submit">
            <span v-if="isLoading">
              <div class="spinner-border spinner-border-sm" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </span>
            <span v-else>Submit</span>
          </button>
        </div>
      </form>
    </div>
  </template>
</template>

<script>
import NewPassword from "./NewPassword.vue";
import { useToast } from "vue-toastification";
import { changeUserPassword } from "@/apis/auth.js";
import InputField from "../../common/InputField.vue";
import { parseJSONFromLocalStorage } from "@/utils/utils";

export default {
  name: "ChangePassword",
  components: {
    InputField,
    NewPassword,
  },
  data() {
    return {
      toast: useToast(),
      userData: parseJSONFromLocalStorage("loggedInUser"),
      oldPassword: "",
      showOldPassword: false,
      isLoading: false,
      isOldPasswordResponse: false,
    };
  },
  methods: {
    // show/hide password
    toggleOldPasswordVisibility() {
      this.showOldPassword = !this.showOldPassword;
    },

    /** change email on submit */
    async onSubmit(e) {
      e.preventDefault();
      this.isLoading = true;

      const data = {
        oldPassword: this.oldPassword,
        email: this.userData.email,
      };

      const response = await changeUserPassword(
        data,
        this.userData.accessToken
      );

      /** success case */
      if (response?.status) {
        this.isOldPasswordResponse = true;
        this.toast.success("Enter 6 digit verification code", {
          position: "bottom-right",
        });
      } else {
        /** failure case */
        if (response.response.data.statusCode === "NotAuthorizedException") {
          this.toast.error("Incorrect Password", {
            position: "bottom-right",
          });
        }
        if (response.response.data.statusCode === "LimitExceededException") {
          this.toast.error(
            "Attempt limit exceeded, please try after some time",
            {
              position: "bottom-right",
            }
          );
        }
      }

      /** network error */
      if (response.code === "ERR_NETWORK") {
        this.toast.error(`Network Error`, {
          position: "bottom-right",
        });
      }
      /** internal server error */
      if (response?.response?.status === 500) {
        this.toast.error(`Internal Server Error`, {
          position: "bottom-right",
        });
        this.router.push("/server-error");
      }
      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
.add-form {
  margin-bottom: 20px;
}
.btn-container {
  display: flex;
  justify-content: center;
}
.change-btn {
  background-color: #333;
  color: white;
  width: 150px;
  height: 40px;
  font-size: 18px;
  border: 1px solid #fff;
  border-radius: 5px;
  cursor: pointer;
}
.change-btn:hover {
  background-color: #555;
}
.password-icon {
  position: absolute;
  right: 20px;
  top: 52px;
  cursor: pointer;
  color: #222;
}
</style>
