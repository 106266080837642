// Function to parse JSON safely from localStorage
export function parseJSONFromLocalStorage(key) {
    const jsonData = localStorage.getItem(key);
    
    if (jsonData) {
      try {
        return JSON.parse(jsonData);
      } catch (error) {
        console.error(`Error parsing JSON from ${key}:`, error);
        return null;
      }
    } else {
      return null;
    }
  }
  
  