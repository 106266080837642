<template>
  <div>
    <div
      v-if="apiKeyData?.openAiKey || localApiKeyData"
      class="api-key-container"
    >
      <div>
        <span class="label">API KEY</span>
        <span class="key" v-if="localApiKeyData">{{ localApiKeyData }}</span>
        <span class="key" v-else>{{ apiKeyData?.openAiKey }}</span>
      </div>
      <i class="fa-regular fa-pen-to-square" @click="openModal"></i>
    </div>
    <button v-else class="secret-key-btn" @click="openModal">
      <i class="fa-solid fa-plus"></i>
      <span style="padding-left: 10px">Enter a secret key</span>
    </button>
  </div>
  <div style="display: flex; justify-content: center; align-items: center">
    <!-- Model show to add API key -->
    <CustomModal
      v-if="modalVisible"
      @close="closeModal"
      title="Secret Key"
      :marginLeft="dynamicMargin"
    >
      <form @submit="onSubmit" class="add-form">
        <!-- API Key input -->
        <InputField
          label="API Key"
          v-model:modelValue="apiKey"
          type="text"
          name="apiKey"
          placeholder="Enter API Key"
          :width="dynamicWidth"
          :minlength="6"
        />
        <div class="d-flex gpt">
          <div class="mx-2">
            GPT 3.5
            <input type="radio" name="gpt" v-model="gptVersion" value="3.5" />
          </div>
          <div class="mx-2">
            GPT 4
            <input type="radio" name="gpt" v-model="gptVersion" value="4" />
          </div>
        </div>

        <!-- loader and change email button -->
        <div class="btn-container mt-3">
          <button class="change-btn" type="submit" :disabled="isLoading">
            <span v-if="isLoading">
              <div class="spinner-border spinner-border-sm" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </span>
            <span v-else>Submit</span>
          </button>
        </div>
      </form>
    </CustomModal>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import InputField from "../common/InputField.vue";
import CustomModal from "../common/CustomModal.vue";
import { UpdateCompanyDetail } from "@/apis/apis.js";
import { parseJSONFromLocalStorage } from "@/utils/utils";

export default {
  name: "ApiKey",
  components: {
    InputField,
    CustomModal,
  },
  data() {
    return {
      modalVisible: false,
      dynamicMargin: "0px",
      dynamicWidth: "100%",
      apiKey: "",
      localApiKeyData: null,
      gptVersion: "3.5",
      userData: parseJSONFromLocalStorage("loggedInUser"),
      isLoading: false,
      toast: useToast(),
      router: useRouter(),
    };
  },
  methods: {
    /** Modal Open */
    openModal() {
      this.modalVisible = true;
    },
    /** Modal Close */
    closeModal() {
      this.modalVisible = false;
    },
    /** submit API Key */
    async onSubmit(e) {
      e.preventDefault();
      this.isLoading = true;

      const data = {
        openAiKey: this.apiKey,
        companyId: this.userData.company,
        gptVersion: this.gptVersion,
      };

      const response = await UpdateCompanyDetail(
        data,
        this.userData.accessToken
      );

      /** success case */
      if (response?.status) {
        this.localApiKeyData = response.data.openAiKey;
        this.toast.success("OpenAI Key added successfully", {
          position: "bottom-right",
        });
        this.modalVisible = false;
        this.apiKey = "";
        this.isLoading = false;
      } else {
        /** invalid api error */
        if (response?.response?.data?.statusCode === "InvalidKey") {
          this.toast.error("Provided OpenAI key is invalid", {
            position: "bottom-right",
          });
        }
        /** invalid version api error */
        if (response?.response?.data?.statusCode === "invalidGptVersion") {
          this.toast.error("Provided Open AI key is not supported GPT4", {
            position: "bottom-right",
          });
        }
        /** network error */
        if (response.code === "ERR_NETWORK") {
          this.toast.error(`Network Error`, {
            position: "bottom-right",
          });
        }
        /** unauthorized */
        if (response?.status === 401) {
          localStorage.removeItem("loggedInUser");

          window.location.href = "/login";
          alert("Session Expired! Please Login Again");
        }
        this.apiKey = "";
        this.isLoading = false;
      }
    },
  },
  computed: {
    apiKeyData() {
      return this.$store.getters.getApiKeyData;
    },
  },
};
</script>

<style scoped>
.api-key-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 10px;
  margin-bottom: 20px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  width: 60vw;
}
.label,
.key {
  font-size: 18px;
}
.key {
  font-weight: 600;
  margin-left: 30px;
}
.fa-pen-to-square {
  cursor: pointer;
}
.secret-key-btn {
  height: 50px;
  padding: 0 20px;
  border: 1px solid #fff;
  border-radius: 5px;
  color: #fff;
  background-color: #333;
  font-size: 18px;
}
.secret-key-btn:hover {
  background-color: #555;
}
.add-form {
  margin-bottom: 20px;
}
.btn-container {
  display: flex;
  justify-content: flex-end;
  margin-right: 10px;
}
.gpt input {
  accent-color: #333;
}
.change-btn {
  background-color: #333;
  color: white;
  width: 150px;
  height: 40px;
  font-size: 18px;
  border: 1px solid #fff;
  border-radius: 5px;
  cursor: pointer;
}
.change-btn:hover {
  background-color: #555;
}
/* for mobile screen */
@media only screen and (max-width: 768px) {
  .api-key-container {
    max-width: 100%;
  }
}
</style>
