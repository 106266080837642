<template>
  <div class="main-container">
    <h1 class="heading">Companies</h1>

    <table class="table table-bordered">
      <!-- Table Header [start] -->
      <thead>
        <tr>
          <!-- <th scope="col"></th> -->
          <th scope="col" class="text-center">Name</th>
          <th scope="col">Email</th>
          <th scope="col" class="text-center">Users</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <!-- Table Header [end] -->

      <!-- Table Body [start] -->
      <tbody>
        <!-- mapping company data -->
        <tr v-for="company in companies" :key="company.id">
          <!-- <th scope="row">{{ company.id }}</th> -->
          <td class="align-middle text-center">{{ company.name }}</td>
          <td class="align-middle">{{ company.email }}</td>
          <td class="align-middle text-center">{{ company.users_count }}</td>
          <td class="align-middle text-center">
            <router-link
              to="/company/1"
              style="text-decoration: none; color: inherit"
            >
              <button class="detail-button">Details</button>
            </router-link>
          </td>
        </tr>
      </tbody>
      <!-- Table Body [end] -->
    </table>

    <!-- Pagination [start] -->
    <!-- <div class="pagination-container">
      <Pagintaion />
    </div> -->
    <!-- Pagination [end] -->
  </div>
</template>

<script>
import { companiesData } from "@/utils/companiesData";
// import Pagintaion from "@/components/common/Pagintaion.vue";

export default {
  name: "DashboardCompanies",
  data() {
    return {
      companies: companiesData,
    };
  },
  components: {
    // Pagintaion,
  },
};
</script>

<style scoped>
.main-container {
  background-color: #1c1c1c;
  height: 100vh;
  padding: 10px 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.heading {
  text-align: center;
  padding: 10px 0;
  color: #fff;
}
table tbody tr td {
  background-color: #222 !important;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2) !important;
  color: #fff !important;
}
.detail-button {
  border: 1px solid #fff;
  font-size: 14px;
  padding: 5px 15px;
  border-radius: 5px;
  background-color: #333;
  color: #fff;
}
.pagination-container {
  display: flex;
  justify-content: flex-end;
}
/* for mobile screen */
@media only screen and (max-width: 768px) {
  .main-container {
    padding-top: 80px;
  }
}
</style>
