<template>
  <DashboardLayout>
    <div v-show="!dashboardDataIsLoading" class="filter-container">
      <div style="color: white">Apply Filters</div>
      <div class="flex items-center gap-4 flex-wrap">
        <div class="filter-group">
          <Dropdown
            id="llmType"
            class="dropdown"
            v-model="selectedLLMType"
            :options="llmTypes"
            optionLabel="name"
            placeholder="Select LLM Type"
          />
        </div>
        <div class="filter-group">
          <Dropdown
            id="hostName"
            class="dropdown"
            v-model="hostName"
            :options="hostNames"
            optionLabel="name"
            placeholder="Select Host Name"
          />
        </div>
        <div class="filter-group">
          <Calendar
            v-model="selectedFromDate"
            id="selectedFromDate"
            placeholder="Select From Date"
            class="calendar-input"
          />
        </div>
        <div class="filter-group">
          <Calendar
            v-model="selectedToDate"
            id="selectedToDate"
            :min-date="selectedFromDate"
            placeholder="Select To Date"
            class="calendar-input"
          />
        </div>
        <div class="filter-group">
          <!-- <label class="filter-label" for="startHour">Start Hour:</label> -->
          <Dropdown
            id="startHour"
            class="dropdown"
            v-model="startHour"
            :options="hoursOptions"
            placeholder="Select Start Hour"
          />
        </div>
        <div class="filter-group">
          <Dropdown
            id="endHour"
            class="dropdown"
            v-model="endHour"
            :options="hoursOptions"
            placeholder="Select End Hour"
          />
        </div>
        <div class="filter-group">
          <Dropdown
            id="category"
            class="dropdown"
            v-model="selectedCategory"
            :options="categories"
            optionLabel="name"
            placeholder="Select Category"
          />
        </div>
        <div class="filter-group">
          <Button
            label="Clear Filters"
            @click="clearFilters"
            class="clear-filters-button"
            rounded
          />
        </div>
      </div>
    </div>

    <LoaderView v-show="dashboardDataIsLoading" />

    <div
      v-show="!dashboardDataIsLoading"
      class="grid grid-cols-2 gap-8 py-12 px-8"
    >
      <div
        v-if="dashboardData.hostNamesResult"
        class="mycard min-h-[35vh] rounded-md col-span-2 2xl:col-span-1 bg-white"
      >
        <h1
          class="text-gray-800 text-[16px] md:text-[24px] text-center 2xl:text-[20px]"
        >
          Host Names
        </h1>
        <HostNamesChart :data="dashboardData.hostNamesResult" />
      </div>

      <div
        v-if="dashboardData.hourlyLLMUsage"
        class="mycard min-h-[35vh] rounded-md col-span-2 2xl:col-span-1 bg-white"
      >
        <h1
          class="text-gray-800 text-[16px] md:text-[24px] text-center 2xl:text-[20px]"
        >
          Hourly LLM Usage Distribution
        </h1>
        <DistributeChart :data="dashboardData.hourlyLLMUsage" />
      </div>

      <div
        v-if="dashboardData.llmsTypes"
        class="mycard min-h-[35vh] rounded-md col-span-2 2xl:col-span-1 bg-white border border-gray-200 shadow-sm"
      >
        <h1
          class="text-gray-800 text-[16px] md:text-[24px] text-center 2xl:text-[20px]"
        >
          LLMs Types
        </h1>
        <LmmTypes :data="dashboardData.llmsTypes" />
      </div>

      <!-- 2 -->
      <div
        v-if="dashboardData.llmEventsOverTime"
        class="mycard min-h-[35vh] rounded-md col-span-2 2xl:col-span-1 bg-white shadow-md border border-gray-200"
      >
        <h1
          class="text-gray-800 text-[16px] md:text-[24px] text-center 2xl:text-[20px]"
        >
          LLM Events over Time
        </h1>
        <LineChart :data="dashboardData.llmEventsOverTime" />
      </div>
      <div
        v-show="!dashboardDataIsLoading"
        class="mycard min-h-[35vh] rounded-md col-span-2 2xl:col-span-1 bg-white shadow-md border border-gray-300"
      >
        <h1
          class="text-gray-800 text-[16px] md:text-[24px] text-center 2xl:text-[20px]"
        >
          Categories
        </h1>
        <CategoriesChart
          :selected-llm-type="selectedLLMType"
          :hostName="hostName"
          :selectedDate="selectedFromDate"
          :selectedToDate="selectedFromDate"
          :startHour="startHour"
          :endHour="endHour"
          :selectedCategory="selectedCategory"
        />
      </div>

      <!-- 3 -->
      <div
        v-if="dashboardData.activitySubType"
        class="mycard min-h-[35vh] rounded-md col-span-2 2xl:col-span-1 bg-white shadow-md border border-gray-300"
      >
        <h1
          class="text-gray-800 text-[16px] md:text-[24px] text-center 2xl:text-[20px]"
        >
          Activity Sub-Type
        </h1>
        <ChartB :data="dashboardData.activitySubType" />
      </div>

      <div
        v-if="dashboardData.activityType"
        class="mycard min-h-[35vh] rounded-md col-span-2 2xl:col-span-1 bg-white shadow-md border border-gray-300"
      >
        <h1
          class="text-gray-800 text-[16px] md:text-[24px] text-center 2xl:text-[20px]"
        >
          Activity Type
        </h1>
        <ChartA :data="dashboardData.activityType" />
      </div>

      <div
        v-if="dashboardData.cloudService"
        class="mycard min-h-[35vh] rounded-md col-span-2 2xl:col-span-1 bg-white shadow-md border border-gray-300"
      >
        <h1
          class="text-gray-800 text-[16px] md:text-[24px] text-center 2xl:text-[20px]"
        >
          Cloud services using LLMs
        </h1>
        <PieChart :data="dashboardData.cloudService" />
      </div>

      <!-- 4 -->
      <div
        class="mycard min-h-[80vh] rounded-md col-span-2 2xl:col-span-2 bg-[#2D237E]"
      >
        <h1
          class="text-gray-200 text-[16px] md:text-[24px] text-center 2xl:text-[20px]"
        >
          LLMs Usage Map
        </h1>
        <WorldMap />
      </div>
      <div class="rounded-md col-span-2 2xl:col-span-2 bg-[#2D237E]">
        <LogsTable
          :selected-llm-type="selectedLLMType"
          :hostName="hostName"
          :selected-date="selectedFromDate"
          :startHour="startHour"
          :endHour="endHour"
          :selectedCategory="selectedCategory"
        />
      </div>
    </div>
  </DashboardLayout>
</template>

<script setup>
import LoaderView from "@/components/common/LoaderView.vue";
import DashboardLayout from "@/Layouts/DashboardLayout.vue";
import ChartA from "@/components/Logs/ChartA.vue";
import ChartB from "@/components/Logs/ChartB.vue";
import LineChart from "@/components/Logs/LineChart.vue";
import PieChart from "@/components/Logs/PieChart.vue";
import LmmTypes from "@/components/Logs/LmmTypes.vue";
import HostNamesChart from "@/components/Logs/HostNamesChart.vue";
import DistributeChart from "@/components/Logs/DistributeChart.vue";
import CategoriesChart from "@/components/Logs/CategoryMapping.vue";
import WorldMap from "@/components/Logs/WorldMap.vue";
import LogsTable from "@/components/Logs/LogsTable.vue";
import { onMounted, ref, watch } from "vue";
import axios from "axios";
import { parseJSONFromLocalStorage } from "@/utils/utils";
const base_url = process.env.VUE_APP_BASE_URL;

const dashboardDataIsLoading = ref(false);
const llmTypes = ref([]);
const selectedLLMType = ref(null);
const hostNames = ref([]);
const categories = ref([]);
const hostName = ref(null);
const startHour = ref(null);
const selectedCategory = ref(null);
const endHour = ref(null);
const hoursOptions = ref([]);
const selectedFromDate = ref(null);
const selectedToDate = ref(null);
const dashboardData = ref({});

// console.log("<<<llmTypes", llmTypes);

const fetchLlmTypes = async () => {
  try {
    const userData = parseJSONFromLocalStorage("loggedInUser");
    const response = await axios.get(
      `${base_url}/companies/distinct-llm-types-and-hostnames`,
      {
        headers: {
          Authorization: `Bearer ${userData.accessToken}`,
        },
      }
    );

    const responseData = response.data?.data;

    const {
      llmTypeData,
      hostNames: hostNamesData,
      categoriesData,
    } = responseData;

    // Log the raw response data for debugging
    console.log("Raw API response data:", responseData);

    // Check if responseData is an array and process it
    if (Array.isArray(llmTypeData)) {
      llmTypes.value = llmTypeData.map((item) => ({
        name: item.name,
        code: item.code,
      }));
    } else {
      console.error("Expected an array, but received:", typeof responseData);
      llmTypes.value = []; // Reset to empty array if data is not as expected
    }
    if (Array.isArray(hostNamesData)) {
      hostNames.value = hostNamesData.map((item) => ({
        name: item.name,
        code: item.code,
      }));
    } else {
      console.error("Expected an array, but received:", typeof responseData);
      llmTypes.value = []; // Reset to empty array if data is not as expected
      hostNames.value = [];
    }

    if (Array.isArray(categoriesData)) {
      categories.value = categoriesData.map((item) => ({
        name: item.category,
        code: item.category,
      }));
    } else {
      console.error("Expected an array, but received:", typeof responseData);
      categories.value = []; // Reset to empty array if data is not as expected
    }

    const lock = Array.from({ length: 24 }, (_, i) => i + 1);
    hoursOptions.value = lock;
  } catch (error) {
    console.error("Error fetching llmTypes:", error);
  }
};

const fetchDashboardData = async () => {
  dashboardDataIsLoading.value = true;

  try {
    const userData = parseJSONFromLocalStorage("loggedInUser");

    const params = {};

    if (selectedLLMType.value) {
      params.llmType = selectedLLMType.value;
    }

    if (hostName.value) {
      params.hostName = hostName.value;
    }
    if (selectedCategory.value) {
      params.category = selectedCategory.value;
    }

    if (startHour.value && endHour.value) {
      params.startHour = startHour.value;
      params.endHour = endHour.value;
    }

    if (selectedFromDate.value) {
      const offset = selectedFromDate.value.getTimezoneOffset() * 60000; // Convert offset to milliseconds
      const adjustedDate = new Date(selectedFromDate.value.getTime() - offset);
      params.date = adjustedDate.toISOString().split("T")[0];
    }
    if (selectedToDate.value) {
      const offset = selectedToDate.value.getTimezoneOffset() * 60000; // Convert offset to milliseconds
      const adjustedDate = new Date(selectedToDate.value.getTime() - offset);
      params.dateTo = adjustedDate.toISOString().split("T")[0];
    }

    const result = await axios.get(`${base_url}/companies/dashboard`, {
      params,
      headers: {
        Authorization: `Bearer ${userData.accessToken}`,
      },
    });

    dashboardData.value = result.data.data;

    dashboardDataIsLoading.value = false;
  } catch (error) {
    dashboardDataIsLoading.value = false;
    console.error(error);
  }
};

// Call fetchLlmTypes, fetchDashboardData on component mount
onMounted(() => {
  fetchLlmTypes();
  fetchDashboardData();
});

// const emit = defineEmits(['filters-changed']);

// const onFiltersChange = () => {
//   // Emit an event, update the global state, or directly pass the filter values to child components.
//   // For this example, we will emit an event.
//   emit('filters-changed', {
//     selectedLLMType: selectedLLMType.value,
//     selectedFromDate: selectedFromDate.value
//   });
// };

watch(
  [
    selectedLLMType,
    selectedFromDate,
    selectedToDate,
    hostName,
    startHour,
    endHour,
    selectedCategory,
  ],
  () => {
    // Fetch new data based on the current filter values
    // Replace this with your actual data fetching logic
    // console.log("<<<<selectedLLMType", selectedLLMType);
    fetchDashboardData();
  }
);

// // Watchers to detect when the filters change
// watch(selectedLLMType, onFiltersChange);
// watch(selectedFromDate, onFiltersChange);

// Method to clear filters
const clearFilters = () => {
  selectedLLMType.value = null;
  selectedFromDate.value = null;
  selectedToDate.value = null;
  selectedCategory.value = null;
  hostName.value = null;
  startHour.value = null;
  endHour.value = null;
  // onFiltersChange();
};
</script>

<style scoped>
.filter-container {
  background-color: #2d237e;
  padding: 16px 32px;
}

.filters-heading {
  color: #fff;
  text-align: center;
  font-size: 1rem; /* 16px */
  @media (min-width: 768px) {
    font-size: 1.5rem; /* 24px */
  }
}

.filter-label {
  color: #fff;
  font-size: 1rem; /* 16px */
  text-align: center;
  margin-right: 10px;
}

.dropdown {
  font-size: 1rem;
  color: #ffffff !important;
  border: 1px solid #ffffff;
  padding: 7px 10px;
  border-radius: 6px;
}

.dropdown .p-dropdown-trigger {
  font-size: 1rem;
  color: rgb(190, 181, 181) !important;
  margin-right: 5px;
}

.calendar .p-inputtext {
  font-size: 1.25rem;
  color: #fff;
}

.calendar .p-datepicker {
  color: #fff;
}

.calendar-input input {
  padding: 0 7px;
}

.p-datepicker.p-component {
  background-color: #ffffff;
  padding: 10px;
}

.clear-filters-button {
  font-size: 15px;
  color: #333 !important;
  border-color: #fff;
  background-color: #fff;
  padding: 2px 10px;
  border-radius: 12px;
}

/* Additional styles to adjust calendar input field size */
.calendar .p-calendar {
  min-width: 200px; /* Adjust this value as needed */
}
</style>
