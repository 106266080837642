<template>
  <div class="main-container">
    <h1>Network Error</h1>
    <h3 class="error">Please check you internet connection!</h3>
    <button class="back-button" @click="backButtonClick">Go Back</button>
  </div>
</template>

<script>
import { useRouter } from "vue-router";

export default {
  name: "NetworkError",

  setup() {
    // Get the router instance using useRouter
    const router = useRouter();

    // go back
    const backButtonClick = () => {
      router.go(-1);
    };

    return {
      backButtonClick,
    };
  },
};
</script>

<style scoped>
.main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  background-color: #1c1c1c;
  color: #fff;
}
.main-container h1 {
  color: red;
}
.back-button {
  border: 1px solid #fff;
  color: #fff;
  background-color: #1c1c1c;
  border-radius: 5px;
  cursor: pointer;
  padding: 5px 10px;
  margin: 10px 0;
}
</style>
