<template>
  <div class="w-full h-full">
    <apexchart
      type="bubble"
      :options="chartOptions"
      :series="chartSeries"
      width="100%"
      height="500px"
    />
  </div>
</template>

<script>
import VueApexCharts from "vue3-apexcharts";

export default {
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      chartOptions: {
        chart: {
          animations: {
            enabled: true,
          },
        },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: ["24px"],
            colors: ["black", "black", "white", "white", "white", "white"],
          },
        },
        plotOptions: {
          bubble: {
            zScaling: true,
            stroke: {
              colors: [
                "#FF5733",
                "#33FF57",
                "#5733FF",
                "#FF3357",
                "#57FF33",
                "#3357FF",
              ],
            },
            marker: {
              states: {
                hover: {
                  lineWidth: 3,
                  lineColor: "red",
                },
              },
            },
            strokeColors: {
              enabled: true,
              colors: [
                "#FF5733",
                "#33FF57",
                "#5733FF",
                "#FF3357",
                "#57FF33",
                "#3357FF",
              ],
            },
          },
        },
        xaxis: {
          labels: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          tooltip: {
            enabled: false,
          },
          tickAmount: 10,
          tickPlacement: "between",
          min: 0,
          max: 10,
        },
        yaxis: {
          labels: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          tooltip: {
            enabled: false,
          },
          tickAmount: 7,
          min: 0,
          max: 7,
        },
        grid: {
          borderColor: "#33279A",
          strokeDashArray: 5,
          yaxis: {
            lines: {
              show: true,
            },
          },
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        colors: [
          "#AEFB4D",
          "#006E40",
          "#296BA9",
          "#7EF483",
          "#4FBFFF",
          "#0BA979",
        ],

        legend: {
          show: true, // Set this to false to hide the legend
        },
      },
      chartSeries: [
        {
          name: "Payroll Tax Summary",
          data: [{ x: 1, y: 5, z: 250, title: "Topic 1" }],
        },
        {
          name: "Employee Stock Purchase Plans",
          data: [{ x: 2, y: 3, z: 210, title: "Topic 2" }],
        },
        {
          name: "Banking Statement",
          data: [{ x: 9, y: 4, z: 330, title: "Topic 3" }],
        },
        {
          name: "Customer Service Traning",
          data: [{ x: 3, y: 4, z: 230, title: "Topic 4" }],
        },
        {
          name: "Internal Recruiting ",
          data: [{ x: 5, y: 4, z: 220, title: "Internal Recruiting " }],
        },
        {
          name: "Customer Service Traning",
          data: [{ x: 7, y: 2, z: 300, title: "Customer Service Traning" }],
        },
      ],
    };
  },
};
</script>
