<template>
  <div>
    <input
      type="file"
      ref="fileInput"
      style="display: none"
      accept=".xlsx, .xls, .pdf, .doc, .docx"
      @change="handleFileChange"
    />
    <!-- when user select the file, file will show -->

    <div v-if="selectedFile">
      <div class="file-details">
        <span class="file-name">{{ selectedFile.name }}</span>
        <i class="fa-solid fa-times cross-icon" @click="removeFile"></i>
        <!-- <button class="view-button" @click="viewDocument">View</button> -->
      </div>
    </div>

    <!-- when user not selected any file, icon will show to upload a file -->
    <div
      style="display: flex; justify-content: center; flex-direction: column"
      v-else
    >
      <button class="upload-button" @click="openFileInput">Upload File</button>
      <div class="file-format">(.xlsx, .xls, .pdf, .doc, .docx)</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DocUpload",
  data() {
    return {
      selectedFile: null,
    };
  },
  methods: {
    openFileInput() {
      this.$refs.fileInput.value = ""; // Clear previously selected file
      this.$refs.fileInput.click();
    },
    /** upload file from computer */
    handleFileChange(event) {
      const file = event.target.files[0];
      /** word, excel and pdf allowed */
      const allowedFileTypes = [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-excel",
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ];
      if (file && allowedFileTypes.includes(file.type)) {
        this.selectedFile = file;
      } else {
        this.selectedFile = null;
        alert("Please select a valid file (Excel, PDF, or Word).");
      }
    },
    /** remove file */
    removeFile() {
      this.selectedFile = null;
      // Reset the input element to allow selecting the same file again
      this.$refs.fileInput.value = "";
    },
    /** view doc in a new tab */
    // viewDocument() {
    //   const fileURL = URL.createObjectURL(this.selectedFile);
    //   window.open(fileURL, "_blank");
    // },
  },
};
</script>

<style scoped>
.upload-button {
  cursor: pointer;
  border: 1px solid #fff;
  background-color: #333;
  color: #fff;
  height: 40px;
  border-radius: 5px;
  padding: 0 10px;
  margin: 10px 0;
}

.file-format {
  font-size: 12px;
  color: #c3c3c3;
}

.file-details {
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
  color: #222;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 8px;
}
.file-name {
  margin-right: 8px;
}
.cross-icon {
  cursor: pointer;
  color: #222;
}
</style>
