<template>
  <div class="tab-container">
    <ul class="nav nav-tabs nav-underline">
      <!-- whitelist tab [start] -->
      <li class="nav-item user-tab">
        <a
          class="nav-link"
          :class="{ active: activeTab === 'whitelist' }"
          @click="setActiveTab('whitelist')"
          >Whitelist Users</a
        >
      </li>
      <!-- whitelist tab [end] -->

      <!-- blacklist tab [start] -->

      <li class="nav-item user-tab">
        <a
          class="nav-link"
          :class="{ active: activeTab === 'blacklist' }"
          @click="setActiveTab('blacklist')"
          >Blacklist Users</a
        >
      </li>
      <!-- blacklist tab [start] -->
    </ul>
    <div class="tab-content mt-3">
      <!-- whitelist tab content [start] -->
      <div
        class="tab-pane"
        :class="{ active: activeTab === 'whitelist' }"
        id="whitelist"
      >
        <UsersList
          :activeTab="activeTab === 'whitelist' ? 'whitelist' : 'blacklist'"
        />
      </div>

      <!-- whitelist tab content [end] -->

      <!-- blacklist tab content [start] -->

      <div
        class="tab-pane"
        :class="{ active: activeTab === 'blacklist' }"
        id="blacklist"
      >
        <UsersList
          :activeTab="activeTab === 'whitelist' ? 'whitelist' : 'blacklist'"
        />
      </div>
      <!-- blacklist tab content [end] -->
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import UsersList from "./UsersList.vue";

export default {
  name: "DashboardUserTabs",
  components: {
    UsersList,
  },
  setup() {
    // Define reactive properties using ref()
    const activeTab = ref("whitelist");

    /** set Active tab */
    const setActiveTab = (tab) => {
      activeTab.value = tab;
    };

    // Return the data and methods that need to be used in the template
    return {
      activeTab,
      setActiveTab,
    };
  },
};
</script>

<style scoped>
.tab-container {
  padding-top: 10px;
  background-color: #1c1c1c;
  height: 100vh;
}
.user-tab {
  cursor: pointer;
}
.user-tab .nav-link {
  font-size: 20px !important;
  font-weight: 700 !important;
  padding: 10px 20px;
  color: #555 !important;
  border: 1px solid #555;
}
.user-tab .nav-link:hover {
  border: 1px solid #555;
}
.nav-tabs .nav-link.active {
  background-color: #222 !important;
  color: white !important;
  border-color: #fff !important;
}
.nav-tabs {
  display: flex !important;
  justify-content: center !important;
  border-bottom: none !important;
}
/* for mobile screen */
@media only screen and (max-width: 768px) {
  .tab-container {
    padding-top: 100px;
  }
}
@media only screen and (max-width: 410px) {
  .tab-container {
    display: flex;
    flex-direction: column;
  }
}
</style>
