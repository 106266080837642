<template>
  <div class="w-full h-full">
    <Chart
      type="bar"
      :data="chartData"
      :options="chartOptions"
      class="w-full h-full"
    />
  </div>
</template>

<script setup>
import { defineProps, ref, onMounted, watch } from "vue";

import Chart from "primevue/chart";
const labels = ref([]);
const data = ref([]);

const props = defineProps({
  data: Array,
  selectedLlmType: String,
  selectedDate: Date,
  hostName: String,
  startHour: String,
  endHour: String,
  selectedCategory: String,
});

const chartData = ref();
const chartOptions = ref();

const setChartData = () => {
  return {
    labels: labels.value,
    datasets: [
      {
        label: "My Second dataset",
        backgroundColor: (context) => {
          const gradient = context.chart.ctx.createLinearGradient(0, 0, 0, 300);
          gradient.addColorStop(0, "rgba(152,156,74, 1)");
          gradient.addColorStop(1, "rgba(152,156,74, 0.2)");
          return gradient;
        },
        borderColor: "#E91E63", // Replaced with a hardcoded value for --pink-500
        data: data.value,
      },
    ],
  };
};

const setChartOptions = () => {
  const textColor = "#333"; // Updated for contrast against a lighter background

  return {
    indexAxis: "x",
    maintainAspectRatio: false,
    aspectRatio: 0.8,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        ticks: {
          color: textColor, // Updated for visibility on lighter background
          font: {
            weight: 500,
          },
        },
        grid: {
          display: true,
          color: "#CCCCCC", // Lightened for a white or light background
          drawBorder: true,
        },
      },
      y: {
        ticks: {
          color: textColor, // Updated for visibility on lighter background
        },
        grid: {
          color: "#CCCCCC", // Lightened for a white or light background
          drawBorder: true,
        },
      },
    },
    elements: {
      bar: {
        borderRadius: 0, // Adjust the value based on your preference
      },
    },
  };
};

const handleData = async () => {
  const hourlyLLMUsage = props.data;
  const datalabels = hourlyLLMUsage.map((item) => item._id);
  const datacounts = hourlyLLMUsage.map((item) => item.count);

  const response = {
    data: [
      {
        labels: datalabels,
        data: datacounts,
      },
    ],
  };

  labels.value = response.data[0].labels;
  data.value = response.data[0].data;
  chartData.value = setChartData();
  chartOptions.value = setChartOptions();
};

watch(
  () => [
    props.selectedLlmType,
    props.selectedCategory,
    props.hostName,
    props.selectedDate,
    props.startHour,
    props.endHour,
    props.data,

  ],
  handleData,
  { immediate: true }
);

onMounted(handleData);
</script>
