<template>
  <div class="accordion accordion-flush my-4" id="accordionFlushExample">
    <div class="accordion-item">
      <button
        class="accordion-button collapsed"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#flush-collapseOne"
        aria-expanded="false"
        aria-controls="flush-collapseOne"
      >
        <h5 class="accordion-header">Privacy Policy</h5>
      </button>
      <div
        id="flush-collapseOne"
        class="accordion-collapse collapse"
        data-bs-parent="#accordionFlushExample"
      >
        <div class="accordion-body">
          The Hygiene Co. Pty Ltd (ACN 642 794 410) (“THC”, “we”, “us”, “our”)
          trading as GREAT AUSSIE WIPES, value your privacy. This Privacy Policy
          describes how we and our company owned brands, subsidiaries and/or
          associates, collect, use and disclose the information about you when
          you. Personal information is information that identifies you, or from
          which your identity can be reasonably determined, and it can include
          (but is not limited to) your name, address, email, telephone number,
          date of birth, and gender.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CompanyPolicy",
};
</script>

<style>
.accordion {
  --bs-accordion-active-bg: transparent !important;
  --bs-accordion-btn-focus-box-shadow: none !important;
  border: 2px solid #fff !important;
}
.accordion-header {
  color: #fff !important;
}
.accordion-button {
  background-color: #333 !important;
}
.accordion-button.collapsed::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
}
</style>
