<template>
  <div class="chart-container w-full h-full">
    <Chart
      type="bar"
      :data="chartData"
      :options="chartOptions"
      class="w-full h-full"
    />
  </div>
</template>

<style>
.chart-container {
  background-color: white; /* Ensure the container has the background color */
}

/* Optionally, if the above doesn't work as expected, try targeting the canvas directly */
.chart-canvas canvas {
  background-color: white !important; /* Force the canvas background color */
}
</style>

<script setup>
import { defineProps, ref, onMounted, watch } from "vue";

import Chart from "primevue/chart";
const labels = ref([]);
const data = ref([]);

const props = defineProps({
  data: Array,
  selectedLlmType: String,
  selectedDate: Date,
  hostName: String,
  startHour: String,
  endHour: String,
  selectedCategory: String,
});

const chartData = ref();
const chartOptions = ref();

const setChartData = () => {
  const documentStyle = getComputedStyle(document.documentElement);
  return {
    labels: labels.value,
    datasets: [
      {
        label: "My Second dataset",
        backgroundColor: [
          "#619E59",
          "#619E59",
          "#619E59",
          "#619E59",
          "#619E59",
          "#619E59",
          "#619E59",
        ],
        borderColor: documentStyle.getPropertyValue("--pink-500"),
        data: data.value,
      },
    ],
  };
};
const setChartOptions = () => {
  // Define a darker color for text for visibility against a white background
  const textColorPrimary = "#333333"; // A dark gray color for text
  const gridColor = "#E0E0E0"; // A light gray color for grid lines

  return {
    indexAxis: "x",
    maintainAspectRatio: false,
    aspectRatio: 0.8,
    plugins: {
      legend: {
        display: false,
        labels: {
          color: textColorPrimary, // Use the darker text color for legend labels if displayed
        },
      },
    },
    scales: {
      x: {
        ticks: {
          color: textColorPrimary, // Use the darker text color for x-axis ticks
          font: {
            weight: 500,
          },
        },
        grid: {
          display: true,
          color: gridColor, // Use the light gray color for grid lines
          drawBorder: true,
        },
      },
      y: {
        ticks: {
          color: textColorPrimary, // Use the darker text color for y-axis ticks
        },
        grid: {
          color: gridColor, // Use the light gray color for grid lines
          drawBorder: true,
        },
      },
    },
    elements: {
      bar: {
        borderRadius: 0, // Adjust if you want rounded corners for bars
      },
    },
  };
};

const handleData = async () => {
  const result = props.data;

  const datalabels = result.map((item) => item._id);
  const datacounts = result.map((item) => item.count);
  const response = {
    data: [
      {
        labels: datalabels,
        data: datacounts,
      },
    ],
  };

  labels.value = response.data[0].labels;
  data.value = response.data[0].data;
  chartData.value = setChartData();
  chartOptions.value = setChartOptions();
};

watch(
  () => [
    props.selectedLlmType,
    props.selectedCategory,
    props.hostName,
    props.selectedDate,
    props.startHour,
    props.endHour,
    props.data,

  ],
  handleData,
  { immediate: true }
);

onMounted(handleData);
</script>
