<template>
  <div class="w-full h-full">
    <Chart
      type="line"
      :data="chartData"
      :options="chartOptions"
      class="w-full h-full"
    />
  </div>
</template>

<script setup>
import { defineProps, ref, onMounted, watch } from "vue";
import Chart from "primevue/chart";
const labels = ref([]);
const data = ref([]);
const chartData = ref();
const chartOptions = ref();

const props = defineProps({
  data: Array,
  selectedLlmType: String,
  selectedDate: Date,
  hostName: String,
  selectedCategory: String,
});

const setChartData = () => {
  return {
    labels: labels.value,
    datasets: [
      {
        label: "APAC",
        data: data.value,
        fill: true,
        tension: 0.4,
        borderColor: (context) => {
          const gradient = context.chart.ctx.createLinearGradient(0, 0, 0, 300);
          gradient.addColorStop(0, "rgba(133,62,71, 1)");
          gradient.addColorStop(1, "rgba(133,62,71, 0.1)");
          return gradient;
        },
        backgroundColor: (context) => {
          const gradient = context.chart.ctx.createLinearGradient(0, 0, 0, 300);
          gradient.addColorStop(0, "rgba(133,62,71, 1)");
          gradient.addColorStop(1, "rgba(133,62,71, 0.1)");
          return gradient;
        },
        borderWidth: 8,
      },
    ],
  };
};

const setChartOptions = () => {
  return {
    maintainAspectRatio: false,
    aspectRatio: 4,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        ticks: {
          color: "#333", // Changed to dark color for contrast against white background
        },
        grid: {
          color: "#e0e0e0", // Lighter grid color for white background
          border: {
            dash: [2, 4],
          },
        },
      },
      y: {
        ticks: {
          color: "#333", // Changed to dark color for contrast against white background
        },
        grid: {
          color: "#e0e0e0", // Lighter grid color for white background
          border: {
            dash: [2, 4],
          },
        },
      },
    },
  };
};

const handleData = async () => {
  const llmEventsOverTime = props.data;
  const datalabels = llmEventsOverTime.map((item) => item._id);
  const datacounts = llmEventsOverTime.map((item) => item.count);
  const response = {
    data: [
      {
        labels: datalabels,
        data: datacounts,
      },
    ],
  };

  labels.value = response.data[0].labels;
  data.value = response.data[0].data;
  chartData.value = setChartData();
  chartOptions.value = setChartOptions();
};

watch(
  () => [
    props.selectedLlmType,
    props.hostName,
    props.selectedCategory,
    props.selectedDate,
    props.startHour,
    props.endHour,
    props.data,
  ],
  handleData,
  { immediate: true }
);

onMounted(handleData);
</script>
