<template>
  <div class="main-container">
    <button class="back-button" @click="goBack">
      <i class="fa-solid fa-arrow-left fa-lg"></i>
    </button>
    <h4 class="text-center">Account Settings</h4>
    <div
      class="d-flex align-items-start justify-content-center"
      :class="{
        'flex-column': screenWidth <= 600,
        'flex-sm-row': screenWidth > 600,
        'align-items-center': screenWidth <= 600,
      }"
      :style="{
        'margin-top': screenWidth <= 600 ? '10%' : '5%',
      }"
    >
      <div class="nav flex-column nav-pills me-3" role="tablist">
        <button
          v-for="(tab, index) in filteredTabs"
          :key="index"
          :class="['nav-link', { active: index === activeTabIndex }]"
          @click="changeTab(index)"
          :id="`v-pills-tab-${index}`"
          data-bs-toggle="pill"
          :data-bs-target="`#v-pills-content-${index}`"
          type="button"
          role="tab"
          :aria-controls="`v-pills-content-${index}`"
          :aria-selected="index === activeTabIndex"
        >
          {{ tab.title }}
        </button>
      </div>
      <div
        class="tab-content"
        id="v-pills-tabContent"
        :style="{
          // background: 'rgba(255, 255, 255, 0.2)',
          // 'border-radius': '10px',
          // 'box-shadow': '0 4px 30px rgba(0, 0, 0, 0.1)',
          width: screenWidth > 600 ? '75%' : '90%',
          'margin-top': screenWidth > 600 ? '0%' : '5%',
          // 'margin-left': screenWidth > 600 ? '5%' : '0%',
          padding: '20px',
        }"
      >
        <div
          v-for="(tab, index) in tabs"
          :key="index"
          class="tab-pane fade"
          :class="{
            show: index === activeTabIndex,
            active: index === activeTabIndex,
          }"
          :id="`v-pills-content-${index}`"
          role="tabpanel"
          :aria-labelledby="`v-pills-tab-${index}`"
          tabindex="0"
        >
          <Security v-if="tab.content === 'Security'" />
          <!-- <Billing v-if="tab.content === 'Billing'" /> -->
          <ApiKey v-if="tab.content === 'ApiKey'" />
          <Plugins v-if="tab.content === 'Plugins'" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Security from "@/components/settings/Security.vue";
// import Billing from "@/components/settings/Billing.vue";
import Plugins from "@/components/settings/Plugins.vue";
import ApiKey from "@/components/settings/ApiKey.vue";
import { fetchCompanyDetail } from "@/apis/apis";
import { parseJSONFromLocalStorage } from "@/utils/utils";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Settings",
  components: {
    Security,
    // Billing,
    Plugins,
    ApiKey,
  },
  data() {
    return {
      activeTabIndex: 0,
      tabs: [
        { title: "Security", content: "Security" },
        // { title: "Billing", content: "Billing" },
        { title: "API Key", content: "ApiKey" },
        { title: "Plugins", content: "Plugins" },
      ],
      screenWidth: window.innerWidth,
      userData: parseJSONFromLocalStorage("loggedInUser"),
    };
  },
  methods: {
    /** go to the previous page */
    goBack() {
      this.$router.go(-1);
    },
    async getCompanyDetail() {
      try {
        const response = await fetchCompanyDetail(
          {
            companyId: this.userData.company,
          },
          this.userData.accessToken
        );

        // Assuming you have the necessary Vuex store set up with 'setApiKeyData' mutation
        this.$store.commit("setApiKeyData", response);
      } catch (error) {
        console.error("Error fetching API data:", error);
      }
    },
    changeTab(index) {
      if (this.userData?.role === "admin") {
        this.activeTabIndex = index;
        const selectedTab = this.filteredTabs[index];
        if (selectedTab.content === "ApiKey") {
          this.getCompanyDetail();
        }
      }
    },
    updateScreenWidth() {
      this.screenWidth = window.innerWidth;
    },
  },
  mounted() {
    window.addEventListener("resize", this.updateScreenWidth);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.updateScreenWidth);
  },
  computed: {
    filteredTabs() {
      if (this.userData?.role === "admin") {
        return this.tabs;
      } else {
        return this.tabs.filter(
          (tab) => tab.content !== "Plugins" && tab.content !== "ApiKey"
        );
      }
    },
    apiKeyData() {
      return this.$store.getters.getApiKeyData;
    },
  },
};
</script>

<style scoped>
.main-container {
  background-color: #1c1c1c;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  color: #fff;
  padding: 10px;
}
.back-button {
  position: absolute;
  left: 10px;
  top: 15px;
  cursor: pointer;
  border: 1px solid #fff;
  background-color: #333;
  color: #fff;
  width: 80px;
  height: 40px;
  border-radius: 5px;
}
.nav-pills .nav-link {
  color: #fff !important;
  width: 160px;
  font-size: 20px;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff !important;
  background-color: #333 !important;
}
</style>
