<template>
  <div class="w-full h-full">
    <Chart
      type="bar"
      :data="chartData"
      :options="chartOptions"
      class="w-full h-full"
    />
  </div>
</template>

<script setup>
import { defineProps, ref, onMounted, watch } from "vue";

import Chart from "primevue/chart";
import axios from "axios";
import { parseJSONFromLocalStorage } from "@/utils/utils";
const base_url = process.env.VUE_APP_BASE_URL;
const labels = ref([]);
const data = ref([]);
const onFetch = async () => {
  const userData = parseJSONFromLocalStorage("loggedInUser");
  const params = {};

  if (props.selectedLlmType) {
    params.llmType = props.selectedLlmType;
  }

  if (props.hostName) {
    params.hostName = props.hostName;
  }

  if (props.startHour && props.endHour) {
    params.startHour = props.startHour;
    params.endHour = props.endHour;
  }


  if (props?.selectedDate?.value) {
    const offset = props.selectedFromDate.value.getTimezoneOffset() * 60000; // Convert offset to milliseconds
    const adjustedDate = new Date(
      props.selectedToDate.value.getTime() - offset
    );
    params.date = adjustedDate.toISOString().split("T")[0];
  }
  if (props?.selectedToDate?.value) {
    const offset = props.selectedToDate.value.getTimezoneOffset() * 60000; // Convert offset to milliseconds
    const adjustedDate = new Date(
      props.selectedToDate.value.getTime() - offset
    );
    params.dateTo = adjustedDate.toISOString().split("T")[0];
  }
  if (props.selectedCategory) {
    params.category = props.selectedCategory;
  }
  const result = await axios.get(`${base_url}/companies/categories`, {
    params,
    headers: {
      Authorization: `Bearer ${userData.accessToken}`,
    },
  });
  const hourlyLLMUsage = result.data.data;
  const datalabels = hourlyLLMUsage.map((item) => item.category);
  const datacounts = hourlyLLMUsage.map((item) => item.count);

  console.log("<<<labels", datalabels);
  console.log("<<<counts", datacounts);
  const response = {
    data: [
      {
        labels: datalabels,
        data: datacounts,
      },
    ],
  };

  console.log("response", response);
  labels.value = response.data[0].labels;
  data.value = response.data[0].data;
  chartData.value = setChartData();
  chartOptions.value = setChartOptions();
};

const props = defineProps({
  selectedLlmType: String,
  hostName: String,
  startHour: String,
  endHour: String,
  selectedCategory: String,
  selectedDate: Date,
  selectedToDate: Date,

});

watch(
  () => [
    props.selectedLlmType,
    props.category,
    props.hostName,
    props.selectedDate,
    props.startHour,
    props.endHour,
    props.selectedCategory,
    props.selectedDate,
    props.selectedToDate,
  ],
  onFetch,
  { immediate: true }
);

const chartData = ref();
const chartOptions = ref();

const setChartData = () => {
  return {
    labels: labels.value,
    datasets: [
      {
        label: "My Second dataset",
        backgroundColor: (context) => {
          const gradient = context.chart.ctx.createLinearGradient(0, 0, 0, 300);
          gradient.addColorStop(0, "rgba(152,156,74, 1)");
          gradient.addColorStop(1, "rgba(152,156,74, 0.2)");
          return gradient;
        },
        borderColor: "#E91E63", // Replaced with a hardcoded value for --pink-500
        data: data.value,
      },
    ],
  };
};

const setChartOptions = () => {
  const textColor = "#333"; // Updated for contrast against a lighter background

  return {
    indexAxis: "x",
    maintainAspectRatio: false,
    aspectRatio: 0.8,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        ticks: {
          color: textColor, // Updated for visibility on lighter background
          font: {
            weight: 500,
          },
        },
        grid: {
          display: true,
          color: "#CCCCCC", // Lightened for a white or light background
          drawBorder: true,
        },
      },
      y: {
        ticks: {
          color: textColor, // Updated for visibility on lighter background
        },
        grid: {
          color: "#CCCCCC", // Lightened for a white or light background
          drawBorder: true,
        },
      },
    },
    elements: {
      bar: {
        borderRadius: 0, // Adjust the value based on your preference
      },
    },
  };
};

onMounted(() => {
  onFetch();

  console.log("cat miunt");
});
</script>
