<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.6154 4.125L22.4359 3M19.6154 9.75H23M19.6154 15.375L22.4359 16.5M9.46154 5.25L13.6553 3.72931C14.6334 3.37467 15.6667 4.0991 15.6667 5.13947V14.7357C15.6667 15.826 14.5404 16.552 13.5474 16.1019L9.46154 14.25V5.25ZM5.51282 5.8125V13.6875H3C1.89543 13.6875 1 12.7921 1 11.6875V7.8125C1 6.70793 1.89543 5.8125 3 5.8125H5.51282ZM2.69231 17.625H5.51282V19.5897C5.51282 20.3686 4.88143 21 4.10256 21V21C3.3237 21 2.69231 20.3686 2.69231 19.5897V17.625Z"
      stroke="#F8F7FF"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>
</template>
