<template>
  <div class="flex w-full items-center md:flex-row flex-col gap-6">
    <div class="flex justify-content-center">
      <Chart type="doughnut" :data="chartData" :options="chartOptions" />
    </div>
    <div class="w-full">
      <div
        v-for="data in mainData"
        :key="data.text"
        class="w-full flex items-center justify-between"
      >
        <div class="flex items-center gap-x-4">
          <div :class="[`bg-${data.color}`, 'h-4 w-4 rounded-sm  ']"></div>
          <span class="text-white text-[18px] font-light">{{ data.text }}</span>
        </div>
        <span class="text-white text-[18px] font-medium">{{ data.value }}</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";

import Chart from "primevue/chart";

const mainData = [
  {
    text: "HR",
    value: 789,
    color: "hr",
  },
  {
    text: "Legal",
    value: 789,
    color: "legal",
  },
  {
    text: "Finance",
    value: 1208,
    color: "finance",
  },
  {
    text: "Marketing",
    value: 2653,
    color: "marketing",
  },
  {
    text: "Sales",
    value: 904,
    color: "sales",
  },
  {
    text: "IT",
    value: 4596,
    color: "it",
  },
  {
    text: "Dev",
    value: 3294,
    color: "dev",
  },
];
onMounted(() => {
  chartData.value = setChartData();
  chartOptions.value = setChartOptions();
});

const chartData = ref();
const chartOptions = ref(null);

const setChartData = () => {
  const documentStyle = getComputedStyle(document.body);
  console.log(documentStyle, mainData);

  return {
    labels: ["HR", "Legal", "Finance", "Marketing", "Sales", "IT", "Dev"],
    datasets: [
      {
        data: [789, 789, 1208, 2653, 904, 4596, 3294],
        backgroundColor: [
          "#0BA979",
          "#006F40",
          "#7EF483",
          "#2F46BD",
          "#006F40",
          "#4FC0FF",
          "#AEFB4D",
          "#296BA9",
        ],
        hoverBackgroundColor: [
          "#0BA979",
          "#006F40",
          "#7EF483",
          "#2F46BD",
          "#006F40",
          "#4FC0FF",
          "#AEFB4D",
          "#296BA9",
        ],
      },
    ],
  };
};

const setChartOptions = () => {
  const documentStyle = getComputedStyle(document.documentElement);
  const textColor = "white";
  console.log("====================================");
  console.log(documentStyle);
  console.log("====================================");

  return {
    plugins: {
      legend: {
        display: false, // This will hide the legend labels
        labels: {
          cutout: "60%",
          color: textColor,
        },
      },
    },
  };
};
</script>
