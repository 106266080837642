import axios from "axios";

// BASE URL to connect with Backend
const base_url = process.env.VUE_APP_BASE_URL;

/** Register a User */
export async function registerUser(data) {
  try {
    const response = await axios.post(`${base_url}/users`, data);
    return response?.data;
  } catch (error) {
    return error;
  }
}
/** Account Verification */
export async function accountVerification(data) {
  try {
    const response = await axios.post(`${base_url}/users/verify-otp`, data);
    return response?.data;
  } catch (error) {
    return error;
  }
}
/** Resend Account Verification Code */
export async function resendAccountVerificationCode(data) {
  try {
    const response = await axios.post(`${base_url}/users/resend-otp`, data);
    return response?.data;
  } catch (error) {
    return error;
  }
}
/** Login */
export async function loginUser(data) {
  try {
    const response = await axios.post(`${base_url}/auth/login`, data);
    return response?.data;
  } catch (error) {
    return error;
  }
}
/** Login with Google */
export function loginWithGoogle(token) {
  try {
    const response = axios.put(`${base_url}/auth/google/callback`, token);
    return response;
  } catch (error) {
    return error;
  }
}
/** Verify Email for Forget Password */
export async function verifyEmail(data) {
  try {
    const response = await axios.post(`${base_url}/auth/verify-email`, data);
    return response?.data;
  } catch (error) {
    return error;
  }
}
/** Forget Password */
export async function forgetPassword(data) {
  try {
    const response = await axios.post(`${base_url}/auth/forget-password`, data);
    return response?.data;
  } catch (error) {
    return error;
  }
}
/** Change Email */
export async function changeUserEmail(data, token) {
  try {
    const response = await axios.put(`${base_url}/users/change-email`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response?.data;
  } catch (error) {
    return error;
  }
}
/** Change Password */
export async function changeUserPassword(data, token) {
  try {
    const response = await axios.put(`${base_url}/auth/change-password`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response?.data;
  } catch (error) {
    return error;
  }
}
