import NotFound from "@/views/NotFound.vue";
import Settings from "@/views/Settings.vue";
import LoginView from "@/views/LoginView.vue";
import GoogleView from "@/views/GoogleView.vue";
import ServerError from "@/views/ServerError.vue";
import CompanyList from "@/views/CompanyList.vue";
import RegisterView from "@/views/RegisterView.vue";
import NetworkError from "@/views/NetworkError.vue";
import ChatBox from "@/components/chat/ChatBox.vue";
import { parseJSONFromLocalStorage } from "@/utils/utils";
import CompanyDetail from "@/views/CompanyDetailView.vue";
import { createRouter, createWebHistory } from "vue-router";
import ResetPasswordView from "@/views/ResetPasswordView.vue";
import ForgetPasswordView from "@/views/ForgetPasswordView.vue";
import AccountVerification from "@/views/AccountVerification.vue";
import DashboardUserTabs from "@/components/dashboard/users/UserTabs.vue";
// import CompanyUsers from "@/components/dashboard/companyDetail/CompanyUsers.vue"
import ChatHistoryBox from "@/components/chat/chatHistoryMessages/ChatHistoryBox.vue";
import DashboardView from "@/views/DashboardView.vue";
import LogsView from "@/views/LogsView.vue";
import TenantsView from "@/views/TenantsView.vue";


const routes = [
  /** login route */
  {
    path: "/login",
    name: "LoginView",
    component: LoginView,
    meta: {
      requiresVisitor: true,
    },
  },
  {
    path: "/dashboard",
    name: "DashboardView",
    component: DashboardView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/tenants",
    name: "TenantsView",
    component: TenantsView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/logs",
    name: "LogsView",
    component: LogsView,
    meta: {
      requiresAuth: true,
    },
  },
  /** Google login route */
  {
    path: "/google",
    name: "GoogleView",
    component: GoogleView,
  },
  /** register router */
  {
    path: "/signup",
    name: "RegisterView",
    component: RegisterView,
  },
  /** account verification route */
  {
    path: "/account-verification",
    name: "AccountVerification",
    component: AccountVerification,
  },
  /** forget password route */
  {
    path: "/forget-password",
    name: "ForgetPasswordView",
    component: ForgetPasswordView,
  },
  /** reset password route */
  {
    path: "/reset-password",
    name: "ResetPasswordView",
    component: ResetPasswordView,
  },
  /** main / chatbot route */
  {
    path: "/",
    name: "ChatBox",
    component: ChatBox,
    meta: {
      requiresAuth: true,
    },
  },
  /** chat history */
  {
    path: "/chat/:id",
    name: "ChatHistoryBox",
    component: ChatHistoryBox,
    meta: {
      requiresAuth: true,
    },
  },
  /** Companies that will show to super-admin */
  {
    path: "/companies",
    name: "CompanyList",
    component: CompanyList,
    meta: {
      requiresAuth: true,
    },
  },
  /** Company that will show to company-admin */
  {
    path: "/company",
    name: "CompanyDetail",
    component: CompanyDetail,
    meta: {
      requiresAuth: true,
    },
  },
  /** Company Detail */
  // {
  //   path: '/company/1',
  //   name: 'CompanyDetail',
  //   component: CompanyDetail,
  // },
  // {
  //   path: '/company/1/users',
  //   name: 'CompanyUsers',
  //   component: CompanyUsers,
  //   meta: {
  //     requiresAuth: true
  //   }
  // },
  /** company users route */
  {
    path: "/company/users",
    name: "DashboardUserTabs",
    component: DashboardUserTabs,
    meta: {
      requiresAuth: true,
    },
  },
  /** settings route */
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
    meta: {
      requiresAuth: true,
    },
  },
  // {
  //   path: "/:catchAll(.*)",
  //   redirect: "/",
  // },
  /**  Not Found */
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: NotFound,
  },
  {
    path: '/setSession',
    component: {
      name: 'SetSession',
      template: '<div>Setting session...</div>',
      mounted() {
        const params = new URLSearchParams(window.location.search);
        const userData = params.get('data');
        if (userData) {
          localStorage.setItem("loggedInUser", userData);
          this.$router.push('/'); // Redirect to the homepage or dashboard
        }
      }
    }
  },
  /** Server Error */
  {
    path: "/server-error",
    name: "ServerError",
    component: ServerError,
  },
  /** Network Error */
  {
    path: "/network-error",
    name: "NetworkError",
    component: NetworkError,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

const isAuthenticated = () => {
  /** check if username is in local storage */
  const userData = parseJSONFromLocalStorage("loggedInUser");
  /**  Return true if the user token exists */
  return !!userData?.accessToken;
};

function getCurrentUserTenant() {
  const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
  if (loggedInUser && loggedInUser.domain) {
    const domainParts = loggedInUser.domain.split('.');
    return domainParts[0]; // Assuming the domain is structured as 'tenant.domain.com'
  }
  return null;
}

router.beforeEach((to, from, next) => {
  // Check if the route requires authentication
  if (to.meta.requiresAuth) {
    if (!isAuthenticated()) {
      // If the user is not authenticated, redirect to login
      next("/login");
    } else {
      // User is authenticated, check for tenant URL correctness
      const expectedTenantName = getCurrentUserTenant();
      
      if (expectedTenantName) {
        const baseUrl = process.env.NODE_ENV === 'development'
          ? `${expectedTenantName}.acumenai.co/`
          : `${expectedTenantName}.acumenai.co`;
        const currentHostName = window.location.hostname;
        const currentTenantName = currentHostName.split('.')[0];

        if (currentTenantName !== expectedTenantName) {
          // Mismatch in tenant, redirect to the correct tenant URL
          const protocol = window.location.protocol;
          const fullPath = to.fullPath.startsWith('/') ? to.fullPath : `/${to.fullPath}`;
          window.location.href = `${protocol}//${baseUrl}${fullPath}`;
        } else {
          next();
        }
      } else {
        // No tenant information, proceed or handle as necessary
        next();
      }
    }
  } else if (to.meta.requiresVisitor) {
    if (isAuthenticated()) {
      next({
        path: "/dashboard"
      });
    } else {
      next();
    }
  } else {
    next();
  }
});


export default router;
