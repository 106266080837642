<template>
  <div class="mt-4">
    <div v-for="(item, index) in accordionItems" :key="index">
      <div class="bot-container p-3 mb-2">
        <h5>{{ item.title }}</h5>
        <button class="btn btn-link" @click="toggleAccordion(index)">
          <i class="fa-regular fa-pen-to-square fa-lg"></i>
        </button>
      </div>
      <div class="accordion-content" :class="{ show: item.open }">
        <div class="p-3">
          <textarea
            v-model="item.content"
            class="mb-2 text-area"
            rows="4"
          ></textarea>
          <button
            v-if="item.content"
            class="submit-btn"
            @click="submitContent(index)"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CompanyPrompts",
  data() {
    return {
      accordionItems: [
        { title: "Query Review Bot", open: false },
        { title: "Query Reply Bot", open: false },
        { title: "Reply Review Bot", open: false },
      ],
    };
  },
  methods: {
    toggleAccordion(index) {
      this.accordionItems.forEach((item, i) => {
        if (i === index) {
          item.open = !item.open;
        } else {
          item.open = false;
        }
      });
    },
    submitContent(index) {
      console.log(
        `Accordion ${index + 1} content:`,
        this.accordionItems[index].content
      );
    },
  },
};
</script>

<style scoped>
.bot-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #333;
  border: 1px solid #fff;
  border-radius: 5px;
}
.accordion-content {
  display: none;
}
.accordion-content.show {
  display: block;
}
.btn-link {
  color: #fff !important;
}
.text-area {
  outline: none;
  width: 100%;
  border-radius: 5px;
  padding: 5px;
}
.submit-btn {
  border: 1px solid #fff;
  background-color: #222;
  color: #fff;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 10px;
}
</style>
