<template>
  <div class="main-container">
    <h1>Internal Server Error</h1>
    <h3 id="error">Something went wrong!</h3>
    <button class="back-button" @click="backButtonClick">Go Back</button>
    <!-- <p id="explanation">
      Something's wrong, maybe the rise of the machines has begun...<br />
      Or, more likely, a distracted developer left a bug in his code.
    </p> -->
    <div id="container">
      <div class="server" id="server1">
        <div class="hdd-container">
          <!-- <div class="hdd">
            <div class="led green-led"></div>
            <div class="led red-led"></div>
            <div class="led red-led"></div>
            <div class="led red-led"></div>
            <div class="led red-led"></div>
            <div class="led red-led"></div>
            <div class="led red-led"></div>
            <div class="led green-led"></div>
          </div> -->
          <div class="hdd">
            <div class="led"></div>
            <div class="led red-led"></div>
            <div class="led"></div>
            <div class="led"></div>
            <div class="led"></div>
            <div class="led"></div>
            <div class="led"></div>
            <div class="led"></div>
          </div>
          <div class="hdd">
            <div class="led"></div>
            <div class="led red-led"></div>
            <div class="led red-led"></div>
            <div class="led red-led"></div>
            <div class="led red-led"></div>
            <div class="led red-led"></div>
            <div class="led red-led"></div>
            <div class="led"></div>
          </div>
          <div class="hdd">
            <div class="led"></div>
            <div class="led"></div>
            <div class="led"></div>
            <div class="led"></div>
            <div class="led"></div>
            <div class="led"></div>
            <div class="led red-led"></div>
            <div class="led"></div>
          </div>
          <div class="hdd">
            <div class="led"></div>
            <div class="led red-led"></div>
            <div class="led red-led"></div>
            <div class="led red-led"></div>
            <div class="led red-led"></div>
            <div class="led red-led"></div>
            <div class="led red-led"></div>
            <div class="led"></div>
          </div>
        </div>
        <div class="cooling-system">
          <div class="grid"></div>
          <div class="grid"></div>
          <div class="grid"></div>
          <div class="grid"></div>
          <div class="grid"></div>
          <div class="grid"></div>
          <div class="grid"></div>
          <div class="grid"></div>
          <div class="grid"></div>
          <div class="grid"></div>
          <div class="grid"></div>
          <div class="grid"></div>
        </div>
      </div>
      <div class="server" id="server2">
        <div class="hdd-container">
          <!-- <div class="hdd">
            <div class="led"></div>
            <div class="led red-led"></div>
            <div class="led red-led"></div>
            <div class="led red-led"></div>
            <div class="led red-led"></div>
            <div class="led red-led"></div>
            <div class="led red-led"></div>
            <div class="led"></div>
          </div> -->
          <div class="hdd">
            <div class="led"></div>
            <div class="led red-led"></div>
            <div class="led"></div>
            <div class="led"></div>
            <div class="led"></div>
            <div class="led"></div>
            <div class="led red-led"></div>
            <div class="led"></div>
          </div>
          <div class="hdd">
            <div class="led"></div>
            <div class="led red-led"></div>
            <div class="led"></div>
            <div class="led"></div>
            <div class="led"></div>
            <div class="led"></div>
            <div class="led red-led"></div>
            <div class="led"></div>
          </div>
          <div class="hdd">
            <div class="led"></div>
            <div class="led red-led"></div>
            <div class="led"></div>
            <div class="led"></div>
            <div class="led"></div>
            <div class="led"></div>
            <div class="led red-led"></div>
            <div class="led"></div>
          </div>
          <div class="hdd">
            <div class="led"></div>
            <div class="led red-led"></div>
            <div class="led red-led"></div>
            <div class="led red-led"></div>
            <div class="led red-led"></div>
            <div class="led red-led"></div>
            <div class="led red-led"></div>
            <div class="led"></div>
          </div>
        </div>
        <div class="cooling-system">
          <div class="grid"></div>
          <div class="grid"></div>
          <div class="grid"></div>
          <div class="grid"></div>
          <div class="grid"></div>
          <div class="grid"></div>
          <div class="grid"></div>
          <div class="grid"></div>
          <div class="grid"></div>
          <div class="grid"></div>
          <div class="grid"></div>
          <div class="grid"></div>
        </div>
      </div>
      <div class="server" id="server3">
        <div class="hdd-container">
          <!-- <div class="hdd">
            <div class="led"></div>
            <div class="led red-led"></div>
            <div class="led red-led"></div>
            <div class="led red-led"></div>
            <div class="led red-led"></div>
            <div class="led red-led"></div>
            <div class="led red-led"></div>
            <div class="led"></div>
          </div> -->
          <div class="hdd">
            <div class="led"></div>
            <div class="led red-led"></div>
            <div class="led"></div>
            <div class="led"></div>
            <div class="led"></div>
            <div class="led"></div>
            <div class="led red-led"></div>
            <div class="led"></div>
          </div>
          <div class="hdd">
            <div class="led"></div>
            <div class="led red-led"></div>
            <div class="led"></div>
            <div class="led"></div>
            <div class="led"></div>
            <div class="led"></div>
            <div class="led red-led"></div>
            <div class="led"></div>
          </div>
          <div class="hdd">
            <div class="led"></div>
            <div class="led red-led"></div>
            <div class="led"></div>
            <div class="led"></div>
            <div class="led"></div>
            <div class="led"></div>
            <div class="led red-led"></div>
            <div class="led"></div>
          </div>
          <div class="hdd">
            <div class="led"></div>
            <div class="led red-led"></div>
            <div class="led red-led"></div>
            <div class="led red-led"></div>
            <div class="led red-led"></div>
            <div class="led red-led"></div>
            <div class="led red-led"></div>
            <div class="led"></div>
          </div>
        </div>
        <div class="cooling-system">
          <div class="grid"></div>
          <div class="grid"></div>
          <div class="grid"></div>
          <div class="grid"></div>
          <div class="grid"></div>
          <div class="grid"></div>
          <div class="grid"></div>
          <div class="grid"></div>
          <div class="grid"></div>
          <div class="grid"></div>
          <div class="grid"></div>
          <div class="grid"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";

export default {
  name: "ServerError",

  setup() {
    // Get the router instance using useRouter
    const router = useRouter();

    // go back
    const backButtonClick = () => {
      router.go(-1);
    };

    return {
      backButtonClick,
    };
  },
};
</script>

<style scoped>
.main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  background-color: #1c1c1c;
  color: #fff;
}

#container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.server {
  height: 30vw;
  width: 24vw;
  border-radius: 1vw;
  background-color: #302f30;
  padding: 3vw 2vw;
  margin: 1vw;
  box-shadow: 1vw 0.5vw 0.2vw #555;
}
.back-button {
  border: 1px solid #fff;
  color: #fff;
  background-color: #1c1c1c;
  border-radius: 5px;
  cursor: pointer;
  padding: 5px 10px;
  margin: 10px 0;
}
.hdd-container {
  padding: 0;
}
.hdd {
  height: 4vw;
  width: 100%;
  margin-bottom: 1vw;
  background-color: #141414;
  border-radius: 0.5vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.led {
  height: 0.8vw;
  width: 0.8vw;
  border-radius: 50%;
  background-color: rgba(0, 255, 0, 0.4);
  margin: 0.5vw;
  box-shadow: 0px 1px 10px rgba(0, 255, 0);
}

.red-led {
  background-color: rgba(255, 0, 0, 0.5);
  box-shadow: 0 1px 5px rgba(255, 0, 0);
  animation: blinking 1s linear infinite;
}

.cooling-system {
  padding-top: 0.5vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.grid {
  background-color: #141414;
  width: 8vw;
  height: 5vw;
  margin: 0.4vw;
  border-radius: 0.8vw;
}

h1 {
  font-family: "Lobster", cursive;
  font-weight: lighter;
}

#error {
  font-size: 1.2em;
}

h1,
p {
  margin: 10px;
}

@keyframes blinking {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
</style>
