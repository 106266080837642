<template>
  <div class="w-full h-full lg:p-20">
    <div
      class="flex items-center mb-8 w-full overflow-auto bg-transparent gap-4"
    >
      <div
        v-for="pin in pins"
        :key="pin.x"
        class="px-2 py-1 rounded-sm"
        :style="[{ 'background-color': pin.data.color }]"
      >
        <span class="text-md whitespace-nowrap font-medium block text-black">{{
          pin.data.name
        }}</span>
        <!-- <span class="text-white text-sm">{{ pin.data.value }}</span> -->
      </div>
    </div>
    <span class="h-full w-full relative">
      <img
        class="h-full w-full"
        :src="`data:image/svg+xml;utf8,${encodeURIComponent(svgMap)}`"
        alt=""
      />
    </span>
  </div>
</template>

<script setup>
import DottedMap from "dotted-map";
// import axios from "axios";
import { onMounted, ref } from "vue";
const allCountries = ref([]);
const pins = ref([]);
var svgMap = ref(null);
const map = new DottedMap({ height: 60, grid: "vertical" });
onMounted(async () => {
  try {
    // const response = await axios.get("http://localhost:3000/countries");
    const response = { "data": [
    {
      "name": "United States",
      "data": 100,
      "lat": 37.0902,
      "long": -95.7129,
      "color": "#FF0000"
    },
    {
      "name": "Canada",
      "data": 100,
      "lat": 56.1304,
      "long": -106.3468,
      "color": "orange"
    },
    {
      "name": "United Kingdom",
      "data": 100,
      "lat": 55.3781,
      "long": -3.436,
      "color": "blue"
    },
    {
      "name": "Australia",
      "data": 100,
      "lat": -25.2744,
      "long": 133.7751,
      "color": "yellow"
    },
    {
      "name": "New Zealand",
      "data": 100,
      "lat": -40.9006,
      "long": 174.886,
      "color": "black"
    },
    {
      "name": "Ireland",
      "data": 100,
      "lat": 53.1424,
      "long": -7.6921,
      "color": "lime"
    },
    {
      "name": "Germany",
      "data": 100,
      "lat": 51.1657,
      "long": 10.4515,
      "color": "red"
    },
    {
      "name": "France",
      "data": 100,
      "lat": 46.2276,
      "long": 2.2137,
      "color": "sky"
    },
    {
      "name": "Spain",
      "data": 100,
      "lat": 40.4637,
      "long": -3.7492,
      "color": "red"
    },
    {
      "name": "Italy",
      "data": 100,
      "lat": 41.8719,
      "long": 12.5674,
      "color": "green"
    },
    {
      "name": "Netherlands",
      "data": 100,
      "lat": 52.1326,
      "long": 5.2913,
      "color": "orange"
    },
    {
      "name": "Sweden",
      "data": 100,
      "lat": 60.1282,
      "long": 18.6435,
      "color": "yellow"
    }
  ]
    }
    console.log("====================================");
    console.log(response.data);
    allCountries.value = response.data;
    console.log("====================================");
    console.log(allCountries.value);

    allCountries.value.map((country) => {
      map.addPin({
        lat: country.lat,
        lng: country.long,
        data: { name: country.name, value: 300, color: country.color },
        svgOptions: { color: country.color, radius: 3 },
      });
      const points = map.getPoints();
      pins.value = points.filter((point) => point.data);
      svgMap.value = map.getSVG({
        radius: 0.35,
        color: "#D1D5DA",
        shape: "circle",
        backgroundColor: "transparent",
      });
    });
  } catch (error) {
    console.error(error);
  }
});

// map.addPin({
//   lat: 31.9522,
//   lng: 35.2332,
//   data: { name: "Paletine", value: 300, color: "green" },
//   svgOptions: { color: "green", radius: 3 },
//   bg: "orange",
// });
// map.addPin({
//   lat: 36.2048,
//   lng: 138.2529,
//   data: { name: "Japan", value: 250, color: "blue" },
//   svgOptions: { color: "blue", radius: 3 },
// });

// map.addPin({
//   lat: 37.0902,
//   lng: -95.7129,
//   data: { name: "USA", value: 200, color: "red" },
//   svgOptions: { color: "red", radius: 3 },
// });

// map.addPin({
//   lat: 55.3781,
//   lng: -3.436,
//   data: { name: "UK", value: 150, color: "yellow" },
//   svgOptions: { color: "yellow", radius: 3 },
// });

// map.addPin({
//   lat: 46.2276,
//   lng: 2.2137,
//   data: { name: "France", value: 100, color: "purple" },
//   svgOptions: { color: "purple", radius: 3 },
// });

// console.log(pins);
</script>

<style scoped></style>
