import { createStore } from "vuex";

const store = createStore({
  state: {
    userData: null,
    selectedChatId: null,
    apiKeyData: null, // company detail
    isNewSessionCreated: false, // chat history re-render condition
    chatMessages: [], // chat messages
  },
  // to update the store state
  mutations: {
    // logged-in user data
    setUserData(state, user) {
      state.userData = user;
    },
    // selected chat from history
    setSelectedChatId(state, payload) {
      state.selectedChatId = payload;
    },
    // company detail
    setApiKeyData(state, data) {
      state.apiKeyData = data;
    },
    // when new session created, chat history re-render
    setIsNewSessionCreated(state, value) {
      state.isNewSessionCreated = value;
    },

    // add chat
    addChatMessage(state, message) {
      state.chatMessages.push(message);
    },
    // clear chat
    clearChatMessages(state) {
      state.chatMessages = [];
    },
  },
  actions: {
    // selected chat from history
    setSelectedChatId(context, payload) {
      context.commit("setSelectedChatId", payload);
    },
    // when new session created, chat history re-render
    setIsNewSessionCreated(context, payload) {
      context.commit("setIsNewSessionCreated", payload);
    },
    // add chat messages
    addChatMessage(context, message) {
      context.commit("addChatMessage", message);
    },
    // clear chat messages to create a new interval
    clearChatMessages(context) {
      context.commit("clearChatMessages");
    },
  },
  getters: {
    // get company detail
    getApiKeyData(state) {
      return state.apiKeyData;
    },
    // get chat messages
    getChatMessages(state) {
      return state.chatMessages;
    },
  },
});

export default store;
