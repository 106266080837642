<template>
  <div
    class="px-4 bg-transparent p-4 sm:px-6 lg:px-8 max-w-[1500px] mx-auto my-20"
  >
    <div class="sm:flex sm:products-center">
      <div class="sm:flex-auto">
        <h1 class="text-2xl font-semibold leading-6 text-white">Logs</h1>
      </div>
      <div class="sm:ml-16 sm:mt-0 sm:flex-none">
        <input
          v-model="search"
          class="px-3 py-1.5 ring-1 ring-gray-300 rounded-md"
          placeholder="Search Records"
          type="text"
          name=""
          id=""
        />
      </div>
    </div>
    <div class="mt-8 flow-root">
      <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <table class="min-w-full divide-gray-700 border-collapse">
            <thead class="bg-darkBlue">
              <tr>
                <th
                  scope="col"
                  class="py-3.5 pl-4 pr-3 text-left text-xs font-medium whitespace-nowrap text-gray-200 sm:pl-2"
                >
                  Date
                </th>
                <th
                  scope="col"
                  class="py-3.5 pl-4 pr-3 text-left text-xs font-medium whitespace-nowrap text-gray-200 sm:pl-2"
                >
                  Time
                </th>
                <th
                  scope="col"
                  class="px-3 py-3.5 text-left text-xs font-medium whitespace-nowrap text-gray-200"
                >
                  Type
                </th>
                <th
                  scope="col"
                  class="px-3 py-3.5 text-left text-xs font-medium whitespace-nowrap text-gray-200"
                >
                  Sub Type
                </th>
                <th
                  scope="col"
                  class="px-3 py-3.5 text-left text-xs font-medium whitespace-nowrap text-gray-200"
                >
                  User Id
                </th>
                <th
                  scope="col"
                  class="px-3 py-3.5 text-left text-xs font-medium whitespace-nowrap text-gray-200"
                >
                  Classification Record Id
                </th>
                <th
                  scope="col"
                  class="px-3 py-3.5 text-left text-xs font-medium whitespace-nowrap text-gray-200"
                >
                  Classification Record
                </th>
                <th
                  scope="col"
                  class="px-3 py-3.5 text-left text-xs font-medium whitespace-nowrap text-gray-200"
                >
                  IP
                </th>
                <th
                  scope="col"
                  class="px-3 py-3.5 text-left text-xs font-medium whitespace-nowrap text-gray-200"
                >
                  Country
                </th>
                <th
                  scope="col"
                  class="px-3 py-3.5 text-left text-xs font-medium whitespace-nowrap text-gray-200"
                >
                  Cloud Service
                </th>
                <th
                  scope="col"
                  class="px-3 py-3.5 text-left text-xs font-medium whitespace-nowrap text-gray-200"
                >
                  LLM Type
                </th>
                <th
                  scope="col"
                  class="px-3 py-3.5 text-left text-xs font-medium whitespace-nowrap text-gray-200"
                >
                  LLM Type Service
                </th>
                <th
                  scope="col"
                  class="px-3 py-3.5 text-left text-xs font-medium whitespace-nowrap text-gray-200"
                >
                  LLM Sub Type Service
                </th>
                <th
                  scope="col"
                  class="px-3 py-3.5 text-left text-xs font-medium whitespace-nowrap text-gray-200"
                >
                  Event Type
                </th>
                  <th
                  scope="col"
                  class="px-3 py-3.5 text-left text-xs font-medium whitespace-nowrap text-gray-200"
                >
                  Host Name
                </th>
              </tr>
            </thead>
            <tbody class="bg-primaryBlue">
              <template v-for="(server, index) in paginatedItems" :key="index">
                <tr
                  @click="setRow(server, index)"
                  :class="[
                    index === selectedIndex ? 'bg-darkBlue' : '',
                    'cursor-pointer',
                  ]"
                >
                  <td
                    class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-200 sm:pl-2"
                  >
  {{ new Date(server?.timestamp).toLocaleString('en-US', { year: 'numeric', month: 'numeric', day: 'numeric' }) }}
                  </td>
                  <td
                    class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-200 sm:pl-2"
                  >
  {{ new Date(server?.timestamp).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true }) }}
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-200">
                    {{ server?.type }}
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-200">
                    {{ server?.subtype }}
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-200">
                    {{ server?.userId }}
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-200">
                    {{ server?.classificationRecordId }}
                  </td>
                  <td class="px-3 py-4 text-sm max-w-xs flex text-gray-200">
                    {{ server?.classificationRecord }}
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-200">
                    {{ server?.ip }}
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-200">
                    {{ server?.country }}
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-200">
                    {{ server?.cloudService }}
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-200">
                    {{ server?.llmType }}
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-200">
                    {{ server?.llmServiceType }}
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-200">
                    {{ server?.llmSubserviceType }}
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-200">
                    {{ server?.eventType }}
                  </td>
                     <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-200">
                    {{ server?.host_name }}
                  </td>
                </tr>

                <tr
                  v-if="currentRow !== null && index === selectedIndex"
                  class="cursor-pointer bg-darkBlue px-2"
                >
                  <td
                    colspan="2"
                    class="whitespace-nowrap px-2 py-4 font-light pl-2 pr-3 text-sm text-gray-200"
                  >
                    Justification
                  </td>
                  <td
                    colspan="10"
                    class="whitespace-nowrap font-light px-3 py-4 text-sm text-gray-200"
                  >
                    {{ server.jus }}
                  </td>
                </tr>
                <tr
                  v-if="currentRow !== null && index === selectedIndex"
                  class="cursor-pointer bg-darkBlue px-2"
                >
                  <td
                    colspan="2"
                    class="whitespace-nowrap px-2 py-4 font-light pl-2 pr-3 text-sm text-gray-200"
                  >
                    social attack
                  </td>
                  <td
                    colspan="10"
                    class="whitespace-nowrap font-light px-3 py-4 text-sm text-gray-200"
                  >
                    {{ server?.social_attack }}
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <!-- Pagination -->
    <nav
      class="flex items-center justify-between border-t border-gray-700 bg-darkBlue px-4 py-3 sm:px-6"
      aria-label="Pagination"
    >
      <div class="hidden sm:block">
        <p class="text-sm text-gray-300">
          Page
          <span class="font-medium">{{ currentPage }}</span>
          -
          <span class="font-medium">{{ totalPages }}</span>
          /
          <span class=" ">
            Total
            <span class="">{{ filteredProducts.length }}</span>
            results</span
          >
        </p>
      </div>
      <div class="flex flex-1 justify-between sm:justify-end">
        <button
          href="#"
          @click="goToPage(currentPage - 1)"
          :disabled="currentPage === 1"
          class="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
        >
          Previous
        </button>
        <button
          href="#"
          @click="goToPage(currentPage + 1)"
          :disabled="currentPage === totalPages"
          class="relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
        >
          Next
        </button>
      </div>
    </nav>
  </div>
</template>

<script setup>
import { defineProps, ref, onMounted, watch, computed } from "vue";
import axios from "axios";
import { parseJSONFromLocalStorage } from "@/utils/utils";
const selected = ref({});
const products = ref([]);

const props = defineProps({
  selectedLlmType: String,
  selectedDate: Date,
  hostName: String,
  startHour: String,
  endHour: String,
  selectedCategory: String,
});

const onFetch = async () => {
  const base_url = process.env.VUE_APP_BASE_URL;
  const userData = parseJSONFromLocalStorage("loggedInUser");
  const params = {};

  if (props.selectedLlmType) {
    params.llmType = props.selectedLlmType;
  }

  if (props.startHour && props.endHour) {
    params.startHour = props.startHour;
    params.endHour = props.endHour;
  }

  if (props.hostName) {
    params.hostName = props.hostName;
  }

  if (props.selectedCategory) {
    params.category = props.selectedCategory;
  }

  if (props.selectedDate) {
    params.date = props.selectedDate.toISOString().split("T")[0];
  }
  const response = await axios.get(`${base_url}/companies/dashboard/logs`, {
    params,
    headers: {
      Authorization: `Bearer ${userData.accessToken}`,
    },
  });
  console.log("<<<<response", response);
  console.log("====================================");
  products.value = response.data.data.logs;
  console.log("====================================");
  console.log(response.data);
  console.log("====================================");
  console.log("====================================");
};

watch(
  () => [props.selectedLlmType, props.hostName, props.selectedDate,props.selectedCategory, props.startHour, props.endHour],
  onFetch,
  { immediate: true }
);

const selectedIndex = ref(null);

const currentRow = ref(false);
const setRow = (server, index) => {
  if (selectedIndex.value === index) {
    selectedIndex.value = null; // If the user clicks the same row again, we'll toggle the detailed view off
    selected.value = {};
  } else {
    selectedIndex.value = index;
    selected.value = server;
  }
};

const search = ref("");
const filteredProducts = computed(() => {
  const searchLowerCase = search.value.toLowerCase();
  return products.value.filter((server) =>
    Object.values(server).some(
      (value) =>
        value && value.toString().toLowerCase().includes(searchLowerCase)
    )
  );
});

// Reactive variables
const currentPage = ref(1);

const totalPages = computed(() =>
  Math.ceil(filteredProducts.value.length / 10)
);

const paginatedItems = computed(() => {
  const startIndex = (currentPage.value - 1) * 10;
  const endIndex = startIndex + 10;
  return filteredProducts.value.slice(startIndex, endIndex);
});

// Method to navigate to a specific page
const goToPage = (page) => {
  if (page >= 1 && page <= totalPages.value) {
    currentPage.value = page;
  }
};
onMounted(onFetch);
</script>
