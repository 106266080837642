<template>
  <div class="modal-overlay">
    <div :style="modalStyle" class="modal-container">
      <div class="modal-header">
        <h1 class="modal-title fs-3">{{ title }}</h1>
        <button class="close-button" @click="closeModal" aria-label="Close">
          &times;
        </button>
      </div>
      <div class="modal-content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomModal",
  props: {
    title: {
      type: String,
      required: true,
    },
    marginLeft: {
      type: String, // You can change the type to fit your use case (e.g., Number, Boolean)
      default: "250px", // Default margin-left value if not provided by parent component
    },
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
  },
  computed: {
    modalStyle() {
      return {
        marginLeft: this.marginLeft,
      };
    },
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.modal-container {
  background-color: #1c1c1c;
  width: 60%;
  padding: 20px;
  border-radius: 4px;
  margin-left: 250px;
}

.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modal-title {
  margin: 0;
}

.close-button {
  border: none;
  background-color: transparent;
  font-size: 20px;
  cursor: pointer;
  float: right;
  color: #fff;
}

.modal-content {
  margin-top: 20px;
}
/* for mobile screen */
@media only screen and (max-width: 768px) {
  .modal-container {
    margin-left: 0px;
  }
}
</style>
