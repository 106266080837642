<template>
  <div class="main-container">
    <div class="spinner-border spinner-border-sm" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</template>

<script setup>
import { onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import { loginWithGoogle } from "@/apis/auth";

const toast = useToast();
const store = useStore();
const router = useRouter();

// handle sign-in with Google
const handleSignInWithGoogle = async () => {
  // Get the URL from the browser
  const url = window.location.href;
  // Parse the URL to get query parameters
  const urlParams = new URLSearchParams(new URL(url).hash.slice(1)); // Removing '#' from the hash
  // Get the access_token parameter
  const accessToken = urlParams.get("access_token");
  // Now you can use the access token as needed
  if (accessToken) {
    let apiResponse;
    try {
      apiResponse = await loginWithGoogle({ access_token: accessToken });

      /**  Store user data in local storage*/
      localStorage.setItem(
        "loggedInUser",
        JSON.stringify(apiResponse?.data?.data)
      );
      /**  Store user data in store*/
      store.commit("setUserData", apiResponse?.data?.data);

      /** when user logged-in, success toast show */
      toast.success(`LoggedIn Successfully`, {
        position: "bottom-right",
      });
      /**  when user successfully logged-in, navigate to main screen */
       const tenantName = apiResponse?.data?.data.domain.split('.')[0];
    
      // Determine the base URL based on the environment
      let baseUrl = process.env.NODE_ENV === 'development' 
        ? `https://${tenantName}.acumenai.co/setSession?data=${encodeURIComponent(JSON.stringify(apiResponse?.data?.data))}` 
        : `https://${tenantName}.acumenai.co/setSession?data=${encodeURIComponent(JSON.stringify(apiResponse?.data?.data))}`;

      // let  baseUrl = "http://localhost:8081/dashboard"

      // Redirect to the dynamically constructed tenant-specific URL
      window.location.href = baseUrl;
    } catch (error) {
      /** user already exist */
      if (error?.response?.data?.statusCode === "UserExists") {
        toast.error("User already exists, Please login with email & password", {
          position: "bottom-right",
        });
        router.push("/login");
      }
      /** network error */
      if (error?.code === "ERR_NETWORK") {
        toast.error(`Network Error`, {
          position: "bottom-right",
        });
        router.push("/server-error");
      }
      /** internal server error */
      if (error?.response?.status === 500) {
        toast.error(`Internal Server Error`, {
          position: "bottom-right",
        });
        router.push("/server-error");
      }
    }
  }
};

onMounted(() => {
  handleSignInWithGoogle();
});
</script>

<style>
.main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  background-color: #1c1c1c;
  color: #fff;
}
</style>
