<template>
  <div class="w-full h-full">
    <Chart
      type="bar"
      :data="chartData"
      :options="chartOptions"
      class="w-full h-full"
    />
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";

import Chart from "primevue/chart";

onMounted(() => {
  chartData.value = setChartData();
  chartOptions.value = setChartOptions();
});

const chartData = ref();
const chartOptions = ref();

const setChartData = () => {
  const documentStyle = getComputedStyle(document.documentElement);

  return {
    labels: [
      "Budgeting",
      "Financial reporting",
      "Bank Operations/Transfers",
      "Collection",
      "Accounts Receivable",
      "June",
      "Accounts Payable",
    ],
    datasets: [
      {
        label: "My Second dataset",
        backgroundColor: [
          "#4FBFFF",
          "#AEFB4D",
          "#0BA979",
          "#7EF483",
          "#296BA9",
          "#2F46BD",
        ],
        borderColor: documentStyle.getPropertyValue("--pink-500"),
        data: [40, 19, 86, 100, 200, 400, 600],
      },
    ],
  };
};
const setChartOptions = () => {
  const documentStyle = getComputedStyle(document.documentElement);
  console.log("====================================");
  console.log(documentStyle);
  console.log("====================================");

  const textColorSecondary = "#F8F6FF";
  const surfaceBorder = "#33279A";

  return {
    indexAxis: "y",

    maintainAspectRatio: false,
    aspectRatio: 0.8,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        ticks: {
          color: textColorSecondary,
          font: {
            weight: 500,
          },
        },
        grid: {
          display: true,
          color: surfaceBorder,
          drawBorder: true,
        },
      },
      y: {
        ticks: {
          color: textColorSecondary,
        },
        grid: {
          color: surfaceBorder,
          drawBorder: true,
        },
      },
    },
    elements: {
      bar: {
        borderRadius: 10, // Adjust the value based on your preference
      },
    },
  };
};
</script>
