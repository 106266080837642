// Unauthorized: Redirect the user to the login screen
export async function handleUnauthorized(error) {
  if (error?.response?.status === 401) {
    localStorage.removeItem("loggedInUser");

    window.location.href = "/login";
    alert("Session Expired! Please Login Again");
  }
  throw error;
}
// Network Error: if backend not connected or response timeout issue
export async function handleNetwork(error) {
  if (error?.code === "ERR_NETWORK" || error?.code === "ERR_BAD_RESPONSE") {
    throw error;
  }
}
