<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.40002 2.4V21.6H21.6M7.20002 14.4001L11.4 10.2001L14.4 13.2001L19.8001 7.8"
      stroke="#F8F7FF"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
