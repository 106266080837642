<template>
  <div class="plugins-container">
    <div class="plugins">
      <div class="plugin-image"></div>
      <div class="name-container">
        <div class="name">NAME</div>
        <button class="install-btn">Install</button>
      </div>
    </div>
    <div class="description">
      description description description descri description description descri
      description description description
    </div>
  </div>
</template>
<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "PluginsCard",
};
</script>
<style scoped>
.plugins-container {
  display: flex;
  flex-direction: column;
  padding: 15px 10px;
  margin: 10px 0;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid #fff;
  height: 250px;
}
.plugins {
  display: flex;
}
.plugin-image {
  width: 100px;
  height: 100px;
  background-color: #555;
  border-radius: 10px;
}
.name-container {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}
.plugins .name {
  font-size: 16px;
  font-weight: 500;
}
.install-btn {
  border: 1px solid #fff;
  background-color: #333;
  color: #fff;
  border-radius: 5px;
  padding: 5px 20px;
  margin: 10px 0;
}
.install-btn:hover {
  background-color: #555;
}
.description {
  padding: 10px 0;
  font-size: 14px;
}
</style>
