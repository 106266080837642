<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.8 17.7L13.2 6.3M6.00002 15.9L2.40002 12.3L6.00002 8.7M18 8.7L21.6 12.3L18 15.9"
      stroke="#F8F7FF"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
