<template>
  <div class="main-container">
    <div class="inner-container">
      <h3 class="heading my-3">REGISTER</h3>
      <form @submit.prevent="handleSignUp" class="add-form">
        <!-- name input -->
        <InputField
          label="Name"
          v-model:modelValue="name"
          type="text"
          name="name"
          placeholder="Name"
        />
        <!-- email input -->
        <InputField
          label="Email"
          v-model:modelValue="email"
          type="text"
          name="email"
          placeholder="Your Email"
        />
        <!-- password input -->
        <div class="d-flex position-relative">
          <InputField
            label="Password"
            v-model:modelValue="password"
            :type="showPassword ? 'text' : 'password'"
            name="password"
            placeholder="Enter Password"
            @input="validatePassword"
          />
          <div class="password-icon" @click="togglePasswordVisibility">
            <i v-if="showPassword" class="fa-solid fa-eye"></i>
            <i v-else class="fa-solid fa-eye-slash"></i>
          </div>
        </div>
        <!-- password error messages -->
        <p class="error-message" v-if="passwordErrorMessage">
          {{ passwordErrorMessage }}
        </p>
        <!-- confirm password input -->
        <div class="d-flex position-relative">
          <InputField
            label="Confirm Password"
            v-model:modelValue="confirmPassword"
            :type="showConfirmPassword ? 'text' : 'password'"
            name="confirmPassword"
            placeholder="Confirm Password"
            @input="validateConfirmPassword"
          />
          <div class="password-icon" @click="toggleConfirmPasswordVisibility">
            <i v-if="showConfirmPassword" class="fa-solid fa-eye"></i>
            <i v-else class="fa-solid fa-eye-slash"></i>
          </div>
        </div>
        <!-- confirm password error messages -->
        <p class="error-message" v-if="confirmPasswordErrorMessage">
          {{ confirmPasswordErrorMessage }}
        </p>

        <!-- if user have already an account, then user can go to login -->
        <p class="account">
          Already have an account? &nbsp;<router-link to="/login"
            >Login</router-link
          >
        </p>
        <!-- Loader and Register button -->
        <div class="btn-container">
          <button class="register-btn" type="submit" :disabled="isDisabled">
            <span v-if="isLoading">
              <div class="spinner-border spinner-border-sm" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </span>
            <span v-else>Register</span>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { ref, computed } from "vue";
import { useRouter } from "vue-router";
import { registerUser } from "@/apis/auth.js";
import { useToast } from "vue-toastification";
import InputField from "@/components/common/InputField.vue";

export default {
  name: "RegisterView",
  components: {
    InputField,
  },
  setup() {
    // Get the toast instance using useToast
    const toast = useToast();
    // Get the router instance using useRouter
    const router = useRouter();

    // Define reactive properties using ref()
    const name = ref("");
    const email = ref("");
    const password = ref("");
    const confirmPassword = ref("");
    const isLoading = ref(false);
    const showPassword = ref(false);
    const showConfirmPassword = ref(false);
    const passwordErrorMessage = ref("");
    const confirmPasswordErrorMessage = ref("");

    // password validation
    const validatePassword = () => {
      const passwordRegex =
        /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
      const requirements = [
        {
          regex: /.*[A-Z].*/,
          message: "1 uppercase letter",
        },
        {
          regex: /.*[a-z].*/,
          message: "1 lowercase letter",
        },
        {
          regex: /.*\d.*/,
          message: "1 digit",
        },
        {
          regex: /.*[!@#$%^&*].*/,
          message: "1 special character",
        },
      ];

      passwordErrorMessage.value = "";

      if (password.value.length > 0) {
        if (password.value.length < 8) {
          passwordErrorMessage.value =
            "Password must have at least 8 characters";
          return;
        }

        requirements.forEach((requirement) => {
          if (!requirement.regex.test(password.value)) {
            passwordErrorMessage.value += `${requirement.message}, `;
          }
        });

        if (passwordRegex.test(password.value)) {
          passwordErrorMessage.value = "";
        } else {
          passwordErrorMessage.value =
            "Password must contain " +
            passwordErrorMessage.value.slice(0, -2) +
            ".";
        }
      } else {
        // If the password field is empty, clear the error message
        passwordErrorMessage.value = "";
      }
    };

    // confirm password validation
    const validateConfirmPassword = () => {
      confirmPasswordErrorMessage.value = "";

      if (confirmPassword.value.length > 0) {
        if (confirmPassword.value !== password.value) {
          confirmPasswordErrorMessage.value = "Passwords do not match";
        }
      } else {
        // If the confirm password field is empty, clear the error message
        confirmPasswordErrorMessage.value = "";
      }
    };

    // show/hide password
    const togglePasswordVisibility = () => {
      showPassword.value = !showPassword.value;
    };
    // show/hide confirm password
    const toggleConfirmPasswordVisibility = () => {
      showConfirmPassword.value = !showConfirmPassword.value;
    };

    // Create a computed property to check if passwords match and validate conditions
    const isDisabled = computed(() => {
      const passwordsMatch = confirmPassword.value === password.value;

      // Check if any of the conditions fail and return true to disable the button
      if (
        !passwordsMatch ||
        passwordErrorMessage.value ||
        confirmPasswordErrorMessage.value
      ) {
        return true;
      }

      return isLoading.value;
    });

    // handle sign-up
    const handleSignUp = async (e) => {
      e.preventDefault();
      let apiResponse;

      /** Set isLoading to true before making the sign-up request */
      isLoading.value = true;

      const userData = {
        name: name.value,
        email: email.value,
        password: password.value,
      };

      apiResponse = await registerUser(userData);

      /** Handle successful sign-up with API */
      if (apiResponse?.status) {
        /** if user signUp with API, success message will show */
        toast.success(`Successfully Registered!`, {
          position: "bottom-right",
        });
        toast.info(
          `A confirmation code has been sent to your registered email. `,
          {
            position: "bottom-right",
          }
        );
        /** when user successfully registered, navigate to confirmation code screen  */
        router.push({
          path: "/account-verification",
          query: { email: email.value },
        });
      } else {
        /** invalid password */
        if (
          apiResponse?.response?.data?.statusCode === "InvalidPasswordException"
        ) {
          toast.error(
            `Password must contains: min 8 characters, at-least; 1 number,
            1 special character, 1 upper-case and 1 lower-case character`,
            {
              position: "bottom-right",
            }
          );
        }
        /** email already exist */
        if (
          apiResponse?.response?.data?.statusCode == "UsernameExistsException"
        ) {
          toast.error("Email already exist", {
            position: "bottom-right",
          });
        }
        if (apiResponse?.response?.data?.statusCode == 400) {
          toast.error(apiResponse?.response?.data?.message, {
            position: "bottom-right",
          });
        }
        /** network error */
        if (apiResponse.code === "ERR_NETWORK") {
          toast.error(`Network Error`, {
            position: "bottom-right",
          });
        }
        /** internal server error */
        if (apiResponse?.response?.status === 500) {
          toast.error(`Internal Server Error`, {
            position: "bottom-right",
          });
          router.push("/server-error");
        }
      }
      /** Set isLoading to false after the sign-up request is completed */
      isLoading.value = false;
    };

    // Return the data and methods that need to be used in the template
    return {
      toast,
      name,
      email,
      password,
      confirmPassword,
      isLoading,
      showPassword,
      showConfirmPassword,
      passwordErrorMessage,
      confirmPasswordErrorMessage,
      validatePassword,
      validateConfirmPassword,
      togglePasswordVisibility,
      toggleConfirmPasswordVisibility,
      isDisabled,
      handleSignUp,
    };
  },
  computed: {
    /** store email and send it to account-verification data */
    ...mapState(["email"]),
  },
};
</script>

<style scoped>
.main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  background-color: #1c1c1c;
}
.inner-container {
  padding: 20px 10px;
  border-radius: 10px;
  /* height: 85vh; */
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
}
.heading {
  color: #fff;
  text-align: center;
}
.add-form {
  margin-bottom: 20px;
}
.account {
  display: flex;
  justify-content: flex-end;
  font-size: 12px;
  margin-right: 15px;
  color: #fff;
}
.account a {
  text-decoration: none !important;
  color: #fff;
  /* color: #1c1c1c; */
}
.btn-container {
  display: flex;
  justify-content: center;
}
.register-btn {
  background-color: #1c1c1c;
  color: white;
  width: 220px;
  height: 40px;
  font-size: 18px;
  border: 1px solid #fff;
  border-radius: 5px;
  cursor: pointer;
}
.register-btn:hover {
  background-color: #333;
}
.password-icon {
  position: absolute;
  right: 20px;
  top: 52px;
  cursor: pointer;
  color: #222;
}
.error-message {
  color: red;
  font-size: 12px;
  max-width: 300px;
  margin-left: 15px;
}
</style>
