<template>
  <DashboardLayout>
    <div class="grid grid-cols-2 gap-8 py-12 px-8">
      <div
        class="mycard min-h-[50vh] rounded-md col-span-2 2xl:col-span-1 bg-[#2D237E]"
      >
        <h1 class="text-white text-[16px] md:text-[24px] 2xl:text-[32px]">
          Data by Department
        </h1>
        <DonutChart />
      </div>
      <div
        class="mycard min-h-[50vh] rounded-md col-span-2 2xl:col-span-1 w-full bg-[#2D237E]"
      >
        <div class="flex w-full items-center justify-between">
          <h1 class="text-white text-[16px] md:text-[24px] 2xl:text-[32px]">
            Usage Growth
          </h1>
          <div class="flex gap-8 items-center">
            <div class="items-center sm:flex hidden gap-4">
              <div class="flex items-center gap-2">
                <div class="h-4 w-4 bg-[#4FBFFF] rounded-sm"></div>
                <span class="text-[11px] 2xl:text-[18px] text-white">US</span>
              </div>
              <div class="flex items-center gap-2">
                <div class="h-4 w-4 bg-[#2F46BD] rounded-sm"></div>
                <span class="text-[11px] 2xl:text-[18px] text-white"
                  >Europe</span
                >
              </div>
              <div class="flex items-center gap-2">
                <div class="h-4 w-4 bg-[#AEFB4D] rounded-sm"></div>
                <span class="text-[11px] 2xl:text-[18px] text-white">APAC</span>
              </div>
            </div>
            <div
              class="flex bg-[#4E3EE0] text-white rounded-full justify-content-center"
            >
              <Dropdown
                v-model="selectedCity"
                :options="cities"
                optionLabel="name"
                placeholder="Month"
                class="w-full px-4 py-2 flex items-center gap-3"
              />
            </div>
          </div>
          <div class="hidden items-center gap-4">
            <div class="flex items-center gap-2">
              <div class="h-4 w-4 bg-[#4FBFFF] rounded-sm"></div>
              <span class="text-[11px] 2xl:text-[18px] text-white">US</span>
            </div>
            <div class="flex items-center gap-2">
              <div class="h-4 w-4 bg-[#2F46BD] rounded-sm"></div>
              <span class="text-[11px] 2xl:text-[18px] text-white">Europe</span>
            </div>
            <div class="flex items-center gap-2">
              <div class="h-4 w-4 bg-[#AEFB4D] rounded-sm"></div>
              <span class="text-[11px] 2xl:text-[18px] text-white">APAC</span>
            </div>
          </div>
        </div>
        <div class="flex sm:hidden justify-between w-full items-center">
          <div class="flex w-full items-center gap-4 justify-between">
            <div class="flex items-center gap-2">
              <div class="h-4 w-4 bg-[#4FBFFF] rounded-sm"></div>
              <span class="text-[11px] 2xl:text-[18px] text-white">US</span>
            </div>
            <div class="flex items-center gap-2">
              <div class="h-4 w-4 bg-[#2F46BD] rounded-sm"></div>
              <span class="text-[11px] 2xl:text-[18px] text-white">Europe</span>
            </div>
            <div class="flex items-center gap-2">
              <div class="h-4 w-4 bg-[#AEFB4D] rounded-sm"></div>
              <span class="text-[11px] 2xl:text-[18px] text-white">APAC</span>
            </div>
          </div>
        </div>
        <LineChart />
      </div>
      <div
        class="mycard min-h-[50vh] rounded-md col-span-2 2xl:col-span-1 bg-[#2D237E]"
      >
        <h1 class="text-white text-[16px] md:text-[24px] 2xl:text-[32px]">
          World Map
        </h1>
        <MapChart />
      </div>
      <div
        class="mycard rounded-md min-h-[50vh] col-span-2 2xl:col-span-1 bg-[#2D237E]"
      >
        <h1 class="text-white text-[16px] md:text-[24px] 2xl:text-[32px]">
          Finance - Data by Process
        </h1>
        <BarChart />
      </div>
      <div class="mycard min-h-[70vh] rounded-md col-span-2 bg-[#2D237E]">
        <h1 class="text-white text-[16px] md:text-[24px] 2xl:text-[32px]">
          Hottest Topics
        </h1>
        <TopicsChart />
      </div>
    </div>
  </DashboardLayout>
</template>

<script setup>
import DashboardLayout from "@/Layouts/DashboardLayout.vue";
import DonutChart from "@/components/TailwindDashboard/DonutChart.vue";
import LineChart from "@/components/TailwindDashboard/LineChart.vue";
import BarChart from "@/components/TailwindDashboard/BarChart.vue";
import TopicsChart from "@/components/TailwindDashboard/TopicsChart.vue";
import MapChart from "@/components/TailwindDashboard/MapChart.vue";
import { ref } from "vue";

import Dropdown from "primevue/dropdown";
const selectedCity = ref();
const cities = ref([
  { name: "Jan", code: "NY" },
  { name: "Feb", code: "RM" },
  { name: "March", code: "LDN" },
]);
</script>

<style scoped>
.overflow-hidden {
  border-radius: 99999px;
}
</style>
