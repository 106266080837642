<template>
  <div class="chatbox-container">
    <div v-if="isLoading" class="chatbox-loader">
      <div
        class="spinner-border spinner-border-sm"
        role="status"
        style="margin-top: 30vh; margin-left: 50%"
      >
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <ChatHistoryMessages
      :chatMessages="chatMessages"
      ref="chatContainer"
      :chatId="chatId"
    />
    <div class="chatbox-input">
      <form @submit.prevent="sendMessage">
        <div class="chatbox-input-field-container">
          <textarea
            ref="textArea"
            type="text"
            rows="1"
            class="chatbox-input-field"
            @keydown.enter="handleKeyDown"
            v-model="newMessage"
            @input="adjustTextareaHeight"
          />
          <button
            :disabled="sending"
            class="chatbox-send-button"
            @click="handleSubmit"
          >
            <i class="fas fa-paper-plane chatbox-send-icon"></i>
          </button>
        </div>
        <p class="chatbox-input-paragraph">
          Chatbot Apr 13 Version. Free Research Preview. Our goal is to make AI
          systems more natural and safe to interact with. Your feedback will
          help us improve.
        </p>
      </form>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from "vue";
import { checkOpenAIKey, fetchMessages, sendQuery } from "@/apis/apis";
import ChatHistoryMessages from "./ChatHistoryMessages.vue";
import { useRoute, useRouter } from "vue-router";
import { parseJSONFromLocalStorage } from "@/utils/utils";
import { useToast } from "vue-toastification";

const chatMessages = ref([]);
const newMessage = ref("");
const sending = ref(false);
const chatContainerRef = ref();
const isLoading = ref(false);
const route = useRoute();
const toast = useToast();
const router = useRouter();

const userData = parseJSONFromLocalStorage("loggedInUser");

const chatId = computed(() => {
  return route.params.id; // Computed property for chatId
});

// Watch for changes in chatId and fetch messages accordingly
watch(chatId, async (newValue) => {
  isLoading.value = true;

  if (newValue) {
    const messages = await fetchMessages(
      { session: newValue },
      userData?.accessToken
    );
    chatMessages.value = messages;
    scrollToBottom();
    isLoading.value = false;
  }
});

onMounted(async () => {
  if (chatId.value) {
    const messages = await fetchMessages(
      { session: chatId.value },
      userData?.accessToken
    );
    chatMessages.value = messages;
    scrollToBottom();
  }
});

const handleSubmit = async () => {
  const openAiKeyResponse = await checkOpenAIKey(userData?.accessToken);
  sending.value = true;

  const queryText = newMessage.value;
  chatMessages.value.push({
    message: queryText,
    isUser: true,
  });
  newMessage.value = "";
  scrollToBottom();

  /** unauthorized */
  if (openAiKeyResponse?.response?.status === 401) {
    localStorage.removeItem("loggedInUser");

    alert("Session Expired! Please Login Again");
    window.location.href = "/login";
  }
  /** internal server error */
  if (openAiKeyResponse?.response?.status === 500) {
    toast.error(`Internal Server Error`, {
      position: "bottom-right",
    });
    router.push("/server-error");
  }

  if (!openAiKeyResponse?.openAiKeyProvided) {
    newMessage.value = "";
    toast.error("The OpenAI key was not provided by the company", {
      position: "bottom-right",
    });
    return; // Don't proceed if openAiKeyProvided is false
  }

  chatMessages.value.push({
    message: "Waiting for response...",
    isUser: false,
  });
  scrollToBottom();

  if (openAiKeyResponse?.openAiKeyProvided) {
    const response = await sendQuery(
      queryText,
      chatId.value,
      userData?.accessToken
    );

    // Check if there's at least one message in chatMessages before updating the last message
    if (chatMessages.value.length > 0) {
      chatMessages.value[chatMessages.value.length - 1].message = "";
    }
    if (response?.response?.data?.statusCode === "openAiKeyMissing") {
      toast.error("The OpenAI key was not provided by the company", {
        position: "bottom-right",
      });
    } else if (response && response.message) {
      const messageArray = response.message.split(" ");
      let index = 0;
      const typingInterval = setInterval(() => {
        if (chatMessages.value.length > 0) {
          chatMessages.value[chatMessages.value.length - 1].message +=
            messageArray[index] + " ";
          scrollToBottom();
          index++;
          if (index >= messageArray.length) {
            clearInterval(typingInterval);
          }
        }
      }, 100);
    }
  }
  sending.value = false;
};

const scrollToBottom = () => {
  const chatContainer = chatContainerRef.value;
  if (chatContainer && chatContainer.lastElementChild) {
    chatContainer.lastElementChild.scrollIntoView({ behavior: "smooth" });
  }
};

const handleKeyDown = (event) => {
  event.preventDefault();
  if (event.key === "Enter" && event.shiftKey) {
    event.target.value += "\n";
    const textarea = event.target;
    textarea.rows += 1;
  } else if (event.key === "Enter") {
    handleSubmit();
    const textarea = event.target;
    textarea.rows = 1;
  }
};

const adjustTextareaHeight = (event) => {
  const textarea = event.target;
  textarea.style.height = "auto";
  textarea.style.height = `${textarea.scrollHeight}px`;
};
</script>

<style>
.chatbox-container {
  background-color: #1c1c1c;
  font-size: 16px;
  color: #fff;
  height: 100vh;
  display: flex;
  flex-direction: column;
  z-index: 1;
}

.chatbox-messages {
  color: #fff;
  flex-grow: 1;
  overflow-y: scroll;
}

.chatbox-message {
  color: #fff;
  width: 100%;
  padding: 1rem;
  display: flex;
  align-items: center;
  scrollbar-color: #1c1c1c #8b8b8b;
}

.chatbox-messages::-webkit-scrollbar {
  width: 8px;
}

.chatbox-messages::-webkit-scrollbar-track {
  background: #1c1c1c;
}

.chatbox-messages::-webkit-scrollbar-thumb {
  background-color: #696464;
  border-radius: 3px;
}

.user-message {
  color: #fff;
  background-color: #1c1c1c;
}

.user-message .chatbox-message-text {
  position: relative;
}

.bot-message {
  color: #fff;
  background-color: #383232;
}

.bot-message .chatbox-message-text {
  position: relative;
}

.chatbox-input {
  padding: 1rem;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  background-color: transparent;
}

.chatbox-input-field-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #383232;
  border-radius: 8px;
  padding: 0.3rem;
  margin-left: 4rem;
  margin-right: 4rem;
  margin-bottom: 1.5rem;
}

.chatbox-input-field {
  flex: 1;
  padding: 8px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  color: #fff;
  background-color: transparent;
}

.chatbox-send-button {
  width: 2.5rem;
  height: 2.5rem;
  margin-left: 10px;
  padding: 10px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
  background-color: transparent;
}

.chatbox-input-field:focus {
  outline: none;
}

.chatbox-send-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.chatbox-send-button:active {
  background-color: rgba(255, 255, 255, 0.2);
}
.chatbox-send-button[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}

.chatbox-input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  bottom: 0;
  left: 0;
  width: 100%;
  background-image: linear-gradient(to top, rgba(56, 50, 50, 1), transparent);
  padding: 20px;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);
}

.chatbox-input-field-container {
  display: flex;
  align-items: center;
}

.chatbox-input-field {
  flex: 1;
  padding: 8px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  color: #fff;
  max-height: 10em;
}

.chatbox-send-button:hover {
  background-color: rgba(46, 46, 46, 1);
}

.chatbox-send-icon {
  margin-right: 5px;
  color: #fff;
  font-size: 1.4rem;
}

.chatbox-input-paragraph {
  margin: 0;
  text-align: center;
  color: #b1b1b1;
  font-size: 12px;
  line-height: 1;
}

.chatbox-input-field {
  width: 100%;
  resize: none;
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 2;
  transition: all 0.2s ease;
  overflow: auto;
}
/* for mobile screen */
@media only screen and (max-width: 768px) {
  .chatbox-input-field-container {
    margin-left: 0rem;
    margin-right: 0rem;
  }
}
</style>
