<template>
  <!-- <h3 class="text-center">Change Password</h3> -->
  <div style="display: flex; justify-content: center">
    <form @submit="onSubmit" class="add-form">
      <!-- confirmation code input -->
      <InputField
        label="Confirmation Code"
        v-model:modelValue="confirmationCode"
        type="text"
        name="confirmationCode"
        placeholder="Enter 6-digit Code"
      />
      <!--  new password input -->
      <div class="d-flex position-relative">
        <InputField
          label="New Password"
          v-model:modelValue="newPassword"
          :type="showNewPassword ? 'text' : 'password'"
          name="newPassword"
          placeholder="Enter New Password"
          @input="validateNewPassword"
        />
        <div class="password-icon" @click="toggleNewPasswordVisibility">
          <i v-if="showNewPassword" class="fa-solid fa-eye"></i>
          <i v-else class="fa-solid fa-eye-slash"></i>
        </div>
      </div>
      <!-- new password error messages -->
      <div class="error-message" v-if="newPasswordErrorMessage">
        {{ newPasswordErrorMessage }}
      </div>
      <!-- confirm password input -->
      <div class="d-flex position-relative">
        <InputField
          label="Confirm Password"
          v-model:modelValue="confirmPassword"
          :type="showConfirmPassword ? 'text' : 'password'"
          name="confirmPassword"
          placeholder="Confirm password"
          @input="validateConfirmPassword"
        />
        <div class="password-icon" @click="toggleConfirmPasswordVisibility">
          <i v-if="showConfirmPassword" class="fa-solid fa-eye"></i>
          <i v-else class="fa-solid fa-eye-slash"></i>
        </div>
      </div>
      <!-- confirm password error messages -->
      <div class="error-message" v-if="confirmPasswordErrorMessage">
        {{ confirmPasswordErrorMessage }}
      </div>
      <!-- loader and change password button -->
      <div class="btn-container mt-3">
        <button class="change-btn" type="submit" :disabled="!isPasswordMatched">
          <span v-if="isLoading">
            <div class="spinner-border spinner-border-sm" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </span>
          <span v-else>Change Password</span>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";
import { forgetPassword } from "@/apis/auth.js";
import InputField from "../../common/InputField.vue";
import { useRouter } from "vue-router";
import { parseJSONFromLocalStorage } from "@/utils/utils";

export default {
  name: "NewPassword",
  components: {
    InputField,
  },
  data() {
    return {
      toast: useToast(),
      router: useRouter(),
      userData: parseJSONFromLocalStorage("loggedInUser"),
      confirmationCode: "",
      newPassword: "",
      showNewPassword: false,
      newPasswordErrorMessage: "",
      confirmPassword: "",
      showConfirmPassword: false,
      confirmPasswordErrorMessage: "",
      isLoading: false,
    };
  },
  methods: {
    // password validation
    validateNewPassword() {
      const passwordRegex =
        /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
      const requirements = [
        {
          regex: /.*[A-Z].*/,
          message: "1 uppercase letter",
        },
        {
          regex: /.*[a-z].*/,
          message: "1 lowercase letter",
        },
        {
          regex: /.*\d.*/,
          message: "1 digit",
        },
        {
          regex: /.*[!@#$%^&*].*/,
          message: "1 special character",
        },
      ];

      this.newPasswordErrorMessage = "";

      if (this.newPassword.length > 0) {
        if (this.newPassword.length < 8) {
          this.newPasswordErrorMessage =
            "Password must have at least 8 characters";
          return;
        }

        requirements.forEach((requirement) => {
          if (!requirement.regex.test(this.newPassword)) {
            this.newPasswordErrorMessage += `${requirement.message}, `;
          }
        });

        if (passwordRegex.test(this.newPassword)) {
          this.newPasswordErrorMessage = "";
        } else {
          this.newPasswordErrorMessage =
            "Password must contain " +
            this.newPasswordErrorMessage.slice(0, -2) +
            ".";
        }
      } else {
        // If the password field is empty, clear the error message
        this.newPasswordErrorMessage = "";
      }
    },
    // confirm password validation
    validateConfirmPassword() {
      this.confirmPasswordErrorMessage = "";
      if (this.newPassword !== this.confirmPassword) {
        this.confirmPasswordErrorMessage = "Password not matched";
      }
    },
    // show/hide password
    toggleConfirmPasswordVisibility() {
      this.showConfirmPassword = !this.showConfirmPassword;
    },
    // show/hide new password
    toggleNewPasswordVisibility() {
      this.showNewPassword = !this.showNewPassword;
    },
    /** change email on submit */
    async onSubmit(e) {
      e.preventDefault();
      this.isLoading = true;

      const data = {
        email: this.userData.email,
        password: this.newPassword,
        verificationCode: this.confirmationCode,
      };

      const response = await forgetPassword(data, this.userData.accessToken);
      /** success case */
      if (response?.status) {
        this.isOldPasswordResponse = true;
        this.toast.success("Password changed successfully", {
          position: "bottom-right",
        });
        this.toast.success("Please Login with new password", {
          position: "bottom-right",
        });

        localStorage.removeItem("loggedInUser");
        /**  After successful sign-out, you can redirect to the login page */
        this.router.push("/login");
      } else {
        /** invalid verification code error */
        if (response?.response?.data?.statusCode === "CodeMismatchException") {
          this.toast.error(`Invalid verification code`, {
            position: "bottom-right",
          });
        }
        /** if account already registered on the email */
        if (response?.response?.data?.statusCode === "AliasExistsException") {
          this.toast.error(`An account with this email already exist`, {
            position: "bottom-right",
          });
        }
        /** expire code error */
        if (response?.response?.data?.statusCode === "ExpiredCodeException") {
          this.toast.error(`Code has been expired. Please request again`, {
            position: "bottom-right",
          });
        }
        /** attempts limit exceeded error */
        if (response?.response?.data?.statusCode === "LimitExceededException") {
          this.toast.error(`Limit exceeded. Try after sometime`, {
            position: "bottom-right",
          });
        }
      }

      /** network error */
      if (response.code === "ERR_NETWORK") {
        this.toast.error(`Network Error`, {
          position: "bottom-right",
        });
      }
      /** internal server error */
      if (response?.response?.status === 500) {
        this.toast.error(`Internal Server Error`, {
          position: "bottom-right",
        });
        this.router.push("/server-error");
      }
      this.isLoading = false;
    },
  },
  computed: {
    isPasswordMatched() {
      return this.newPassword === this.confirmPassword;
    },
  },
};
</script>

<style scoped>
.add-form {
  margin-bottom: 20px;
}
.btn-container {
  display: flex;
  justify-content: center;
}
.change-btn {
  background-color: #333;
  color: white;
  width: 200px;
  height: 40px;
  font-size: 18px;
  border: 1px solid #fff;
  border-radius: 5px;
  cursor: pointer;
}
.change-btn:hover {
  background-color: #555;
}
.password-icon {
  position: absolute;
  right: 20px;
  top: 52px;
  cursor: pointer;
  color: #222;
}
.error-message {
  color: red;
  font-size: 12px;
  max-width: 300px;
  margin-left: 15px;
}
</style>
