<template>
  <div class="container">
    <div class="row">
      <div class="col col-12 col-sm-12 col-lg-4">
        <PluginsCard />
      </div>
      <div class="col col-12 col-sm-12 col-lg-4">
        <PluginsCard />
      </div>
      <div class="col col-12 col-sm-12 col-lg-4">
        <PluginsCard />
      </div>
      <div class="col col-12 col-sm-12 col-lg-4">
        <PluginsCard />
      </div>
    </div>
  </div>
</template>
<script>
import PluginsCard from "@/components/common/PluginsCard.vue";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Plugins",
  components: {
    PluginsCard,
  },
};
</script>
<style scoped></style>
