<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.99962 9.29986H20.9996M6.59962 13.4999H9.59962M4.80009 5.10001H19.1997C20.5252 5.10001 21.5997 6.17368 21.5997 7.49914L21.6 16.501C21.6 17.8265 20.5255 18.9 19.2 18.9L4.80032 18.8998C3.47487 18.8998 2.40038 17.8254 2.40034 16.4999L2.40009 7.50007C2.40005 6.17456 3.47458 5.10001 4.80009 5.10001Z"
      stroke="#F8F7FF"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
