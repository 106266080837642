<template>
  <div>
    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog
        as="div"
        class="relative z-50 lg:hidden"
        @close="sidebarOpen = false"
      >
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-gray-900/80" />
        </TransitionChild>

        <div class="fixed inset-0 flex">
          <TransitionChild
            as="template"
            enter="transition ease-in-out duration-300 transform"
            enter-from="-translate-x-full"
            enter-to="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leave-from="translate-x-0"
            leave-to="-translate-x-full"
          >
            <DialogPanel class="relative flex flex-1 w-full max-w-xs mr-16">
              <TransitionChild
                as="template"
                enter="ease-in-out duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in-out duration-300"
                leave-from="opacity-100"
                leave-to="opacity-0"
              >
                <div
                  class="absolute top-0 flex justify-center w-16 pt-5 left-full"
                >
                  <button
                    type="button"
                    class="-m-2.5 p-2.5"
                    @click="sidebarOpen = false"
                  >
                    <span class="sr-only">Close sidebar</span>
                    <XMarkIcon class="w-6 h-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </TransitionChild>
              <!-- Sidebar component, swap this element with another sidebar if you like -->
              <div
                class="flex flex-col pl-2 pb-2 overflow-y-auto bg-indigo-900 grow gap-y-5"
              >
                <div class="flex items-center h-16 shrink-0">
                  <svg
                    @click="sidebarOpen = false"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="white"
                    class="w-6 h-6 cursor-pointer ml-3"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </div>
                <nav class="flex flex-col flex-1">
                  <ul role="list" class="flex flex-col flex-1 gap-y-7">
                    <ul role="list" class="-mx-2 space-y-3">
                      <li v-for="item in navigation" :key="item.name">
                        <router-link
                          v-if="!item.children"
                          :to="item.href"
                          @click="setLink(item.href)"
                          :class="[
                            item.current
                              ? 'bg-[#271C9E]   border-l-4 text-[#F8F6FF] border-[#4E3EE0]'
                              : 'text-[#F8F6FF] border-l-4 border-transparent  hover:border-l-4 hover:border-[#4E3EE0]  hover:text-white hover:bg-[#271C9E]',
                            'group flex gap-x-3  items-center    py-1.5 px-4 text-[18px] font-medium leading-6 ',
                          ]"
                        >
                          <component
                            :is="item.icon"
                            :class="[
                              item.current
                                ? 'text-white'
                                : 'text-white group-hover:text-white',
                              'h-6 w-6 shrink-0',
                            ]"
                            aria-hidden="true"
                          />
                          {{ item.name }}

                          <span class="pl-4"
                            ><tag-view :number="item.number"></tag-view
                          ></span>
                        </router-link>
                        <Disclosure as="div" v-else v-slot="{ open }">
                          <DisclosureButton
                            :class="[
                              item.current
                                ? 'bg-[#271C9E]   border-l-4 text-[#F8F6FF] border-[#4E3EE0]'
                                : 'text-[#F8F6FF] border-l-4 border-transparent  hover:border-l-4 hover:border-[#4E3EE0]  hover:text-white hover:bg-[#271C9E]',
                              'group flex gap-x-3  w-full   py-1.5 px-4 text-[18px] font-medium leading-6 ',
                            ]"
                          >
                            <component
                              :is="item.icon"
                              class="w-6 h-6 shrink-0 white"
                              aria-hidden="true"
                            />
                            {{ item.name }}
                            <span class="pl-4"
                              ><tag-view :number="item.number"></tag-view
                            ></span>
                            <ChevronRightIcon
                              :class="[
                                open ? 'rotate-90 text-white' : 'text-white',
                                'ml-auto h-4 w-4 shrink-0',
                              ]"
                              aria-hidden="true"
                            />
                          </DisclosureButton>
                          <DisclosurePanel as="ul" class="px-2 mt-1">
                            <li
                              v-for="subItem in item.children"
                              :key="subItem.name"
                            >
                              <!-- 44px -->
                              <div
                                @click="openSubLink = !openSubLink"
                                :class="[
                                  subItem.current ? 'bg-gray-50' : ' ',
                                  ' rounded-md gap-3 cursor-pointer py-2 flex items-center pr-2 pl-12 text-sm leading-6 ',
                                ]"
                              >
                                <div
                                  class="h-2 w-2 bg-[#4E3EE0] rounded-full"
                                ></div>
                                <span class="text-[#F8F6FF] text-[18px]">{{
                                  subItem.name
                                }}</span>
                                <ChevronRightIcon
                                  :class="[
                                    openSubLink
                                      ? 'rotate-90 text-white'
                                      : 'text-white',
                                    'ml-auto h-4 w-4 shrink-0',
                                  ]"
                                  aria-hidden="true"
                                />
                              </div>
                              <ul>
                                <li v-if="openSubLink">
                                  <a
                                    href="#"
                                    class="text-[#F8F6FF] text-[18px] rounded-md gap-3 py-2 flex items-center pr-2 pl-16 leading-6"
                                    v-for="i in subItem.children"
                                    :key="i"
                                  >
                                    <div
                                      class="h-2 w-2 bg-[#4E3EE0] rounded-full"
                                    ></div>

                                    {{ i.name }}
                                    <tag-view :number="item.number"></tag-view>
                                  </a>
                                </li>
                              </ul>
                            </li>
                          </DisclosurePanel>
                        </Disclosure>
                      </li>
                    </ul>
                  </ul>
                </nav>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Static sidebar for desktop -->
    <div
      class="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-80 lg:flex-col"
    >
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div class="flex flex-col px-2 overflow-y-auto grow gap-y-5 bg-darkBlue">
        <div class="flex items-center px-4 py-4 h-28 shrink-0">
          <LogoDesktop />
        </div>
        <nav class="flex flex-col flex-1 mt-20">
          <ul role="list" class="flex flex-col flex-1 gap-y-7">
            <li>
              <ul role="list" class="-mx-2 space-y-3">
                <li v-for="item in navigation" :key="item.name">
                  <router-link
                    v-if="!item.children"
                    :to="item.href"
                    @click="setLink(item.href)"
                    :class="[
                      active === item.href
                        ? 'bg-[#271C9E]   border-l-4 text-[#F8F6FF] border-[#4E3EE0]'
                        : 'text-[#F8F6FF] border-l-4 border-transparent  hover:border-l-4 hover:border-[#4E3EE0]  hover:text-white hover:bg-[#271C9E]',
                      'group flex gap-x-3  items-center    py-1.5 px-4 text-[18px] font-medium leading-6 ',
                    ]"
                  >
                    <component
                      :is="item.icon"
                      :class="[
                        item.current
                          ? 'text-white'
                          : 'text-white group-hover:text-white',
                        'h-6 w-6 shrink-0',
                      ]"
                      aria-hidden="true"
                    />
                    {{ item.name }}

                    <span class="pl-4"
                      ><tag-view :number="item.number"></tag-view
                    ></span>
                  </router-link>
                  <Disclosure as="div" v-else v-slot="{ open }">
                    <DisclosureButton
                      :class="[
                        item.current
                          ? 'bg-[#271C9E]   border-l-4 text-[#F8F6FF] border-[#4E3EE0]'
                          : 'text-[#F8F6FF] border-l-4 border-transparent  hover:border-l-4 hover:border-[#4E3EE0]  hover:text-white hover:bg-[#271C9E]',
                        'group flex gap-x-3  w-full   py-1.5 px-4 text-[18px] font-medium leading-6 ',
                      ]"
                    >
                      <component
                        :is="item.icon"
                        class="w-6 h-6 shrink-0 white"
                        aria-hidden="true"
                      />
                      {{ item.name }}
                      <span class="pl-4"
                        ><tag-view :number="item.number"></tag-view
                      ></span>
                      <ChevronRightIcon
                        :class="[
                          open ? 'rotate-90 text-white' : 'text-white',
                          'ml-auto h-4 w-4 shrink-0',
                        ]"
                        aria-hidden="true"
                      />
                    </DisclosureButton>
                    <DisclosurePanel as="ul" class="px-2 mt-1">
                      <li v-for="subItem in item.children" :key="subItem.name">
                        <!-- 44px -->
                        <div
                          @click="openSubLink = !openSubLink"
                          :class="[
                            subItem.current ? 'bg-gray-50' : ' ',
                            ' rounded-md gap-3 cursor-pointer py-2 flex items-center pr-2 pl-12 text-sm leading-6 ',
                          ]"
                        >
                          <div class="h-2 w-2 bg-[#4E3EE0] rounded-full"></div>
                          <span class="text-[#F8F6FF] text-[18px]">{{
                            subItem.name
                          }}</span>
                          <ChevronRightIcon
                            :class="[
                              openSubLink
                                ? 'rotate-90 text-white'
                                : 'text-white',
                              'ml-auto h-4 w-4 shrink-0',
                            ]"
                            aria-hidden="true"
                          />
                        </div>
                        <ul>
                          <li v-if="openSubLink">
                            <a
                              href="#"
                              class="text-[#F8F6FF] text-[18px] rounded-md gap-3 py-2 flex items-center pr-2 pl-16 leading-6"
                              v-for="i in subItem.children"
                              :key="i"
                            >
                              <div
                                class="h-2 w-2 bg-[#4E3EE0] rounded-full"
                              ></div>

                              {{ i.name }}
                              <tag-view :number="item.number"></tag-view>
                            </a>
                          </li>
                        </ul>
                      </li>
                    </DisclosurePanel>
                  </Disclosure>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      </div>
    </div>

    <div
      class="sticky top-0 z-40 flex items-center justify-between px-4 py-4 bg-[#110B46] shadow-sm gap-x-6 sm:px-6 lg:hidden"
    >
      <div class="text-sm font-semibold leading-6 text-white">
        <button @click="sidebarOpen = true">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
            />
          </svg>
        </button>
      </div>
      <div>
        <a href="/">
          <img class="h-14" :src="accumeai" alt="Your Company" />
        </a>
      </div>
      <div>
        <div class="p-4 rounded"></div>
      </div>
    </div>

    <main class="min-h-screen lg:pl-80 bg-primaryBlue">
      <slot />
    </main>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";

import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import { ChevronRightIcon } from "@heroicons/vue/20/solid";

import "../index.css";
import HR from "@/icons/HR.vue";
import LegalIcon from "@/icons/LegalIcon.vue";
import FinanceIcon from "@/icons/FinanceIcon.vue";
import MarketingIcon from "@/icons/MarketingIcon.vue";
import SalesIcon from "@/icons/SalesIcon.vue";
import IT from "@/icons/IT.vue";
import DevIcon from "@/icons/DevIcon.vue";
import LogoDesktop from "@/icons/LogoDesktop.vue";
import accumeai from "../assets/acumenai.png";
import TagView from "@/components/TailwindDashboard/TagView.vue";
import { useRoute } from "vue-router";

const router = useRoute();
const active = ref(router.path);

const sidebarOpen = ref(false);

console.log("====================================");
console.log(accumeai);
console.log("====================================");
const openSubLink = ref(false);
const navigation = [
  { name: "Hr", href: "/dashboard", icon: HR, current: true, number: 676 },
  { name: "Legal", href: "#", icon: LegalIcon, current: false, number: 676 },
  {
    name: "Finance",
    href: "#",
    icon: FinanceIcon,
    current: false,
    children: [
      {
        name: "Accounts Payable",
        children: [{ name: "Invoice Processing", href: "#" }],
      },
    ],
    number: 777,
  },
  {
    name: "Marketing",
    href: "#",
    icon: MarketingIcon,
    current: false,
    number: 676,
  },
  { name: "Sales", href: "#", icon: SalesIcon, current: false, number: 676 },
  { name: "IT", href: "#", icon: IT, current: false, number: 376 },
  { name: "Dev", href: "#", icon: DevIcon, current: false, number: 176 },
  {
    name: "LLMS Logs",
    href: "/logs",
    icon: DevIcon,
    current: false,
    number: 76,
  },
];
const setLink = (link) => {
  console.log("====================================");
  console.log(link);
  console.log("====================================");
  active.value = link;
};
onMounted(() => {
  console.log("====================================");
  console.log(router.path, active.value);
  active.value = router.path;
  console.log("====================================");
});
</script>
