<template>
  <div>
    <!-- Top Header for small devices [start] -->
    <div class="sidebar-header">
      <i class="fa-solid fa-bars fa-lg" @click="toggleSidebar()"></i>
      <!-- <h5>HEADER</h5> -->
      <i class="fa-solid fa-lg" @click="toggleSidebar()">
        <template v-if="isMobile && showSidebar">
          <!-- Show cross icon when sidebar open -->
          <i class="fa-solid fa-times"></i>
        </template>
        <template v-else>
          <!-- Show add icon when sidebar close -->
          <i class="fa-solid fa-plus"></i>
        </template>
      </i>
    </div>
    <!-- Top Header for small devices [end] -->

    <!-- Sidebar for desktop devices [start] -->
    <div class="main-sidebar">
      <div class="sidebar-queries">
        <div class="sidebar-menu">
          <!-- Create a new chat -->
          <ul style="width: 200px; margin: auto">
            <li>
              <router-link to="/" @click="createNewChat">
                + New Chat
              </router-link>
            </li>
          </ul>
          <!-- show all previous chats -->
          <ul class="sidebar-queries chat-history">
            <ChatHistory />
          </ul>
        </div>
      </div>

      <div class="sidebar-menu-container">
        <div class="sidebar-menu">
          <ul style="width: 200px" @click="createNewChat">
            <!-- Company Admin can see company-detail and company-users -->
            <template v-if="userRole === 'admin'">
              <!-- <li>
                <router-link to="/companies">Companies</router-link>
              </li> -->
              <li>
                <router-link to="/company">Company</router-link>
              </li>
              <li>
                <router-link to="/company/users">Users</router-link>
              </li>
            </template>
            <!-- logged-in user info -->
            <li class="dropdown">
              <a
                class="dropdown-toggle"
                href="#"
                role="button"
                id="loginDropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i class="fas fa-sign-in-alt"></i>&nbsp;{{ username }}
              </a>
              <ul
                style="width: 200px"
                class="dropdown-menu"
                aria-labelledby="loginDropdown"
              >
                <li>
                  <router-link class="dropdown-item" to="/settings">
                    <i class="fa-solid fa-gear"></i>&nbsp;Settings</router-link
                  >
                </li>

                <li>
                  <a class="dropdown-item" href="#" @click="onSignOut"
                    ><i class="fas fa-sign-out-alt"></i>&nbsp;Logout</a
                  >
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- Sidebar for desktop devices [end] -->
  </div>
</template>

<script>
import { useStore } from "vuex";
import { ref, nextTick } from "vue";
// import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import { parseJSONFromLocalStorage } from "@/utils/utils";
import ChatHistory from "@/components/chat/ChatHistory.vue";

export default {
  name: "MainSidebar",
  emits: ["new-chat"],
  components: {
    ChatHistory,
  },

  setup() {
    // Get the toast instance using useToast
    const toast = useToast();
    // Get the router instance using useRouter
    // const router = useRouter();

    const store = useStore();

    const username = ref("");
    const userRole = ref("");
    const isMobile = ref(false);
    const showSidebar = ref(false);
    const chats = ref([]);

    const userData = parseJSONFromLocalStorage("loggedInUser");

    /* check mobile view */
    const checkMobileView = () => {
      isMobile.value = window.innerWidth <= 768;
    };
    /* open side bar */
    const toggleSidebar = () => {
      showSidebar.value = !showSidebar.value;
      if (isMobile.value) {
        const body = document.querySelector("body");
        body.classList.toggle("mobile-sidebar-open", showSidebar.value);
      }
    };
    /** get username from local storage */
    const getUserName = () => {
      if (userData?.accessToken && userData?.name) {
        const nameParts = userData?.name.split(" ");
        username.value = nameParts[0];
      }
    };
    /** get userRole from local storage */
    const getUserRole = () => {
      if (userData?.accessToken && userData?.role) {
        /** assign username value */
        userRole.value = userData?.role;
      }
    };
    //  handle logout
    const onSignOut = async () => {
      try {
        localStorage.clear();

        sessionStorage.clear();

        document.cookie.split(";").forEach(function (c) {
          document.cookie =
            c.trim().split("=")[0] +
            "=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
        });

        toast.success("Logged Out", {
          position: "bottom-right",
        });

        window.location.href = "/login";
      } catch (error) {
        /** Handle errors and display an error toast notification */
        toast.error("Failed to log out, please try again", {
          position: "bottom-right",
        });
      }
    };

    // Method to create a new chat
    const createNewChat = async () => {
      // Wait for the next tick to ensure any DOM updates are complete
      await nextTick();
      // previous selected chat id empty
      store.state.selectedChatId = null;
      // clear messages
      await store.dispatch("clearChatMessages");
      await store.dispatch("setIsNewSessionCreated", false);
    };
    // Return the data and methods that need to be used in the template
    return {
      username,
      userRole,
      isMobile,
      showSidebar,
      chats,
      checkMobileView,
      toggleSidebar,
      getUserName,
      getUserRole,
      createNewChat,
      onSignOut,
    };
  },

  mounted() {
    /** check device */
    this.checkMobileView();
    window.addEventListener("resize", this.checkMobileView);
    /**  Call getUserName method to retrieve the username from local storage */
    this.getUserName();
    this.getUserRole();
  },
};
</script>

<style scoped>
.sidebar-header {
  display: none;
}
.sidebar-header h3 {
  margin: 0;
  background-color: #222;
}
.main-sidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 250px;
  background-color: #222;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}
.sidebar-menu-container {
  position: absolute;
  bottom: 10px;
  border-top: 0.1px solid #555;
  margin-left: 3px;
  max-height: 170px;
  min-height: auto;
}
.sidebar-menu {
  padding: 20px;
}
.sidebar-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.sidebar-menu ul li {
  margin-bottom: 10px;
}
.sidebar-menu ul li a {
  display: block;
  /* padding: 10px; */
  height: 40px !important;
  display: flex;
  align-items: center;
  padding-left: 10px;
  background-color: #333;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}
.sidebar-menu ul li a:hover {
  background-color: #555;
}
/* new styles */
.sidebar-queries {
  display: flex;
  justify-content: center;
  height: 50%;
  background-color: #222;
  color: #fff;
}
.chat-history {
  height: calc(100vh - 250px);
  width: 240px;
  overflow-y: scroll;
}
.chat-history::-webkit-scrollbar {
  width: 5px; /* Set the width of the scrollbar */
}

.chat-history::-webkit-scrollbar-thumb {
  background-color: #888; /* Set the color of the thumb */
}

.chat-history::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Set the color of the thumb on hover */
}

.chat-history::-webkit-scrollbar-track {
  background-color: #555; /* Set the background color of the scrollbar track */
  border-radius: 20px;
}

.chat-history::-webkit-scrollbar-track:hover {
  background-color: #ddd; /* Set the background color of the scrollbar track on hover */
}
.dropdown {
  position: relative !important;
}
.dropdown-toggle::after {
  /* margin-left: 110px !important;
  margin-right: 10px !important; */
  position: absolute !important;
  top: 20px;
  right: 10px;
}

.dropdown-item {
  background-color: transparent !important;
  color: #222 !important;
  padding: 5px !important;
  height: 40px;
}
/* for mobile screen */
@media only screen and (max-width: 768px) {
  .main-sidebar {
    display: none;
  }
  .sidebar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    background-color: #222;
    color: #fff;
    position: absolute;
    top: 0;
    width: 100%;
    padding: 0 10px;
  }
  .mobile-sidebar-open .main-sidebar {
    display: block;
    width: 250;
  }
}
</style>
