<template>
  <div class="company-card-main-container">
    <div class="row">
      <div class="col col-12">
        <div class="company-detail-container">
          <!-- Detail Card [start] -->
          <div>
            <button class="back-button" @click="goBack">
              <i class="fa-solid fa-arrow-left fa-lg" />
            </button>
            <div>
              <div v-if="isLoading" class="placeholder-glow">
                <span class="placeholder col-4"></span>
              </div>
              <h2 v-else>
                {{ companyDetails?.domain }}
              </h2>
            </div>
            <div>
              <h5 v-if="isLoading" class="card-title placeholder-glow">
                <span class="placeholder col-4"></span>
              </h5>
              <h5 v-else class="card-subtitle my-2">
                {{ userData?.email }}
              </h5>
            </div>
            <div
              class="btn-container"
              style="
                display: flex;
                justify-content: space-between;
                align-items: center;
              "
            >
              <router-link to="company/users">
                <button class="view-button">View Company Users</button>
              </router-link>
              <!-- Document Upload [start] -->
              <DocUpload />
              <!-- Document Upload [end] -->
            </div>

            <!-- Company BOTS [start] -->
            <CompanyPrompts />
            <!-- Company BOTS [end] -->

            <!-- Privacy Accordian [start] -->
            <CompanyPolicy />
            <!-- Privacy Accordian [end] -->
          </div>
          <!-- Detail Card [end] -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DocUpload from "./DocUpload.vue";
import CompanyPolicy from "./CompanyPolicy.vue";
import { fetchCompanyDetail } from "@/apis/apis";
import CompanyPrompts from "./CompanyPrompts.vue";
import { parseJSONFromLocalStorage } from "@/utils/utils";

export default {
  name: "CompanyCard",
  components: {
    CompanyPolicy,
    DocUpload,
    CompanyPrompts,
  },
  data() {
    return {
      companyDetails: null,
      isLoading: false,
      userData: parseJSONFromLocalStorage("loggedInUser"),
    };
  },
  methods: {
    /** go to the previous page */
    goBack() {
      this.$router.go(-1);
    },
    /** fetch company details */
    async companyDetail() {
      this.isLoading = true;

      let data;

      if (this.userData.accessToken) {
        data = await fetchCompanyDetail(
          { companyId: this.userData?.company },
          this.userData?.accessToken
        );
        this.companyDetails = data;
      }
      this.isLoading = false;
    },
  },
  async mounted() {
    await this.companyDetail();
  },
};
</script>
<style scoped>
.company-card-main-container {
  background-color: #1c1c1c;
  min-height: 100vh;
  max-height: auto;
  padding: 10px;
  overflow-x: hidden;
}
.company-detail-container {
  color: #fff;
}
.btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.back-button {
  position: absolute;
  right: 10px;
  top: 15px;
  cursor: pointer;
  border: 1px solid #fff;
  background-color: #333;
  color: #fff;
  width: 50px;
  height: 40px;
  border-radius: 5px;
}
.view-button {
  cursor: pointer;
  border: 1px solid #fff;
  background-color: #333;
  color: #fff;
  height: 40px;
  border-radius: 5px;
  padding: 0 10px;
  margin: 10px 0;
}
/* for mobile screen */
@media only screen and (max-width: 768px) {
  .company-detail-container {
    margin-top: 80px;
  }
  .back-button {
    top: 100px;
  }
}
@media only screen and (max-width: 400px) {
  .btn-container {
    flex-direction: column;
  }
}
</style>
