<template>
  <div class="main-container">
    <h1 class="error">404</h1>
    <div class="page">Oops!!! The page you are looking for is not found</div>
  </div>
</template>

<script>
export default {
  name: "NotFound",
};
</script>

<style scoped>
.main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  background-color: #1c1c1c;
  color: #fff;
}
.error {
  font-size: 150px;
  color: #333;
  text-shadow: 1px 1px 1px #555, 2px 2px 1px #555, 3px 3px 1px #555,
    4px 4px 1px #555, 5px 5px 1px #555, 6px 6px 1px #555, 7px 7px 1px #555,
    8px 8px 1px #555, 25px 25px 8px rgba(0, 0, 0, 0.2);
}
.page {
  font-size: 20px;
  font-weight: 600;
  color: #555;
  padding: 0 2rem;
}
</style>
