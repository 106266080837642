export const companiesData = [
    {
      id: 1,
      name: "Company A",
      email: "companya@example.com",
      users_count: 100,
      phone_number: "123-456-7890",
      company_policy: "Strict data privacy policy",
    },
    {
      id: 2,
      name: "Company B",
      email: "companyb@example.com",
      users_count: 50,
      phone_number: "987-654-3210",
      company_policy: "Environmentally friendly practices",
    },
    {
      id: 3,
      name: "Company C",
      email: "companyc@example.com",
      users_count: 200,
      phone_number: "555-123-4567",
      company_policy: "Diversity and inclusion policy",
    },
    {
      id: 4,
      name: "Company D",
      email: "companyd@example.com",
      users_count: 75,
      phone_number: "444-555-6666",
      company_policy: "Customer satisfaction guarantee",
    },
    {
      id: 5,
      name: "Company E",
      email: "companye@example.com",
      users_count: 300,
      phone_number: "777-888-9999",
      company_policy: "Quality control measures",
    },
    {
      id: 6,
      name: "Company F",
      email: "companyf@example.com",
      users_count: 150,
      phone_number: "222-333-4444",
      company_policy: "Work-life balance initiatives",
    },
    {
      id: 7,
      name: "Company G",
      email: "companyg@example.com",
      users_count: 80,
      phone_number: "666-777-8888",
      company_policy: "Ethical sourcing practices",
    },
    {
      id: 8,
      name: "Company H",
      email: "companyh@example.com",
      users_count: 120,
      phone_number: "999-000-1111",
      company_policy: "Innovation and research focus",
    },
    {
      id: 9,
      name: "Company I",
      email: "companyi@example.com",
      users_count: 250,
      phone_number: "123-987-6543",
      company_policy: "Community engagement initiatives",
    },
    {
      id: 10,
      name: "Company J",
      email: "companyj@example.com",
      users_count: 50,
      phone_number: "111-222-3333",
      company_policy: "Transparency and accountability",
    },
  ];
  