<template>
  <div class="main-container">
    <div class="inner-container">
      <h3 class="heading my-3">LOGIN</h3>

      <form @submit="handleSignIn" class="add-form">
        <!-- email input -->
        <InputField
          label="Email"
          v-model:modelValue="email"
          type="text"
          name="email"
          placeholder="Enter Email"
        />
        <!-- password input -->
        <div class="d-flex position-relative">
          <InputField
            label="Password"
            v-model:modelValue="password"
            :type="showPassword ? 'text' : 'password'"
            name="password"
            placeholder="Enter Password"
          />
          <!-- show / hide password icon -->
          <div class="password-icon" @click="togglePasswordVisibility">
            <i v-if="showPassword" class="fa-solid fa-eye"></i>
            <i v-else class="fa-solid fa-eye-slash"></i>
          </div>
        </div>
        <!-- forget password link -->
        <div class="bottom-container">
          <p class="account">
            <router-link to="/forget-password">Forget Password?</router-link>
          </p>
          <!-- if user donn't have ana account, then go to register -->
          <p class="account">
            <!-- Don't have an account?&nbsp; -->
            <router-link to="/signup">Register</router-link>
          </p>
        </div>
        <!-- loader and login button -->
        <div class="btn-container mt-2">
          <button class="login-btn" type="submit" :disabled="isLoading">
            <span v-if="isLoading">
              <div class="spinner-border spinner-border-sm" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </span>
            <span v-else>Login</span>
          </button>
        </div>
      </form>

      <div class="text-center text-white mb-2">OR</div>
      <!-- Google login button -->
      <div class="btn-container mt-2">
        <button class="google-btn" @click="handleSignInWithGoogle">
          <span
            ><i class="fa-brands fa-google mx-2"></i>
            <span class="mx-2">Login with Google</span></span
          >
        </button>
      </div>
      <!-- loader and Microsoft login button -->
      <!-- <div class="btn-container mt-2">
        <button
          class="google-btn"
          data-bs-toggle="tooltip"
          data-bs-placement="bottom"
          title="Coming Soon"
          disabled
        >
          <span v-if="isMicrosoftLoading">
            <div class="spinner-border spinner-border-sm" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </span>
          <span v-else
            ><i class="fa-brands fa-microsoft mx-2"></i
            ><span class="mx-2">Login with Microsoft</span></span
          >
        </button>
      </div> -->
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import InputField from "@/components/common/InputField.vue";
import { loginUser } from "@/apis/auth.js";

export default {
  name: "LoginView",
  components: {
    InputField,
  },
  setup() {
    // Get the toast instance using useToast
    const toast = useToast();
    // Get the router instance using useRouter
    const router = useRouter();

    const store = useStore();

    // Define reactive properties using ref()
    const email = ref("");
    const password = ref("");
    const isLoading = ref(false);
    const isGoogleLoading = ref(false);
    const isMicrosoftLoading = ref(false);
    const showPassword = ref(false);

    // show/hide password
    const togglePasswordVisibility = () => {
      showPassword.value = !showPassword.value;
    };

    // handle sign-in with email & password
    const handleSignIn = async (e) => {
      e.preventDefault();
      let apiResponse;
      /** Set isLoading to true before making the sign-up request */
      isLoading.value = true;
      const userData = {
        email: email.value,
        password: password.value,
      };
      apiResponse = await loginUser(userData);
      /** Handle successful sign-in with API */
      if (apiResponse?.status) {
        /**  Store user data in local storage*/
        localStorage.setItem("loggedInUser", JSON.stringify(apiResponse?.data));
        /**  Store user data in store*/
        store.commit("setUserData", apiResponse?.data);

        /** when user logged-in, success toast show */
        toast.success(`LoggedIn Successfully`, {
          position: "bottom-right",
        });
        // Extract tenant name from apiResponse.data.domain
        const tenantName = apiResponse?.data.domain.split(".")[0];

        // Determine the base URL based on the environment
        let baseUrl =
          process.env.NODE_ENV === "development"
            ? `https://${tenantName}.acumenai.co/setSession?data=${encodeURIComponent(
                JSON.stringify(apiResponse?.data)
              )}`
            : `https://${tenantName}.acumenai.co/setSession?data=${encodeURIComponent(
                JSON.stringify(apiResponse?.data)
              )}`;

      // let   baseUrl = "http://localhost:8081/"

        // Update router base URL if needed, and navigate to the main screen
        window.location.href = baseUrl;
        /**  when user successfully logged-in, navigate to main screen */
        // router.push("/");
      } else {
        /** invalid credentials */
        if (
          apiResponse?.response?.data?.statusCode === "NotAuthorizedException"
        ) {
          toast.error(`Invalid Credentials`, {
            position: "bottom-right",
          });
        }
         if (
          apiResponse?.response?.data?.statusCode === 401
        ) {
          toast.error(`User Access Denied`, {
            position: "bottom-right",
          });
        }
        /** if user account is not verified */
        if (
          apiResponse?.response?.data?.statusCode ===
          "UserNotConfirmedException"
        ) {
          toast.error(`Please verify your account for login`, {
            position: "bottom-right",
          });
        }
        /** network error */
        if (apiResponse.code === "ERR_NETWORK") {
          toast.error(`Network Error`, {
            position: "bottom-right",
          });
        }
        /** internal server error */
        if (apiResponse?.response?.status === 500) {
          toast.error(`Internal Server Error`, {
            position: "bottom-right",
          });
          router.push("/server-error");
        }
      }
      /**  Set isLoading to false after the sign-up request is completed */
      isLoading.value = false;
    };

    // handle sign-in with Google
    const handleSignInWithGoogle = () => {
      const url =
        "https://chatbot-prod.auth.us-east-1.amazoncognito.com/oauth2/authorize?client_id=2scla62k0v6igt02fg0sbivgdu&response_type=token&scope=email+profile+openid&redirect_uri=https%3A%2F%2Flogin.acumenai.co%2Fgoogle&identity_provider=Google";
      window.location.href = url;
    };

    // Return the data and methods that need to be used in the template
    return {
      email,
      password,
      isLoading,
      isGoogleLoading,
      isMicrosoftLoading,
      showPassword,
      togglePasswordVisibility,
      handleSignIn,
      handleSignInWithGoogle,
    };
  },
};
</script>

<style scoped>
.main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  background-color: #1c1c1c;
}
.inner-container {
  padding: 20px 10px;
  border-radius: 10px;
  /* height: 80vh; */
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
}
.heading {
  color: #fff;
  text-align: center;
}
.add-form {
  margin-bottom: 20px;
}
.password-icon {
  position: absolute;
  right: 20px;
  top: 53px;
  cursor: pointer;
  color: #222;
}
.bottom-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 15px;
}
.account {
  font-size: 14px;
  color: #fff;
}
.account a {
  text-decoration: none !important;
  color: #fff;
  /* color: #1c1c1c; */
}
.btn-container {
  display: flex;
  justify-content: center;
}
.login-btn {
  background-color: #1c1c1c;
  color: white;
  width: 220px;
  height: 40px;
  font-size: 18px;
  border: 1px solid #fff;
  border-radius: 5px;
  cursor: pointer;
}
.login-btn:hover {
  background-color: #333;
}
.google-btn {
  background-color: #1c1c1c;
  color: white;
  width: 220px;
  height: 40px;
  font-size: 16px;
  border: 1px solid #fff;
  border-radius: 5px;
  cursor: pointer;
}
.google-btn:hover {
  background-color: #333;
}
.google-btn:disabled {
  background-color: grey;
  cursor: not-allowed;
}
/* for mobile screen */
@media only screen and (max-width: 400px) {
  .bottom-container {
    flex-direction: column;
    align-items: flex-start;
    margin-left: 15px;
  }
}
</style>
