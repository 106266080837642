<!-- Custom Component for Input -->

<template>
  <div class="form-control">
    <label>{{ label }}</label>
    <input
      required
      :value="modelValue"
      :type="type"
      :name="name"
      :placeholder="placeholder"
      :minlength="minlength"
      @input="$emit('update:modelValue', $event.target.value)"
    />
  </div>
</template>

<script>
export default {
  name: "InputField",
  props: {
    label: String,
    modelValue: [String, Number],
    type: {
      type: String,
      default: "text",
    },
    name: String,
    placeholder: String,
    width: {
      type: String,
      default: "400px", // Default width if not provided by parent component
    },
    minlength: Number,
  },
  data() {
    return {
      inputWidth: this.calculateInputWidth(),
    };
  },
  // computed: {
  //   formControlStyle() {
  //     if (window.innerWidth <= 450) {
  //       return "220px";
  //     } else if (window.innerWidth <= 320) {
  //       return "300px";
  //     } else {
  //       return {
  //         width: this.width,
  //       };
  //     }
  //   },
  // },
  methods: {
    calculateInputWidth() {
      if (window.innerWidth <= 450) {
        return "300px";
      } else if (window.innerWidth <= 320) {
        return "220px";
      } else {
        return this.width;
      }
    },
    updateInputWidth() {
      this.inputWidth = this.calculateInputWidth();
    },
  },
  mounted() {
    window.addEventListener("resize", this.updateInputWidth);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.updateInputWidth);
  },
};
</script>

<style scoped>
.form-control {
  margin: 5px 0;
  border: none;
  width: 400px;
  background-color: transparent;
  /* background-color: #222; */
}
.form-control label {
  display: block;
  color: #fff;
}
.form-control input {
  width: 100%;
  height: 40px;
  padding: 3px 7px;
  font-size: 17px;
  margin-top: 10px;
  border: 1px solid gray;
  outline: none;
}
/* for mobile screen */
@media only screen and (max-width: 450px) {
  .form-control {
    width: 300px;
  }
}
@media only screen and (max-width: 300px) {
  .form-control {
    width: 220px;
  }
}
</style>
