<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.20002 19.8002C4.54438 19.4146 6.85787 17.5976 8.00778 16.7019C8.42658 16.3757 8.9403 16.2002 9.47116 16.2002C10.7548 16.2002 13.2284 16.2002 14.5183 16.2002C15.0553 16.2002 15.5742 16.3832 16.0066 16.7017C17.4914 17.7955 18.8833 18.6105 20.4 19.8002M6.00002 21.6H18C19.9882 21.6 21.6 19.9882 21.6 18V5.99999C21.6 4.01177 19.9882 2.39999 18 2.39999H6.00002C4.0118 2.39999 2.40002 4.01177 2.40002 5.99999V18C2.40002 19.9882 4.0118 21.6 6.00002 21.6ZM15.4386 9.27203C15.4386 7.44052 13.8924 5.94408 12 5.94408C10.1076 5.94408 8.56147 7.44052 8.56147 9.27203C8.56147 11.1035 10.1076 12.6 12 12.6C13.8924 12.6 15.4386 11.1035 15.4386 9.27203Z"
      stroke="#F8F7FF"
      stroke-width="2"
    />
  </svg>
</template>
