<template>
  <div>
    <CompanyCard />
  </div>
</template>

<script>
import CompanyCard from "@/components/dashboard/companyDetail/CompanyCard.vue";
export default {
  name: "CompanyDetailView",
  components: {
    CompanyCard,
  },
};
</script>
