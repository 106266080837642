<template>
  <div id="app">
    <MainSidebar v-if="shouldShowSidebar" />
    <div :class="['content-container', { 'no-sidebar': !shouldShowSidebar }]">
      <router-view />
    </div>
  </div>
</template>

<script>
import MainSidebar from "./components/layout/MainSidebar.vue";

export default {
  name: "App",
  components: { MainSidebar },
  computed: {
    /** on these pages, sidebar and header will not show */
    shouldShowSidebar() {
      const excludedRoutes = [
        "LoginView",
        "RegisterView",
        "AccountVerification",
        "ForgetPasswordView",
        "ResetPasswordView",
        "Settings",
        "NotFound",
        "ServerError",
        "NetworkError",
        "GoogleView",
        "DashboardView",
        "TenantsView",
        "LogsView",
      ];
      return !excludedRoutes.includes(this.$route.name);
    },
  },
};
</script>

<style>
.content-container {
  margin-left: 250px;
}

.content-container.no-sidebar {
  margin-left: 0;
}
/** Toast color change for success and info case */
.Vue-Toastification__toast--success,
.Vue-Toastification__toast--info {
  background-color: rgba(255, 255, 255, 0.2) !important;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1) !important;
}

/* for mobile screen */
@media only screen and (max-width: 768px) {
  .content-container {
    margin-left: 0;
  }
}
</style>
