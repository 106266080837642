<template>
  <div class="main-container">
    <div class="inner-container">
      <h3 class="heading my-3">RESET YOUR PASSWORD</h3>

      <form @submit="onSubmit" class="add-form">
        <!-- verification code input -->
        <InputField
          label="Verification Code"
          v-model:modelValue="verificationCode"
          type="text"
          name="verificationCode"
          placeholder="Enter verification code"
        />
        <!-- new password input -->
        <div class="d-flex position-relative">
          <InputField
            label="New Password"
            v-model:modelValue="newPassword"
            :type="showPassword ? 'text' : 'password'"
            name="newPassword"
            placeholder="Enter new password"
            @input="validatePassword"
          />
          <div class="password-icon" @click="togglePasswordVisibility">
            <i v-if="showPassword" class="fa-solid fa-eye"></i>
            <i v-else class="fa-solid fa-eye-slash"></i>
          </div>
        </div>
        <!-- password error -->
        <p class="error-message" v-if="passwordErrorMessage">
          {{ passwordErrorMessage }}
        </p>
        <!-- confirm new password input -->
        <div class="d-flex position-relative">
          <InputField
            label="Confirm Password"
            v-model:modelValue="confirmNewPassword"
            :type="showConfirmPassword ? 'text' : 'password'"
            name="confirmPassword"
            placeholder="Enter Password"
            @input="validateConfirmPassword"
          />
          <div class="password-icon" @click="toggleConfirmPasswordVisibility">
            <i v-if="showConfirmPassword" class="fa-solid fa-eye"></i>
            <i v-else class="fa-solid fa-eye-slash"></i>
          </div>
        </div>
        <!-- confirm password error messages -->
        <p class="error-message" v-if="confirmPasswordErrorMessage">
          {{ confirmPasswordErrorMessage }}
        </p>
        <!-- loader and change password button -->
        <div class="btn-container mt-3">
          <button class="change-btn" type="submit" :disabled="isDisabled">
            <span v-if="isLoading">
              <div class="spinner-border spinner-border-sm" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </span>
            <span v-else>Change Password</span>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { ref, computed } from "vue";
import { forgetPassword } from "@/apis/auth.js";
import { useToast } from "vue-toastification";
import { useRoute, useRouter } from "vue-router";
import InputField from "@/components/common/InputField.vue";

export default {
  name: "ResetPasswordView",
  components: {
    InputField,
  },
  setup() {
    // Get the toast instance using useToast
    const toast = useToast();
    // Get the router instance using useRouter
    const router = useRouter();
    const route = useRoute();

    // Define reactive properties using ref()
    const email = ref("");
    const verificationCode = ref("");
    const newPassword = ref("");
    const confirmNewPassword = ref("");
    const showPassword = ref(false);
    const showConfirmPassword = ref(false);
    const passwordErrorMessage = ref("");
    const confirmPasswordErrorMessage = ref("");
    const isLoading = ref(false);

    // password validation
    const validatePassword = () => {
      const passwordRegex =
        /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
      const requirements = [
        {
          regex: /.*[A-Z].*/,
          message: "1 uppercase letter",
        },
        {
          regex: /.*[a-z].*/,
          message: "1 lowercase letter",
        },
        {
          regex: /.*\d.*/,
          message: "1 digit",
        },
        {
          regex: /.*[!@#$%^&*].*/,
          message: "1 special character",
        },
      ];

      passwordErrorMessage.value = "";

      if (newPassword.value.length > 0) {
        if (newPassword.value.length < 8) {
          passwordErrorMessage.value =
            "Password must have at least 8 characters";
          return;
        }

        requirements.forEach((requirement) => {
          if (!requirement.regex.test(newPassword.value)) {
            passwordErrorMessage.value += `${requirement.message}, `;
          }
        });

        if (passwordRegex.test(newPassword.value)) {
          passwordErrorMessage.value = "";
        } else {
          passwordErrorMessage.value =
            "Password must contain " +
            passwordErrorMessage.value.slice(0, -2) +
            ".";
        }
      } else {
        // If the password field is empty, clear the error message
        passwordErrorMessage.value = "";
      }
    };

    // confirm password validation
    const validateConfirmPassword = () => {
      confirmPasswordErrorMessage.value = "";

      if (confirmNewPassword.value.length > 0) {
        if (confirmNewPassword.value !== newPassword.value) {
          confirmPasswordErrorMessage.value = "Passwords do not match";
        }
      } else {
        // If the confirm password field is empty, clear the error message
        confirmPasswordErrorMessage.value = "";
      }
    };

    // show/hide password
    const togglePasswordVisibility = () => {
      showPassword.value = !showPassword.value;
    };
    // show/hide confirm password
    const toggleConfirmPasswordVisibility = () => {
      showConfirmPassword.value = !showConfirmPassword.value;
    };

    // Create a computed property to check if passwords match and validate conditions
    const isDisabled = computed(() => {
      const passwordsMatch = confirmNewPassword.value === newPassword.value;

      // Check if any of the conditions fail and return true to disable the button
      if (
        !passwordsMatch ||
        passwordErrorMessage.value ||
        confirmPasswordErrorMessage.value
      ) {
        return true;
      }

      return isLoading.value;
    });

    // handle sign-in
    const onSubmit = async (e) => {
      e.preventDefault();
      let apiResponse;

      /** Set isLoading to true before making the sign-up request */
      isLoading.value = true;
      /** Access the email from the query parameters */
      const _email = route.query.email;
      const userData = {
        email: _email,
        verificationCode: verificationCode.value,
        password: newPassword.value,
      };
      apiResponse = await forgetPassword(userData);

      /** Handle successful sign-up with API */
      if (apiResponse?.status) {
        /** if user change password with API, success message will show */
        toast.success(`Password reset successful`, {
          position: "bottom-right",
        });
        /** navigate to login */
        router.push("/login");
      } else {
        /** invalid code error */
        if (
          apiResponse?.response?.data?.statusCode === "CodeMismatchException"
        ) {
          toast.error(`Invalid verification code provided, please try again`, {
            position: "bottom-right",
          });
        }
        /** expire code error */
        if (
          apiResponse?.response?.data?.statusCode === "ExpiredCodeException"
        ) {
          toast.error(`Code has been expired`, {
            position: "bottom-right",
          });
        }
        /** attempt limit exceeded error */
        if (
          apiResponse?.response?.data?.statusCode === "LimitExceededException"
        ) {
          toast.error(`Attempt limit exceeded, please try again`, {
            position: "bottom-right",
          });
        }
      }

      /** network error */
      if (apiResponse.code === "ERR_NETWORK") {
        toast.error(`Network Error`, {
          position: "bottom-right",
        });
      }
      /** internal server error */
      if (apiResponse?.response?.status === 500) {
        toast.error(`Internal Server Error`, {
          position: "bottom-right",
        });
        router.push("/server-error");
      }
      /** Set isLoading to false after the sign-up request is completed */
      isLoading.value = false;
    };

    // Return the data and methods that need to be used in the template
    return {
      email,
      verificationCode,
      newPassword,
      confirmNewPassword,
      showPassword,
      showConfirmPassword,
      passwordErrorMessage,
      confirmPasswordErrorMessage,
      isLoading,
      validatePassword,
      validateConfirmPassword,
      togglePasswordVisibility,
      toggleConfirmPasswordVisibility,
      isDisabled,
      onSubmit,
    };
  },

  computed: {
    /** store email and send it to change password data */
    ...mapState(["email"]),
  },
};
</script>

<style scoped>
.main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  background-color: #1c1c1c;
}
.inner-container {
  padding: 10px;
  border-radius: 10px;
  height: 70vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
}
.heading {
  color: #fff;
  text-align: center;
}
.add-form {
  margin-bottom: 20px;
}
.btn-container {
  display: flex;
  justify-content: center;
}
.change-btn {
  background-color: #1c1c1c;
  color: white;
  width: 220px;
  height: 40px;
  font-size: 18px;
  border: 1px solid #fff;
  border-radius: 5px;
  cursor: pointer;
}
.change-btn:hover {
  background-color: #333;
}
.password-icon {
  position: absolute;
  right: 20px;
  top: 52px;
  cursor: pointer;
  color: #222;
}
.error-message {
  color: red;
  font-size: 12px;
  max-width: 300px;
  margin-left: 15px;
}
</style>
