<template>
  <div class="chatbox-container">
    <ChatMessages
      :chatMessages="chatMessages"
      ref="chatContainer"
      :_newSessionId="_newSessionId"
    />

    <div class="chatbox-input">
      <form @submit.prevent="sendMessage">
        <div class="chatbox-input-field-container">
          <textarea
            ref="textArea"
            type="text"
            rows="1"
            class="chatbox-input-field"
            @keydown.enter="handleKeyDown"
            v-model="newMessage"
            @input="adjustTextareaHeight"
          />
          <button
            :disabled="sending"
            class="chatbox-send-button"
            @click="handleSubmit"
          >
            <i class="fas fa-paper-plane chatbox-send-icon"></i>
          </button>
        </div>
        <p class="chatbox-input-paragraph">
          Chatbot Apr 13 Version. Free Research Preview. Our goal is to make AI
          systems more natural and safe to interact with. Your feedback will
          help us improve.
        </p>
      </form>
    </div>
  </div>
</template>

<script setup>
import { useStore } from "vuex";
import { computed, ref } from "vue";
import ChatMessages from "./ChatMessages.vue";
import { chatSessions, checkOpenAIKey, sendQuery } from "@/apis/apis";
import { parseJSONFromLocalStorage } from "@/utils/utils";
import { useToast } from "vue-toastification";
import { useRouter } from "vue-router";

const store = useStore();
const toast = useToast();
const router = useRouter();

let _newSessionId;

const newMessage = ref("");
const sending = ref(false);
const chatContainerRef = ref();

const userData = parseJSONFromLocalStorage("loggedInUser");

const chatMessages = computed(() => store.getters.getChatMessages);

const createNewChatSession = async () => {
  const response = await chatSessions(userData?.accessToken, true);
  if (response && response.data) {
    _newSessionId = response.data[0]._id;
    await store.dispatch("setIsNewSessionCreated", true);
  }
};

const handleSubmit = async () => {
  const openAiKeyResponse = await checkOpenAIKey(userData?.accessToken);

  if (newMessage.value.trim() === "") {
    return; // Do nothing if the message is empty or only contains spaces
  }

  /** unauthorized */
  if (openAiKeyResponse?.response?.status === 401) {
    localStorage.removeItem("loggedInUser");
    alert("Session Expired! Please Login Again");
    window.location.href = "/login";
  }
  /** internal server error */
  if (openAiKeyResponse?.response?.status === 500) {
    toast.error(`Internal Server Error`, {
      position: "bottom-right",
    });
    router.push("/server-error");
  }
  /** api key missing error */
  if (!openAiKeyResponse?.openAiKeyProvided) {
    newMessage.value = "";
    toast.error("The OpenAI key was not provided by the company", {
      position: "bottom-right",
    });
    return; // Don't proceed if openAiKeyProvided is false
  }

  sending.value = true;
  const queryText = newMessage.value;

  chatMessages.value.push({
    message: queryText,
    isUser: true,
  });

  newMessage.value = "";
  scrollToBottom();

  // Check if there are any messages in the current session
  if (chatMessages.value.length === 1) {
    // No messages in the current session, create a new session
    await createNewChatSession();
  }

  chatMessages.value.push({
    message: "Waiting for response...",
    isUser: false,
  });
  scrollToBottom();

  if (openAiKeyResponse?.openAiKeyProvided) {
    // Check the condition here
    const response = await sendQuery(
      queryText,
      _newSessionId,
      userData?.accessToken
    );

    // Check if there's at least one message in chatMessages before updating the last message
    if (chatMessages.value.length > 0) {
      chatMessages.value[chatMessages.value.length - 1].message = "";
    }
    if (response?.response?.data?.statusCode === "openAiKeyMissing") {
      toast.error("The OpenAI key was not provided by the company", {
        position: "bottom-right",
      });
    } else if (response && response.message) {
      const messageArray = response.message.split(" ");
      let index = 0;
      const typingInterval = setInterval(() => {
        if (chatMessages.value.length > 0) {
          chatMessages.value[chatMessages.value.length - 1].message +=
            messageArray[index] + " ";
          scrollToBottom();
          index++;
          if (index >= messageArray.length) {
            clearInterval(typingInterval);
          }
        }
      }, 100);
    }
  }

  sending.value = false;
};

const scrollToBottom = () => {
  const chatContainer = chatContainerRef.value;
  if (chatContainer && chatContainer.lastElementChild) {
    chatContainer.lastElementChild.scrollIntoView({ behavior: "smooth" });
  }
};

const handleKeyDown = (event) => {
  event.preventDefault();
  if (event.key === "Enter" && event.shiftKey) {
    event.target.value += "\n";
    const textarea = event.target;
    textarea.rows += 1;
  } else if (event.key === "Enter") {
    handleSubmit();
    const textarea = event.target;
    textarea.rows = 1;
  }
};

const adjustTextareaHeight = (event) => {
  const textarea = event.target;
  textarea.style.height = "auto";
  textarea.style.height = `${textarea.scrollHeight}px`;
};
</script>

<style scoped>
.chatbox-container {
  background-color: #1c1c1c;
  font-size: 16px;
  color: #fff;
  height: 100vh;
  display: flex;
  flex-direction: column;
  z-index: 1;
}
.chatbox-input {
  padding: 1rem;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  background-color: transparent;
}
.chatbox-input-field-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #383232;
  border-radius: 8px;
  padding: 0.3rem;
  margin-left: 4rem;
  margin-right: 4rem;
  margin-bottom: 1.5rem;
}
.chatbox-input-field {
  flex: 1;
  padding: 8px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  color: #fff;
  background-color: transparent;
}
.chatbox-send-button {
  width: 2.5rem;
  height: 2.5rem;
  margin-left: 10px;
  padding: 10px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
  background-color: transparent;
}
.chatbox-input-field:focus {
  outline: none;
}
.chatbox-send-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
.chatbox-send-button:active {
  background-color: rgba(255, 255, 255, 0.2);
}
.chatbox-send-button[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}
.chatbox-input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  bottom: 0;
  left: 0;
  width: 100%;
  background-image: linear-gradient(to top, rgba(56, 50, 50, 1), transparent);
  padding: 20px;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);
}
.chatbox-input-field-container {
  display: flex;
  align-items: center;
}
.chatbox-input-field {
  flex: 1;
  padding: 8px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  color: #fff;
  max-height: 10em;
}
.chatbox-send-button:hover {
  background-color: rgba(46, 46, 46, 1);
}
.chatbox-send-icon {
  margin-right: 5px;
  color: #fff;
  font-size: 1.4rem;
}
.chatbox-input-paragraph {
  margin: 0;
  text-align: center;
  color: #b1b1b1;
  font-size: 12px;
  line-height: 1;
}
.chatbox-input-field {
  width: 100%;
  resize: none;
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 2;
  transition: all 0.2s ease;
  overflow: auto;
}
/* for mobile screen */
@media only screen and (max-width: 768px) {
  .chatbox-input-field-container {
    margin-left: 0rem;
    margin-right: 0rem;
  }
}
</style>
