<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.4 15.6C19.4 16.1523 19.8477 16.6 20.4 16.6C20.9523 16.6 21.4 16.1523 21.4 15.6H19.4ZM2.60002 15.6C2.60002 16.1523 3.04774 16.6 3.60002 16.6C4.15231 16.6 4.60002 16.1523 4.60002 15.6H2.60002ZM5.70002 5.2H18.3V3.2H5.70002V5.2ZM19.4 6.23077V15.6H21.4V6.23077H19.4ZM4.60002 15.6V6.23077H2.60002V15.6H4.60002ZM18.3 5.2C18.9394 5.2 19.4 5.69282 19.4 6.23077H21.4C21.4 4.52559 19.9802 3.2 18.3 3.2V5.2ZM5.70002 3.2C4.0198 3.2 2.60002 4.52558 2.60002 6.23077H4.60002C4.60002 5.69282 5.06065 5.2 5.70002 5.2V3.2ZM3.60002 17.2H20.4V15.2H3.60002V17.2ZM20.6 17.4V18.6H22.6V17.4H20.6ZM20.4 18.8H3.60002V20.8H20.4V18.8ZM3.40002 18.6V17.4H1.40002V18.6H3.40002ZM3.60002 18.8C3.48957 18.8 3.40002 18.7105 3.40002 18.6H1.40002C1.40002 19.815 2.385 20.8 3.60002 20.8V18.8ZM20.6 18.6C20.6 18.7105 20.5105 18.8 20.4 18.8V20.8C21.615 20.8 22.6 19.815 22.6 18.6H20.6ZM20.4 17.2C20.5105 17.2 20.6 17.2895 20.6 17.4H22.6C22.6 16.185 21.615 15.2 20.4 15.2V17.2ZM3.60002 15.2C2.385 15.2 1.40002 16.185 1.40002 17.4H3.40002C3.40002 17.2895 3.48957 17.2 3.60002 17.2V15.2Z"
      fill="#F8F7FF"
    />
  </svg>
</template>
