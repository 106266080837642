<template>
  <div class="main-container">
    <div class="inner-container">
      <h3 class="heading my-3">Verify Your Account</h3>

      <form @submit="handleVerify" class="add-form">
        <!-- confirmation code input -->
        <InputField
          label="Confirmation Code"
          v-model:modelValue="confirmationCode"
          type="text"
          name="confirmationCode"
          placeholder="Enter Code"
        />
        <!-- resend the code -->
        <div class="resend" @click="handleResend">Resend the code</div>
        <!-- loader and submit button -->
        <div class="btn-container">
          <button class="submit-btn" type="submit" :disabled="isLoading">
            <span v-if="isLoading">
              <div class="spinner-border spinner-border-sm" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </span>
            <span v-else>Submit</span>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useToast } from "vue-toastification";
import { useRoute, useRouter } from "vue-router";
import {
  accountVerification,
  resendAccountVerificationCode,
} from "@/apis/auth.js";
import InputField from "@/components/common/InputField.vue";

export default {
  name: "AccountVerification",
  components: {
    InputField,
  },
  setup() {
    // Get the toast instance using useToast
    const toast = useToast();
    // Get the router instance using useRouter
    const router = useRouter();
    const route = useRoute();

    // Define reactive properties using ref()
    const confirmationCode = ref("");
    const isLoading = ref(false);

    // handle account verification
    const handleVerify = async (e) => {
      e.preventDefault();
      let apiResponse;

      /** Set isLoading to true before making the sign-up request */
      isLoading.value = true;
      /** Access the username from the query parameters */
      const _email = route.query.email;
      const userData = {
        email: _email,
        confirmationCode: confirmationCode.value,
      };
      apiResponse = await accountVerification(userData);

      /** success case */
      if (apiResponse?.status) {
        /** show success message */
        toast.success(`Account Verified Successfully`, {
          position: "bottom-right",
        });
        /**  when user successfully verification, navigate to login screen */
        router.push("/login");
      } else {
        /** invalid verification code error */
        if (
          apiResponse?.response?.data?.statusCode === "CodeMismatchException"
        ) {
          toast.error(`Invalid verification code`, {
            position: "bottom-right",
          });
        }
        /** if account already registered on the email */
        if (
          apiResponse?.response?.data?.statusCode === "AliasExistsException"
        ) {
          toast.error(`An account with this email already exist`, {
            position: "bottom-right",
          });
        }
        /** expire code error */
        if (
          apiResponse?.response?.data?.statusCode === "ExpiredCodeException"
        ) {
          toast.error(`Code has been expired. Please request again`, {
            position: "bottom-right",
          });
        }
        /** attempts limit exceeded error */
        if (
          apiResponse?.response?.data?.statusCode === "LimitExceededException"
        ) {
          toast.error(`Limit exceeded. Try after sometime`, {
            position: "bottom-right",
          });
        }
      }
      /** network error */
      if (apiResponse.code === "ERR_NETWORK") {
        toast.error(`Network Error`, {
          position: "bottom-right",
        });
      }
      /** internal server error */
      if (apiResponse?.response?.status === 500) {
        toast.error(`Internal Server Error`, {
          position: "bottom-right",
        });
        router.push("/server-error");
      }

      /** Set isLoading to false after the sign-up request is completed */
      isLoading.value = false;
    };

    // handle account verification
    const handleResend = async () => {
      /** Access the username from the query parameters */
      let apiResponse;
      const _email = route.query.email;
      const userData = {
        email: _email,
      };

      apiResponse = await resendAccountVerificationCode(userData);
      /** success case */
      if (apiResponse?.status) {
        /** code sent message */
        toast.success(`New confirmation code has been sent`, {
          position: "bottom-right",
        });
      } else {
        /** network error */
        if (apiResponse?.response?.data.code === "ERR_NETWORK") {
          toast.error(`Network Error`, {
            position: "bottom-right",
          });
        }
        /** internal server error */
        if (apiResponse?.response?.status === 500) {
          toast.error(`Internal Server Error`, {
            position: "bottom-right",
          });
          router.push("/server-error");
        }
      }
    };

    // Return the data and methods that need to be used in the template
    return {
      confirmationCode,
      isLoading,
      handleVerify,
      handleResend,
    };
  },
};
</script>

<style scoped>
.main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  background-color: #1c1c1c;
}
.inner-container {
  padding: 10px;
  border-radius: 10px;
  height: 60vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
}
.heading {
  color: #fff;
  text-align: center;
}
.add-form {
  margin-bottom: 20px;
}
.btn-container {
  display: flex;
  justify-content: center;
}
.submit-btn {
  background-color: #1c1c1c;
  color: white;
  width: 220px;
  height: 40px;
  font-size: 18px;
  border: 1px solid #fff;
  border-radius: 5px;
  cursor: pointer;
}
.submit-btn:hover {
  background-color: #333;
}
.resend {
  cursor: pointer;
  font-size: 14px;
  margin-left: 15px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 10px;
}
</style>
